import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    Navbar,
    UncontrolledDropdown,
} from 'reactstrap';
import navLinks from '../../api/NavLinks';
import IL from '../../../src/il.png'
import UK from '../../../src/uk.png'

const StickyButtons = () => {
    const location = useLocation();
    const isHebrewRoute = location.pathname === '/he' || location.pathname === '/Pricing-Calculator';
    const [isHidden, setIsHidden] = useState(isHebrewRoute);

    const buttonBaseStyle = {
        padding: '6px 10px',
        borderRadius: '6px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
        transition: 'all 0.3s ease',
        cursor: 'pointer',
        fontSize: '13px',
        width: '120px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        transform: 'translateX(0)',
        position: 'relative',
        overflow: 'hidden',
    };

    const buttonHoverStyle = (e, color) => {
        e.currentTarget.style.transform = 'translateX(-5px)';
        e.currentTarget.style.boxShadow = '2px 4px 12px rgba(0,0,0,0.15)';
        e.currentTarget.style.backgroundColor = color;
    };

    const buttonUnhoverStyle = (e, originalColor) => {
        e.currentTarget.style.transform = 'translateX(0)';
        e.currentTarget.style.boxShadow = '0 2px 8px rgba(0,0,0,0.1)';
        e.currentTarget.style.backgroundColor = originalColor;
    };

    useEffect(() => {
        // Load accessibility widget script
        if (isHebrewRoute) {
            const script = document.createElement('script');
            script.src = "https://cdn.userway.org/widget.js";
            script.setAttribute('data-account', 'epxGxHxHxB');  // Replace with your actual UserWay account ID
            script.setAttribute('data-position', 'left');
            script.setAttribute('data-button-color', '#0078a8');
            script.setAttribute('data-language', 'he');
            document.body.appendChild(script);
            
            return () => {
                // Clean up script on unmount
                const existingScript = document.querySelector('script[src="https://cdn.userway.org/widget.js"]');
                if (existingScript) {
                    document.body.removeChild(existingScript);
                }
            };
        }
    }, [isHebrewRoute]);

    return (
        <>
            {/* The UserWay widget will be automatically injected by the script */}
            <div className="sticky-buttons-container" style={{
                position: 'fixed',
                right: isHidden ? '-130px' : '15px',
                top: '70%',
                transform: 'translateY(-50%)',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                zIndex: 1000,
                transition: 'all 0.3s ease-in-out',
                background: 'rgba(255, 255, 255, 0.95)',
                padding: '12px',
                borderRadius: '10px',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            }}>
                <div 
                    className="toggle-btn"
                    onClick={() => setIsHidden(!isHidden)}
                    style={{
                        position: 'absolute',
                        left: '-28px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        backgroundColor: '#0078a8',
                        color: 'white',
                        width: '28px',
                        height: '56px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        borderRadius: '6px 0 0 6px',
                        boxShadow: '-2px 0 10px rgba(0,0,0,0.1)',
                        transition: 'all 0.3s ease',
                        fontSize: '16px',
                    }}
                    onMouseOver={(e) => {
                        e.currentTarget.style.backgroundColor = '#005c82';
                        e.currentTarget.style.width = '32px';
                    }}
                    onMouseOut={(e) => {
                        e.currentTarget.style.backgroundColor = '#0078a8';
                        e.currentTarget.style.width = '28px';
                    }}
                >
                    {isHidden ? '←' : '→'}
                </div>
                
                <div 
                    className="sticky-btn calculator-btn"
                    style={{
                        ...buttonBaseStyle,
                        backgroundColor: '#A2FADD',
                        borderLeft: '3px solid #7de2c5',
                    }}
                    onMouseOver={(e) => buttonHoverStyle(e, '#8ff0d0')}
                    onMouseOut={(e) => buttonUnhoverStyle(e, '#A2FADD')}
                >
                    <Link to="/Pricing-Calculator" style={{ 
                        color: '#0f172a', 
                        textDecoration: 'none', 
                        fontWeight: 'bold',
                        width: '100%',
                    }}>
                        מחשבון עלות
                    </Link>
                </div>
                
                <div 
                    className="sticky-btn language-btn"
                    style={{
                        ...buttonBaseStyle,
                        backgroundColor: '#0078a8',
                        borderLeft: '3px solid #005c82',
                    }}
                    onMouseOver={(e) => buttonHoverStyle(e, '#006694')}
                    onMouseOut={(e) => buttonUnhoverStyle(e, '#0078a8')}
                >
                    <Link to="/he" style={{ 
                        color: 'white', 
                        textDecoration: 'none', 
                        fontWeight: 'bold',
                        width: '100%',
                    }}>
                        HE - עברית
                    </Link>
                </div>
                
                <div 
                    className="sticky-btn platform-btn"
                    style={{
                        ...buttonBaseStyle,
                        backgroundColor: '#0078a8',
                        borderLeft: '3px solid #005c82',
                    }}
                    onMouseOver={(e) => buttonHoverStyle(e, '#006694')}
                    onMouseOut={(e) => buttonUnhoverStyle(e, '#0078a8')}
                >
                    <a 
                        href="https://boarding.epicfinal.com/login" 
                        style={{ 
                            color: 'white', 
                            textDecoration: 'none', 
                            fontWeight: 'bold',
                            width: '100%',
                        }}
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        כניסה לפלטפורמה
                    </a>
                </div>
            </div>
        </>
    );
};

const Header1 = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const [isCartview, setIsCartview] = useState(false);
    const wrapperRef = useRef(null);
    const lastScrollTop = useRef(0);
    const ticking = useRef(false);
    const scrollTimer = useRef(null);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            document.removeEventListener('mousedown', handleClickOutside);
            if (scrollTimer.current) clearTimeout(scrollTimer.current);
        };
    }, []);

    const toggle = () => {
        setIsOpen(!isOpen);
    };
    const [openMenus, setOpenMenus] = useState([]);

    const toggleMenu = (index) => {
        if (openMenus.includes(index)) {
            setOpenMenus(openMenus.filter((item) => item !== index));
        } else {
            setOpenMenus([...openMenus, index]);
        }
    };
    const closeAllMenus = () => {
        setOpenMenus([]);
    };
    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setIsCartview(false);
        }
    };
    const handleScroll = () => {
        if (!ticking.current) {
            window.requestAnimationFrame(() => {
                const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
                const scrollingDown = currentScrollTop > lastScrollTop.current;
                const scrollDelta = Math.abs(currentScrollTop - lastScrollTop.current);
                
                // Only trigger header visibility change if scroll amount is significant
                if (scrollDelta > 10) {
                    if (scrollTimer.current) {
                        clearTimeout(scrollTimer.current);
                    }

                    if (currentScrollTop < 50) {
                        setVisible(false);
                    } else if (scrollingDown && currentScrollTop > 100) {
                        setVisible(true);
                    } else if (!scrollingDown) {
                        setVisible(false);
                    }

                    // Add a small delay before allowing the next visibility change
                    scrollTimer.current = setTimeout(() => {
                        scrollTimer.current = null;
                    }, 100);
                }
                
                lastScrollTop.current = currentScrollTop;
                ticking.current = false;
            });
            
            ticking.current = true;
        }
    };

    return (
        <header id="site-header" className="header">
            <StickyButtons />
            <div 
                id="header-wrap" 
                className={`${visible ? 'fixed-header' : ''}`}
                style={{
                    transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                    transform: visible ? 'translateY(-100%)' : 'translateY(0)',
                    opacity: visible ? '0' : '1',
                    position: visible ? 'fixed' : 'relative',
                    width: '100%',
                    top: '0',
                    zIndex: 1030,
                    backdropFilter: 'blur(8px)',
                    backgroundColor: visible ? 'rgba(255, 255, 255, 0.95)' : 'transparent',
                    boxShadow: visible ? '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)' : 'none'
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <Navbar className="navbar navbar-expand-lg navbar-light ">
                                <Link className="navbar-brand logo text-primary mb-0 font-w-7o" to="/">
                                    e<span class="text-dark font-w-4">picfinal</span> 
                                </Link>
                                <button
                                    className="navbar-toggler"  
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#navbarNavDropdown"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    onClick={toggle}
                                >
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <Collapse isOpen={isOpen} className="collapse navbar-collapse" navbar>
                                    <Nav className="mx-auto" navbar>
                                        {navLinks.map((navLink, index) => (
                                            <NavItem key={index}>
                                                {navLink.type && navLink.type === "subMenu" ? (
                                                    <UncontrolledDropdown nav inNavbar isOpen={openMenus.includes(index)}
                                                        toggle={() => toggleMenu(index)}
                                                        onMouseLeave={closeAllMenus}>
                                                        <DropdownToggle nav caret >
                                                            {navLink.menu_title}
                                                        </DropdownToggle>
                                                        <DropdownMenu
                                                            id={`submenu_${index}`}
                                                            className="dropdown-menu"

                                                        >
                                                            {navLink.child_routes &&
                                                                navLink.child_routes.map((subNavLink, keys) =>
                                                                    subNavLink.type &&
                                                                        subNavLink.type === "childsubMenu" ? (
                                                                        <UncontrolledDropdown
                                                                            inNavbar
                                                                            className="dropdown-submenu"
                                                                            key={keys}
                                                                        >
                                                                            <DropdownToggle
                                                                                tag="a"
                                                                                caret
                                                                                className="dropdown-item dropdown-toggle cursor-pointe"
                                                                            >
                                                                                {subNavLink.menu_title}
                                                                            </DropdownToggle>
                                                                            {/* Sub Menu Start */}
                                                                            <DropdownMenu
                                                                                id={`childsubmenu_${keys}`}
                                                                                className="dropdown-menu"
                                                                            >
                                                                                {subNavLink.child_routes &&
                                                                                    subNavLink.child_routes.map(
                                                                                        (ChildsubNavLink, i) => (
                                                                                            <DropdownItem
                                                                                                key={i}
                                                                                                tag={Link}
                                                                                                to={ChildsubNavLink.path}

                                                                                            >
                                                                                                {ChildsubNavLink.menu_title}
                                                                                            </DropdownItem>
                                                                                        )
                                                                                    )}
                                                                            </DropdownMenu>
                                                                            {/* Sub Menu End */}
                                                                        </UncontrolledDropdown>
                                                                    ) : (
                                                                        <ul className="list-unstyled" key={keys}>
                                                                            <li>
                                                                                <DropdownItem
                                                                                    tag={Link}
                                                                                    to={subNavLink.path}
                                                                                >
                                                                                    {subNavLink.menu_title}
                                                                                </DropdownItem>
                                                                            </li>
                                                                            
                                                                        </ul>
                                                                        
                                                                    )
                                                                )}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                ) : (
                                                    <NavLink 
                                                        href={navLink.path}
                                                        className={navLink.menu_title === "Marketplace" ? "nav-marketplace-btn" : ""}
                                                        style={navLink.menu_title === "Marketplace" ? {
                                                            backgroundColor: "#A2FADD",
                                                            color: "#0f172a",
                                                            fontWeight: "bold",
                                                            borderRadius: "4px",
                                                            padding: "6px 12px",
                                                            marginLeft: "10px",
                                                            transition: "all 0.3s ease"
                                                        } : {}}
                                                        onMouseOver={navLink.menu_title === "Marketplace" ? (e) => {
                                                            e.currentTarget.style.backgroundColor = "#7de2c5";
                                                            e.currentTarget.style.color = "#0f172a";
                                                        } : undefined}
                                                        onMouseOut={navLink.menu_title === "Marketplace" ? (e) => {
                                                            e.currentTarget.style.backgroundColor = "#A2FADD";
                                                            e.currentTarget.style.color = "#0f172a";
                                                        } : undefined}
                                                    >
                                                        {navLink.menu_title}
                                                    </NavLink>
                                                    
                                                )}
                                            </NavItem>
                                        ))}
                                        <NavItem>
                                            <NavLink 
                                                href="https://boarding.epicfinal.com"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="nav-platform-btn"
                                                style={{
                                                    backgroundColor: "#0078a8",
                                                    color: "white",
                                                    fontWeight: "bold",
                                                    borderRadius: "4px",
                                                    padding: "6px 12px",
                                                    marginLeft: "10px",
                                                    transition: "all 0.3s ease"
                                                }}
                                                onMouseOver={(e) => {
                                                    e.currentTarget.style.backgroundColor = "#005c82";
                                                }}
                                                onMouseOut={(e) => {
                                                    e.currentTarget.style.backgroundColor = "#0078a8";
                                                }}
                                            >
                                                Platform
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Collapse>
                                <div className="d-sm-flex align-items-center justify-content-end">
                                    <div className="contact-info-container d-none d-md-block" style={{ 
                                        textAlign: 'center', 
                                        padding: '10px', 
                                        borderBottom: '1px solid #ddd'
                                    }}>
                                        <div className="phone-numbers-container" style={{ 
                                            marginBottom: '1px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                            justifyContent: 'center',
                                            gap: '10px'
                                        }}>
                                            <div className="phone-number" style={{ 
                                                color: "#0078a8", 
                                                fontWeight: 'bold',
                                                display: 'flex',
                                                alignItems: 'center',
                                                whiteSpace: 'nowrap'
                                            }}>
                                                <img src={IL} alt="IL Flag" style={{ verticalAlign: 'middle', marginRight: '5px', width: '20px' }} />
                                                <a href="tel:+972515564767" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                    (+972) 515-564-767
                                                </a>
                                            </div>
                                            <div className="phone-number" style={{ 
                                                color: "#0078a8", 
                                                fontWeight: 'bold',
                                                display: 'flex',
                                                alignItems: 'center',
                                                whiteSpace: 'nowrap'
                                            }}>
                                                <img src={UK} alt="UK Flag" style={{ verticalAlign: 'middle', marginRight: '5px', width: '20px' }} />
                                                <a href="tel:+442392161214" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                    (+44) 239-216-1214
                                                </a>
                                            </div>
                                        </div>
                                        <div style={{ 
                                            color: "#003f6c", 
                                            fontWeight: 'bold',
                                            fontSize: '0.9rem',
                                            marginTop: '5px'
                                        }}>
                                            8:30am to 5pm, GMT+2 time, Sunday to Thursday.
                                        </div>
                                    </div>
                                </div>
                            </Navbar>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header1;
