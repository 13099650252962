import React from 'react'
import IL from '../../../src/il.png'
import UK from '../../../src/uk.png'
import WA from '../../../src/WA.jpg'

function Map() {
    return (
        <>
            <section className="contact-section py-5">
                <div className='container'>
                    <div className='row align-items-start'>
                        <div className="col-12 col-lg-7 mb-4 mb-lg-0">
                            <div className="contact-heading position-relative mb-4">
                                <h2 className="mb-4 text-center text-lg-start">
                                    <span className="font-w-4 d-block mb-2 fs-4 fs-lg-3">Fast Response</span>
                                    <span className="fs-2 fs-lg-1 fw-bold">Contact now.</span>
                                </h2>
                                {/* Desktop image */}
                                <img 
                                    className="d-none d-lg-block position-absolute" 
                                    style={{ 
                                        right: '0',
                                        top: '-50px',
                                        width: '45%',
                                        maxWidth: '300px'
                                    }} 
                                    src={WA} 
                                    alt="Contact"
                                />
                                {/* Mobile image */}
                                <img 
                                    className="d-lg-none w-100 mb-4" 
                                    src={WA} 
                                    alt="Contact"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 ms-auto">
                            <div className="contact-info">
                                <div className="d-flex align-items-center bg-white p-3 shadow-sm rounded mb-4">
                                    <div className="me-3">
                                        <div className="f-icon-s p-3 rounded" style={{ backgroundColor: "#d0faec" }}>
                                            <i className="flaticon-location"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <h5 className="mb-2">Address:</h5>
                                        <span className="text-black">South District, Beer-Sheva,84709 Israel</span>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center bg-white p-3 shadow-sm rounded mb-4">
                                    <div className="me-3">
                                        <div className="f-icon-s p-3 rounded" style={{ backgroundColor: "#d0faec" }}>
                                            <i className="flaticon-mail"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <h5 className="mb-2">Email us:</h5>
                                        <a className="btn-link text-break" href="mailto:support@epicfinal.com">support@epicfinal.com</a>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center bg-white p-3 shadow-sm rounded mb-4">
                                    <div className="me-3">
                                        <div className="f-icon-s p-3 rounded" style={{ backgroundColor: "#d0faec" }}>
                                            <i className="flaticon-telephone"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <h5 className="mb-2">Call us:</h5>
                                        <div className="d-flex flex-column gap-2">
                                            <a className="btn-link d-flex align-items-center" href="tel:+972515564767">
                                                <img src={IL} alt="Israel" className="me-2" style={{width: '20px'}} />
                                                <span>(+972) 515-564-767</span>
                                            </a>
                                            <a className="btn-link d-flex align-items-center" href="tel:+442392161214">
                                                <img src={UK} alt="UK" className="me-2" style={{width: '20px'}} />
                                                <span>(+44) 239-216-1214</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Map
