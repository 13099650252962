import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import './WooCommerce.css';
import patternSvg from './pattern.svg';

const WooCommerceEn = () => {
  const [showPricingModal, setShowPricingModal] = useState(false);

  // Add PayPal script load handler
  useEffect(() => {
    let scriptElement = null;
    
    const loadPayPalScript = () => {
      // Remove existing script if any
      if (scriptElement) {
        document.body.removeChild(scriptElement);
      }

      // Create new script element
      scriptElement = document.createElement('script');
      scriptElement.src = `https://www.paypal.com/sdk/js?client-id=AdWIx81WbV4y0eGSaVvcJ46FVo9-eSxz1Ao2rOy0glrPDdU_343yTk4xYg_NxffTknRiAbaYbNByFNcf&vault=true&intent=subscription&locale=en_US`;
      scriptElement.setAttribute('data-sdk-integration-source', 'button-factory');
      scriptElement.async = true;

      // Add load and error handlers
      scriptElement.onload = () => {
        console.log('PayPal script loaded successfully');
      };

      scriptElement.onerror = (error) => {
        console.error('Error loading PayPal script:', error);
      };

      // Append script to body
      document.body.appendChild(scriptElement);
    };

    loadPayPalScript();

    // Cleanup function
    return () => {
      if (scriptElement && document.body.contains(scriptElement)) {
        document.body.removeChild(scriptElement);
      }
    };
  }, []);

  // Add PayPal button renderer with error handling
  const renderPayPalButton = (planIndex) => {
    if (!window.paypal) {
      console.log('PayPal script not loaded yet');
      return null;
    }

    try {
      // English PayPal buttons
      if (planIndex === 0) {
        return (
          <div id="paypal-button-container-P-8YC13099PR982634MM6RGEZY"></div>
        );
      } else if (planIndex === 1) {
        return (
          <div id="paypal-button-container-P-32508119SA155922SM6RGJSQ"></div>
        );
      } else if (planIndex === 2) {
        return (
          <div id="paypal-button-container-P-4UD04094D3902033KM6RGLBQ"></div>
        );
      }
    } catch (error) {
      console.error('Error rendering PayPal button:', error);
      return null;
    }
    return null;
  };

  useEffect(() => {
    const initializePayPalButtons = () => {
      if (!window.paypal) {
        console.log('PayPal not loaded yet, waiting...');
        return;
      }

      try {
        // English PayPal buttons configuration
        const englishButtonConfigs = [
          {
            containerId: '#paypal-button-container-P-8YC13099PR982634MM6RGEZY',
            planId: 'P-8YC13099PR982634MM6RGEZY'
          },
          {
            containerId: '#paypal-button-container-P-32508119SA155922SM6RGJSQ',
            planId: 'P-32508119SA155922SM6RGJSQ'
          },
          {
            containerId: '#paypal-button-container-P-4UD04094D3902033KM6RGLBQ',
            planId: 'P-4UD04094D3902033KM6RGLBQ'
          }
        ];

        englishButtonConfigs.forEach(config => {
          const container = document.querySelector(config.containerId);
          if (container) {
            window.paypal.Buttons({
              style: {
                shape: 'rect',
                color: 'blue',
                layout: 'vertical',
                label: 'subscribe'
              },
              createSubscription: function(data, actions) {
                return actions.subscription.create({
                  plan_id: config.planId
                });
              },
              onApprove: function(data, actions) {
                alert(data.subscriptionID);
              },
              onError: function(err) {
                console.error('PayPal button error:', err);
              }
            }).render(config.containerId);
          }
        });
      } catch (error) {
        console.error('Error initializing PayPal buttons:', error);
      }
    };

    // Initialize buttons when modal is shown
    if (showPricingModal) {
      // Add a small delay to ensure DOM elements are ready
      const timer = setTimeout(initializePayPalButtons, 500);
      return () => clearTimeout(timer);
    }
  }, [showPricingModal]);

  useEffect(() => {
    // Initialize AOS animation library
    if (typeof window !== 'undefined') {
      const AOS = require('aos');
      AOS.init({
        duration: 1000,
        once: true,
      });
    }
  }, []);

  const plans = [
    {
      name: "Starter",
      specs: {
        cpu: "1 Core",
        ram: "2GB RAM",
        storage: "100GB SSD",
        bandwidth: "20Mbps",
        traffic: "Unlimited"
      },
      price: "$68"
    },
    {
      name: "Growth",
      specs: {
        cpu: "2 Cores",
        ram: "4GB RAM",
        storage: "100GB SSD",
        bandwidth: "20Mbps",
        traffic: "Unlimited"
      },
      price: "$139"
    },
    {
      name: "Advanced",
      specs: {
        cpu: "4 Cores",
        ram: "8GB RAM",
        storage: "200GB SSD",
        bandwidth: "50Mbps",
        traffic: "Unlimited"
      },
      price: "$219"
    }
  ];

  const features = [
    "Full Management",
    "Snapshot Backup",
    "Remote Cloud Backup (7 days)",
    "Updates & Security Patches",
    "Server Monitoring",
    "Infrastructure Support",
    "Technical Consulting",
    "SSL Certificate",
    "Cyber Security System",
  ];

  const handleStartNow = (e) => {
    e.preventDefault();
    setShowPricingModal(true);
  };

  const fadeInUp = {
    initial: { y: 60, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    transition: { duration: 0.6 }
  };

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Managed WooCommerce Hosting | Professional Online Store Solutions | EpicFinal</title>
        <meta name="description" content="Professional managed WooCommerce hosting with excellent performance, advanced security, and 24/7 support. Build your professional online store with all the tools for business success." />
        <meta name="keywords" content="WooCommerce, online store, managed hosting, cloud hosting, ecommerce, credit card processing, online shop, digital store, payment gateway" />
        <meta property="og:title" content="Managed WooCommerce Hosting | Professional Online Store Solutions | EpicFinal" />
        <meta property="og:description" content="Build your professional online store with our managed WooCommerce solution. Fast hosting, advanced security, and expert support 24/7." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://epicfinal.com/en/woocommerce" />
        <meta property="og:site_name" content="EpicFinal" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:image" content="https://epicfinal.com/Woo_logo_white.svg" />
        <meta property="og:image:alt" content="WooCommerce Logo" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Managed WooCommerce Hosting | Professional Online Store Solutions" />
        <meta name="twitter:description" content="Build your professional online store with our managed WooCommerce solution. Fast hosting, advanced security, and expert support 24/7." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://epicfinal.com/en/woocommerce" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "Managed WooCommerce Hosting",
            "description": "Professional managed WooCommerce hosting with excellent performance, advanced security, and 24/7 support",
            "brand": {
              "@type": "Brand",
              "name": "EpicFinal"
            },
            "offers": {
              "@type": "AggregateOffer",
              "priceCurrency": "USD",
              "lowPrice": "68",
              "highPrice": "219",
              "offerCount": "3",
              "priceValidUntil": new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0]
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.8",
              "reviewCount": "127"
            }
          })}
        </script>
        <link href="https://unpkg.com/aos@2.3.1/dist/aos.css" rel="stylesheet" />
      </Helmet>

      <div className="woocommerce-container" style={{ direction: 'ltr', textAlign: 'left' }}>
        <motion.div 
          className="woocommerce-hero"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div className="hero-overlay" style={{ background: `url(${patternSvg}) repeat` }}></div>
          <div className="container">
            <div className="hero-content">
              <motion.div 
                className="woocommerce-logo"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <img src="/Woo_logo_white.svg" alt="WooCommerce Logo" />
              </motion.div>
              <motion.h1 
                className="main-title"
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.6 }}
              >
                Managed WooCommerce Cloud Hosting
                <span className="gradient-text">The Perfect Solution for Your Online Business</span>
              </motion.h1>
              <motion.p 
                className="meta-description"
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.6 }}
              >
                Experience our managed WooCommerce cloud solution: fast hosting, secure online store, professional support, complete SEO optimization, and performance tuning to maximize your sales.
              </motion.p>
              <motion.div 
                className="hero-buttons"
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.6 }}
              >
                <a href="#" className="btn-primary" onClick={handleStartNow}>
                  <i className="fas fa-rocket"></i>
                  Start Now
                </a>
              </motion.div>
            </div>
          </div>
        </motion.div>

        <section id="features" className="features-section">
          <div className="container">
            <div className="section-header" data-aos="fade-up">
              <span className="section-subtitle">Why Choose Us?</span>
              <h2 className="section-title">Why Choose Managed WooCommerce Cloud Hosting?</h2>
            </div>
            <div className="features-grid">
              <motion.div 
                className="feature-card"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="feature-icon">
                  <i className="fas fa-tasks"></i>
                </div>
                <h3>Full Management & Time Savings</h3>
                <ul>
                  <li><i className="fas fa-check-circle"></i>No need to waste time on server maintenance</li>
                  <li><i className="fas fa-check-circle"></i>Centralized management in one place</li>
                  <li><i className="fas fa-check-circle"></i>Focus on marketing and sales</li>
                </ul>
              </motion.div>

              <motion.div 
                className="feature-card"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="feature-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                <h3>High Performance & Enhanced Shopping Experience</h3>
                <ul>
                  <li><i className="fas fa-check-circle"></i>Optimal cloud hosting infrastructure</li>
                  <li><i className="fas fa-check-circle"></i>Fast loading times</li>
                  <li><i className="fas fa-check-circle"></i>Improved conversion rates</li>
                </ul>
              </motion.div>

              <motion.div 
                className="feature-card"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="feature-icon">
                  <i className="fas fa-shield-alt"></i>
                </div>
                <h3>Advanced Multi-Layer Security</h3>
                <ul>
                  <li><i className="fas fa-check-circle"></i>Professional protection against cyber attacks</li>
                  <li><i className="fas fa-check-circle"></i>Automatic daily backups</li>
                  <li><i className="fas fa-check-circle"></i>Built-in SSL certificate</li>
                </ul>
              </motion.div>
            </div>
          </div>
        </section>

        <section className="seo-section">
          <div className="container">
            <div className="section-header" data-aos="fade-up">
              <span className="section-subtitle">SEO Optimization</span>
              <h2 className="section-title">SEO & Digital Marketing Ready</h2>
            </div>
            <div className="benefits-grid" data-aos="fade-up" data-aos-delay="100">
              <div className="benefit-card">
                <i className="fas fa-server"></i>
                <h3>Advanced Infrastructure</h3>
                <p>Servers optimized for HTTP/2 and HTTPS protocols</p>
              </div>
              <div className="benefit-card">
                <i className="fas fa-search"></i>
                <h3>Built-in Optimization</h3>
                <p>Support for leading SEO plugins</p>
              </div>
              <div className="benefit-card">
                <i className="fas fa-link"></i>
                <h3>SEO-Friendly URLs</h3>
                <p>Meta tags and full control over settings</p>
              </div>
            </div>
          </div>
        </section>

        <section className="support-section">
          <div className="container">
            <div className="section-header" data-aos="fade-up">
              <span className="section-subtitle">Professional Support</span>
              <h2 className="section-title">Technical Support</h2>
            </div>
            <div className="support-grid" data-aos="fade-up" data-aos-delay="100">
              <div className="support-image">
                <svg width="400" height="300" viewBox="0 0 400 300" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <linearGradient id="supportGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                      <stop offset="0%" stopColor="#96588a" />
                      <stop offset="100%" stopColor="#7c3977" />
                    </linearGradient>
                  </defs>
                  <circle cx="200" cy="150" r="120" fill="url(#supportGradient)" opacity="0.1"/>
                  <g transform="translate(130,90)">
                    <circle cx="70" cy="70" r="50" fill="url(#supportGradient)"/>
                    <path d="M70 40 A30 30 0 0 1 100 70 A30 30 0 0 1 70 100 A30 30 0 0 1 40 70 A30 30 0 0 1 70 40" 
                          fill="white" opacity="0.9"/>
                    <circle cx="70" cy="60" r="15" fill="white"/>
                  </g>
                  <g transform="translate(220,120)">
                    <rect x="0" y="0" width="80" height="40" rx="20" fill="white"/>
                    <rect x="-60" y="50" width="60" height="30" rx="15" fill="white"/>
                  </g>
                </svg>
              </div>
              <div className="support-features">
                <div className="support-feature">
                  <i className="fas fa-users"></i>
                  <div>
                    <h3>Professional Team</h3>
                    <p>Extensive experience in computing infrastructure and applications</p>
                  </div>
                </div>
                <div className="support-feature">
                  <i className="fas fa-tools"></i>
                  <div>
                    <h3>Technical Assistance</h3>
                    <p>Implementation of payment gateways and inventory management</p>
                  </div>
                </div>
                <div className="support-feature">
                  <i className="fas fa-chart-line"></i>
                  <div>
                    <h3>Business Growth</h3>
                    <p>Supporting your business growth</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="scalability-section">
          <div className="container">
            <div className="section-header" data-aos="fade-up">
              <span className="section-subtitle">Business Growth</span>
              <h2 className="section-title">Scalability Without Limits</h2>
            </div>
            <div className="scalability-grid" data-aos="fade-up" data-aos-delay="100">
              <div className="scalability-card">
                <i className="fas fa-server"></i>
                <h3>Resource Expansion</h3>
                <p>Easy server resource scaling</p>
              </div>
              <div className="scalability-card">
                <i className="fas fa-tags"></i>
                <h3>Full Management</h3>
                <p>Complete infrastructure and plugin management</p>
              </div>
              <div className="scalability-card">
                <i className="fas fa-cloud"></i>
                <h3>High Availability Infrastructure</h3>
                <p>99.99% uptime with redundant infrastructure</p>
              </div>
            </div>
          </div>
        </section>

        <section className="cta-section">
          <div className="container">
            <div className="cta-content" data-aos="fade-up">
              <h2>Ready to Take Your Online Store to the Next Level?</h2>
              <p>Contact us for a free consultation</p>
              <div className="cta-buttons">
                <Link to="/en/contact" className="btn-primary">
                  <i className="fas fa-paper-plane"></i>
                  Contact Us
                </Link>            
              </div>
            </div>
          </div>
        </section>

        <section className="summary-section">
          <div className="container">
            <div className="summary-content" data-aos="fade-up">
              <div className="summary-icon">
                <i className="fas fa-rocket"></i>
              </div>
              <h2>Let Us Handle the Technology – You Focus on Growing Sales!</h2>
              <p>Choose our managed WooCommerce cloud solution and start selling online smartly and efficiently.</p>
              <a href="#" className="btn-primary" onClick={handleStartNow}>
                <i className="fas fa-arrow-right"></i>
                Start Now
              </a>
            </div>
          </div>
        </section>
      </div>

      {/* Pricing Modal */}
      <Modal 
        show={showPricingModal} 
        onHide={() => setShowPricingModal(false)}
        size="xl"
        className="pricing-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose Your Hosting Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pricing-plans-container">
            <div className="deployment-info">
              <div className="info-box">
                <i className="fas fa-clock"></i>
                <p>Average deployment time: ~2 hours</p>
              </div>
              <div className="info-box highlight">
                <i className="fas fa-cogs"></i>
                <p>Managed Cloud Infrastructure</p>
              </div>
              <div className="info-box">
                <i className="fas fa-envelope"></i>
                <p>
                  After ordering, please contact:
                  <a href="mailto:support@epicfinal.com">support@epicfinal.com</a>
                </p>
              </div>
            </div>
            
            <Row>
              {plans.map((plan, index) => (
                <Col md={4} key={index}>
                  <div className={`pricing-card ${index === 1 ? 'popular' : ''}`}>
                    {index === 1 && (
                      <div className="popular-badge">Most Popular</div>
                    )}
                    <div className="pricing-card-header">
                      <h3 className="plan-name">{plan.name}</h3>
                      <div className="price-container">
                        <span className="price">{plan.price}</span>
                        <span className="period">/month</span>
                      </div>
                    </div>
                    
                    <div className="pricing-card-content">
                      <ul className="specs-list">
                        <li>
                          <i className="fas fa-microchip"></i>
                          <span>{plan.specs.cpu}</span>
                        </li>
                        <li>
                          <i className="fas fa-memory"></i>
                          <span>{plan.specs.ram}</span>
                        </li>
                        <li>
                          <i className="fas fa-hdd"></i>
                          <span>{plan.specs.storage}</span>
                        </li>
                        <li>
                          <i className="fas fa-network-wired"></i>
                          <span>{plan.specs.bandwidth}</span>
                        </li>
                        <li>
                          <i className="fas fa-exchange-alt"></i>
                          <span>{plan.specs.traffic}</span>
                        </li>
                      </ul>
                      <div className="terms-disclaimer">
                        <small>
                          By making a purchase, you agree to our
                          <a href="/en/terms_conditions" target="_blank" rel="noopener noreferrer">
                            Terms and Conditions
                          </a>
                        </small>
                      </div>
                      {renderPayPalButton(index)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>

            <div className="features-section">
              <div className="features-header">
                <h4 className="features-title">All Plans Include</h4>
                <p className="features-subtitle">Everything you need to run your application successfully</p>
              </div>
              <div className="features-container">
                {features.map((feature, index) => {
                  const featureIcons = [
                    "fa-cogs",
                    "fa-camera",
                    "fa-cloud",
                    "fa-shield-alt",
                    "fa-chart-line",
                    "fa-tools",
                    "fa-comments",
                    "fa-lock",
                    "fa-user-shield"
                  ];

                  return (
                    <div key={index} className="feature-box">
                      <div className="feature-icon">
                        <i className={`fas ${featureIcons[index]}`}></i>
                      </div>
                      <div className="feature-text">{feature}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WooCommerceEn; 