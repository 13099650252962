import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, Form, Button, InputGroup, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { products, categories } from './marketplaceData';
import './Marketplace.css';

const Marketplace = () => {
  const location = useLocation();
  const [language, setLanguage] = useState(location.state?.language || 'en');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [showDeployModal, setShowDeployModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // SEO Meta Description based on language
  const getMetaDescription = () => {
    return language === 'en'
      ? 'Discover and deploy cloud software solutions. Our marketplace offers a wide range of managed instances for development tools, analytics, security, and more.'
      : 'גלה והטמע פתרונות תוכנה בענן. שוק המוצרים שלנו מציע מגוון רחב של שירותים מנוהלים לכלי פיתוח, אנליטיקה, אבטחה ועוד.';
  };

  // Add structured data for SEO
  const getStructuredData = () => {
    return {
      "@context": "https://schema.org",
      "@type": "ItemList",
      "itemListElement": products.map((product, index) => ({
        "@type": "SoftwareApplication",
        "position": index + 1,
        "name": language === 'en' ? product.name : product.nameHe,
        "description": language === 'en' ? product.description : product.descriptionHe,
        "applicationCategory": product.category,
        "offers": {
          "@type": "Offer",
          "price": "68",
          "priceCurrency": language === 'en' ? "USD" : "ILS"
        }
      }))
    };
  };

  const filteredProducts = products.filter(product => {
    const matchesCategory = selectedCategory === 'All' || product.category === selectedCategory;
    const searchableText = language === 'en' 
      ? `${product.name} ${product.description} ${product.category} ${product.provider}`.toLowerCase()
      : `${product.nameHe} ${product.descriptionHe} ${product.category} ${product.provider}`.toLowerCase();
    const matchesSearch = searchTerm === '' || searchableText.includes(searchTerm.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  const getLocalizedCategory = (category) => {
    const foundCategory = categories.find(c => c.en === category);
    return language === 'en' ? foundCategory.en : foundCategory.he;
  };

  const handleDeploy = (product) => {
    setSelectedProduct(product);
    setShowDeployModal(true);
  };

  const plans = {
    en: [
      {
        name: "Starter",
        specs: {
          cpu: "1 Core",
          ram: "2GB RAM",
          storage: "100GB SSD",
          bandwidth: "20Mbps",
          traffic: "Unlimited"
        },
        price: "$68"
      },
      {
        name: "Growth",
        specs: {
          cpu: "2 Cores",
          ram: "4GB RAM",
          storage: "100GB SSD",
          bandwidth: "20Mbps",
          traffic: "Unlimited"
        },
        price: "$139"
      },
      {
        name: "Advanced",
        specs: {
          cpu: "4 Cores",
          ram: "8GB RAM",
          storage: "200GB SSD",
          bandwidth: "50Mbps",
          traffic: "Unlimited"
        },
        price: "$219"
      }
    ],
    he: [
      {
        name: "סטנדרטי",
        specs: {
          cpu: "ליבה 1",
          ram: "זיכרון RAM: 2GB",
          storage: "אחסון: 100GB SSD",
          bandwidth: "רוחב פס: 20Mbps",
          traffic: "תעבורה: ללא הגבלה"
        },
        price: "219 ₪"
      },
      {
        name: "עסקי",
        specs: {
          cpu: "2 ליבות",
          ram: "זיכרון RAM: 4GB",
          storage: "אחסון: 100GB SSD",
          bandwidth: "רוחב פס: 20Mbps",
          traffic: "תעבורה: ללא הגבלה"
        },
        price: "439 ₪"
      },
      {
        name: "מתקדם",
        specs: {
          cpu: "4 ליבות",
          ram: "זיכרון RAM: 8GB",
          storage: "אחסון: 200GB SSD",
          bandwidth: "רוחב פס: 50Mbps",
          traffic: "תעבורה: ללא הגבלה"
        },
        price: "699 ₪"
      }
    ]
  };

  const features = {
    en: [
      "Full Management",
      "Snapshot Backup",
      "Remote Cloud Backup (7 days)",
      "Updates & Security Patches",
      "Server Monitoring",
      "Infrastructure Support",
      "Technical Consulting",
      "SSL Certificate",
      "Cyber Security System",
      "High-Speed SSD Infrastructure"
    ],
    he: [
      "ניהול מלא",
      "גיבוי Snapshot",
      "גיבוי לענן מרוחק (7 ימים)",
      "עדכונים ועדכוני אבטחה",
      "ניטור שרת",
      "תמיכה בתשתית",
      "ייעוץ טכני",
      "תעודת SSL",
      "מערכת הגנת סייבר",
    ]
  };

  // Add background icons component
  const BackgroundIcons = () => {
    const icons = [
      // Server & Infrastructure
      'fas fa-server',
      'fas fa-network-wired',
      'fas fa-database',
      'fas fa-hdd',
      'fas fa-memory',
      'fas fa-microchip',
      'fas fa-sitemap',
      'fas fa-router',
      'fas fa-ethernet',
      // Cloud & Services
      'fas fa-cloud',
      'fas fa-cloud-upload-alt',
      'fas fa-cloud-download-alt',
      'fas fa-cloud-meatball',
      'fas fa-tasks',
      'fas fa-project-diagram',
      // Security
      'fas fa-shield-alt',
      'fas fa-user-shield',
      'fas fa-lock',
      'fas fa-key',
      'fas fa-fingerprint',
      // Development
      'fas fa-code',
      'fas fa-laptop-code',
      'fas fa-terminal',
      'fas fa-bug',
      'fas fa-code-branch',
      // Analytics & Monitoring
      'fas fa-chart-line',
      'fas fa-chart-bar',
      'fas fa-chart-pie',
      'fas fa-tachometer-alt',
      'fas fa-analytics',
      // Tools & Settings
      'fas fa-tools',
      'fas fa-wrench',
      'fas fa-cogs',
      'fas fa-sliders-h',
      'fas fa-screwdriver'
    ];

    const getRandomAnimation = () => {
      const animations = [
        'floatUpDown',
        'floatLeftRight',
        'rotate',
        'pulse',
        'bounce'
      ];
      return animations[Math.floor(Math.random() * animations.length)];
    };

    const getRandomSize = () => {
      // Bigger base sizes
      const sizes = [24, 28, 32, 36, 40, 44, 48];
      return sizes[Math.floor(Math.random() * sizes.length)];
    };

    const getRandomOpacity = () => {
      // Increased base opacity
      return (Math.random() * 0.15) + 0.12;
    };

    const getRandomDuration = () => {
      // Slightly faster animations
      return Math.floor(Math.random() * 15) + 8;
    };

    const getRandomDelay = () => {
      return Math.random() * 5;
    };

    const getRandomPosition = () => {
      // Add some padding to keep icons away from edges
      const padding = 10;
      return Math.random() * (100 - 2 * padding) + padding;
    };

    return (
      <div className="background-icons">
        {[...Array(80)].map((_, index) => (
          <i 
            key={index}
            className={`${icons[index % icons.length]} background-icon`}
            style={{
              left: `${getRandomPosition()}%`,
              top: `${getRandomPosition()}%`,
              fontSize: `${getRandomSize()}px`,
              opacity: getRandomOpacity(),
              animation: `${getRandomAnimation()} ${getRandomDuration()}s infinite ease-in-out, glow ${getRandomDuration() / 2}s infinite ease-in-out`,
              animationDelay: `${getRandomDelay()}s`,
              transform: `rotate(${Math.random() * 360}deg)`,
              zIndex: Math.floor(Math.random() * 3)
            }}
          />
        ))}
      </div>
    );
  };

  // Add PayPal script load handler
  useEffect(() => {
    let scriptElement = null;
    
    const loadPayPalScript = () => {
      // Remove existing script if any
      if (scriptElement) {
        document.body.removeChild(scriptElement);
      }

      // Create new script element
      scriptElement = document.createElement('script');
      scriptElement.src = `https://www.paypal.com/sdk/js?client-id=AdWIx81WbV4y0eGSaVvcJ46FVo9-eSxz1Ao2rOy0glrPDdU_343yTk4xYg_NxffTknRiAbaYbNByFNcf&vault=true&intent=subscription&locale=${language === 'en' ? 'en_US' : 'he_IL'}`;
      scriptElement.setAttribute('data-sdk-integration-source', 'button-factory');
      scriptElement.async = true;

      // Add load and error handlers
      scriptElement.onload = () => {
        console.log('PayPal script loaded successfully');
      };

      scriptElement.onerror = (error) => {
        console.error('Error loading PayPal script:', error);
      };

      // Append script to body
      document.body.appendChild(scriptElement);
    };

    loadPayPalScript();

    // Cleanup function
    return () => {
      if (scriptElement && document.body.contains(scriptElement)) {
        document.body.removeChild(scriptElement);
      }
    };
  }, [language]); // Add language dependency to reload script when language changes

  // Add PayPal button renderer with error handling
  const renderPayPalButton = (planIndex) => {
    if (!window.paypal) {
      console.log('PayPal script not loaded yet');
      return null;
    }

    try {
      if (language === 'en') {
        // English PayPal buttons
        if (planIndex === 0) {
          return (
            <div id="paypal-button-container-P-8YC13099PR982634MM6RGEZY"></div>
          );
        } else if (planIndex === 1) {
          return (
            <div id="paypal-button-container-P-32508119SA155922SM6RGJSQ"></div>
          );
        } else if (planIndex === 2) {
          return (
            <div id="paypal-button-container-P-4UD04094D3902033KM6RGLBQ"></div>
          );
        }
      } else {
        // Hebrew PayPal buttons
        if (planIndex === 0) {
          return (
            <div id="paypal-button-container-P-40696904NP245520GM6RGYYY"></div>
          );
        } else if (planIndex === 1) {
          return (
            <div id="paypal-button-container-P-9Y5799824B107742KM6RG5LY"></div>
          );
        } else if (planIndex === 2) {
          return (
            <div id="paypal-button-container-P-30208148FW311183BM6RG6PQ"></div>
          );
        }
      }
    } catch (error) {
      console.error('Error rendering PayPal button:', error);
      return null;
    }
    return null;
  };

  useEffect(() => {
    const initializePayPalButtons = () => {
      if (!window.paypal) {
        console.log('PayPal not loaded yet, waiting...');
        return;
      }

      try {
        if (language === 'en') {
          // English PayPal buttons configuration
          const buttonConfigs = [
            {
              containerId: '#paypal-button-container-P-8YC13099PR982634MM6RGEZY',
              planId: 'P-8YC13099PR982634MM6RGEZY'
            },
            {
              containerId: '#paypal-button-container-P-32508119SA155922SM6RGJSQ',
              planId: 'P-32508119SA155922SM6RGJSQ'
            },
            {
              containerId: '#paypal-button-container-P-4UD04094D3902033KM6RGLBQ',
              planId: 'P-4UD04094D3902033KM6RGLBQ'
            }
          ];

          buttonConfigs.forEach(config => {
            const container = document.querySelector(config.containerId);
            if (container) {
              window.paypal.Buttons({
                style: {
                  shape: 'rect',
                  color: 'blue',
                  layout: 'vertical',
                  label: 'subscribe'
                },
                createSubscription: function(data, actions) {
                  return actions.subscription.create({
                    plan_id: config.planId
                  });
                },
                onApprove: function(data, actions) {
                  alert(data.subscriptionID);
                },
                onError: function(err) {
                  console.error('PayPal button error:', err);
                }
              }).render(config.containerId);
            }
          });
        } else {
          // Hebrew PayPal buttons configuration
          const hebrewButtonConfigs = [
            {
              containerId: '#paypal-button-container-P-40696904NP245520GM6RGYYY',
              planId: 'P-40696904NP245520GM6RGYYY'
            },
            {
              containerId: '#paypal-button-container-P-9Y5799824B107742KM6RG5LY',
              planId: 'P-9Y5799824B107742KM6RG5LY'
            },
            {
              containerId: '#paypal-button-container-P-30208148FW311183BM6RG6PQ',
              planId: 'P-30208148FW311183BM6RG6PQ'
            }
          ];

          hebrewButtonConfigs.forEach(config => {
            const container = document.querySelector(config.containerId);
            if (container) {
              window.paypal.Buttons({
                style: {
                  shape: 'rect',
                  color: 'blue',
                  layout: 'vertical',
                  label: 'subscribe'
                },
                createSubscription: function(data, actions) {
                  return actions.subscription.create({
                    plan_id: config.planId
                  });
                },
                onApprove: function(data, actions) {
                  alert(data.subscriptionID);
                },
                onError: function(err) {
                  console.error('PayPal button error:', err);
                }
              }).render(config.containerId);
            }
          });
        }
      } catch (error) {
        console.error('Error initializing PayPal buttons:', error);
      }
    };

    // Initialize buttons when modal is shown
    if (showDeployModal) {
      // Add a small delay to ensure DOM elements are ready
      const timer = setTimeout(initializePayPalButtons, 500);
      return () => clearTimeout(timer);
    }
  }, [showDeployModal, language]);

  return (
    <>
      <Helmet>
        <title>{language === 'en' ? 'Cloud Marketplace - Deploy Managed Instances' : 'שוק הענן - הטמעת שירותים מנוהלים'}</title>
        <meta name="description" content={getMetaDescription()} />
        <meta name="keywords" content={categories.map(cat => language === 'en' ? cat.en : cat.he).join(', ')} />
        <html lang={language === 'en' ? 'en' : 'he'} />
        <meta property="og:title" content={language === 'en' ? 'Cloud Marketplace' : 'שוק הענן'} />
        <meta property="og:description" content={getMetaDescription()} />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">
          {JSON.stringify(getStructuredData())}
        </script>
        {language === 'he' && <meta name="robots" content="noindex" />}
      </Helmet>

      <main className="marketplace-container" dir={language === 'he' ? 'rtl' : 'ltr'}>
        <BackgroundIcons />
        <header className="marketplace-header">
          <Container>
            <div className="header-content">
              <div className="header-top">
                <h1>
                  {language === 'en' ? 'Cloud Marketplace' : 'מוצרי ענן'}
                  <span className="beta-badge">
                    {language === 'en' ? 'Beta' : 'בטא'}
                  </span>
                </h1>
                <nav className="language-switch" aria-label="Language Selection">
                  <Button 
                    variant={language === 'en' ? 'primary' : 'outline-primary'}
                    onClick={() => {
                      setLanguage('en');
                      window.history.replaceState({ language: 'en' }, '', '/MARKETPLACE');
                    }}
                    size="sm"
                    aria-label="Switch to English"
                  >
                    EN
                  </Button>
                  <Button 
                    variant={language === 'he' ? 'primary' : 'outline-primary'}
                    onClick={() => {
                      setLanguage('he');
                      window.history.replaceState({ language: 'he' }, '', '/MARKETPLACE');
                    }}
                    size="sm"
                    aria-label="Switch to Hebrew"
                  >
                    עב
                  </Button>
                </nav>
              </div>
              <p className="header-subtitle">
                {language === 'en' 
                  ? 'Discover, deploy, and manage software that runs on cloud' 
                  : 'גלה, הטמע ונהל תוכנות שרצות בענן'}
              </p>
            </div>
          </Container>
        </header>

        <Container className="main-content">
          <Row>
            <Col lg={3} md={4}>
              <div className="sidebar">
                <div className="sidebar-section">
                  <h5>{language === 'en' ? 'Categories' : 'קטגוריות'}</h5>
                  <div className="category-list">
                    {categories.map(category => (
                      <Button
                        key={category.en}
                        variant="link"
                        className={`category-link ${selectedCategory === category.en ? 'active' : ''}`}
                        onClick={() => setSelectedCategory(category.en)}
                      >
                        {language === 'en' ? category.en : category.he}
                        <span className="category-count">
                          {products.filter(p => category.en === 'All' || p.category === category.en).length}
                        </span>
                      </Button>
                    ))}
                  </div>
                </div>
              </div>
            </Col>
            
            <Col lg={9} md={8}>
              <div className="content-section">
                <div className="search-section">
                  <InputGroup>
                    <Form.Control
                      type="search"
                      placeholder={language === 'en' ? "Search products..." : "חיפוש מוצרים..."}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className={language === 'he' ? 'text-right' : ''}
                    />
                    <Button variant="outline-secondary">
                      {language === 'en' ? 'Search' : 'חיפוש'}
                    </Button>
                  </InputGroup>
                </div>

                {filteredProducts.length > 0 ? (
                  <div className="products-list">
                    {filteredProducts.map((product, index) => (
                      <Card key={index} className="product-card">
                        <Card.Body>
                          <div className="product-header">
                            <Card.Title>
                              {language === 'en' 
                                ? product.name 
                                : <>{product.nameHe} <span className="english-name">({product.name})</span></>
                              }
                            </Card.Title>
                            <span className="provider-badge">{product.provider}</span>
                          </div>
                          <Card.Text>
                            {language === 'en' ? product.description : product.descriptionHe}
                          </Card.Text>
                          <div className="product-footer">
                            <div className="product-footer-left">
                              <span className="category-tag">
                                {getLocalizedCategory(product.category)}
                              </span>
                              <div className="pricing-tag">
                                <div className="price-wrapper">
                                  <span className="starting-from">
                                    {language === 'en' ? 'Starting from' : 'החל מ-'}
                                  </span>
                                  <span className="amount">
                                    {language === 'en' ? '$68' : '219 ₪'}
                                  </span>
                                  <span className="period">
                                    {language === 'en' ? '/month' : '/חודש'}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="deploy-button-wrapper">
                              <Button variant="primary" size="sm" onClick={() => handleDeploy(product)}>
                                {language === 'en' ? 'Deploy Now' : 'התחל עכשיו'}
                              </Button>
                              <span className="managed-label">
                                {language === 'en' ? 'Fully Managed Instance' : 'מופע מחשוב מנוהל'}
                              </span>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    ))}
                  </div>
                ) : (
                  <div className="empty-state">
                    <h3>{language === 'en' ? 'No Products Found' : 'לא נמצאו מוצרים'}</h3>
                    <p>
                      {language === 'en' 
                        ? 'Try adjusting your search or filter to find what you\'re looking for.' 
                        : 'נסה להתאים את החיפוש או הסינון כדי למצוא את מה שאתה מחפש.'}
                    </p>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>

        <Modal 
          show={showDeployModal} 
          onHide={() => setShowDeployModal(false)}
          size="xl"
          dir={language === 'he' ? 'rtl' : 'ltr'}
          className="deploy-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {language === 'en' 
                ? 'Choose Deployment Plan' 
                : 'בחר תכנית הטמעה'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="pricing-plans-container">
              <div className="deployment-info">
                <div className="info-box">
                  <i className="fas fa-clock"></i>
                  <p>
                    {language === 'en' 
                      ? 'Average deployment time: ~2 hours'
                      : 'זמן הטמעה ממוצע: כשעתיים'}
                  </p>
                </div>
                <div className="info-box highlight">
                  <i className="fas fa-cogs"></i>
                  <p>
                    {language === 'en'
                      ? 'Fully Managed Instance'
                      : 'תשתית ענן מנוהלת'}
                  </p>
                </div>
                <div className="info-box">
                  <i className="fas fa-envelope"></i>
                  <p>
                    {language === 'en'
                      ? 'After ordering, please contact: '
                      : 'לאחר ההזמנה, יש ליצור קשר: '}
                    <a href="mailto:support@epicfinal.com">support@epicfinal.com</a>
                  </p>
                </div>
              </div>
              <Row className="pricing-row">
                {plans[language].map((plan, index) => (
                  <Col md={4} key={index}>
                    <div className={`pricing-card ${index === 1 ? 'popular' : ''}`}>
                      {index === 1 && (
                        <div className="popular-badge">
                          {language === 'en' ? 'Most Popular' : 'הכי פופולרי'}
                        </div>
                      )}
                      <div className="pricing-card-header">
                        <h3 className="plan-name">
                          {language === 'en' 
                            ? plan.name 
                            : <>{plan.name} <span className="english-name">({plans.en[index].name})</span></>
                          }
                        </h3>
                        <div className="price-container">
                          <span className="price">{plan.price}</span>
                          <span className="period">/{language === 'en' ? 'month' : 'חודש'}</span>
                        </div>
                      </div>
                      
                      <div className="pricing-card-body">
                        <ul className="specs-list">
                          <li>
                            <i className="fas fa-microchip"></i>
                            <span>{plan.specs.cpu}</span>
                          </li>
                          <li>
                            <i className="fas fa-memory"></i>
                            <span>{plan.specs.ram}</span>
                          </li>
                          <li>
                            <i className="fas fa-hdd"></i>
                            <span>{plan.specs.storage}</span>
                          </li>
                          <li>
                            <i className="fas fa-network-wired"></i>
                            <span>{plan.specs.bandwidth}</span>
                          </li>
                          <li>
                            <i className="fas fa-exchange-alt"></i>
                            <span>{plan.specs.traffic}</span>
                          </li>
                        </ul>
                        <div className="terms-disclaimer">
                          <small>
                            {language === 'en' 
                              ? 'By proceeding with the purchase, you agree to our '
                              : 'בביצוע הרכישה, הנך מסכים/ה ל'}
                            <a href="/terms_conditions" target="_blank" rel="noopener noreferrer">
                              {language === 'en' ? 'Terms & Conditions' : 'תנאי השימוש וההתקשרות'}
                            </a>
                          </small>
                        </div>
                        {index === 0 || index === 1 || index === 2 ? (
                          renderPayPalButton(index)
                        ) : (
                          <Button 
                            variant={index === 1 ? 'primary' : 'outline-primary'} 
                            className="select-plan-btn"
                          >
                            {language === 'en' ? 'Select Plan' : 'בחר תכנית'}
                          </Button>
                        )}
                      </div>
                    </div>
                  </Col>
                ))}
                <Col md={4}>
                  <div className="pricing-card custom-plan">
                    <div className="pricing-card-header">
                      <h3 className="plan-name">
                        {language === 'en' ? 'Enterprise' : 'ארגוני'}
                      </h3>
                      <div className="price-container custom">
                        <span className="custom-text">
                          {language === 'en' ? 'Custom Solution' : 'פתרון מותאם אישית'}
                        </span>
                      </div>
                    </div>
                    <div className="pricing-card-body">
                      <div className="custom-description">
                        <i className="fas fa-building"></i>
                        <p>
                          {language === 'en' 
                            ? 'Tailored solutions for large-scale enterprises with specific requirements'
                            : 'פתרונות מותאמים אישית לארגונים גדולים עם דרישות ספציפיות'}
                        </p>
                      </div>
                      <div className="enterprise-features">
                        <div className="enterprise-feature">
                          <i className="fas fa-expand-arrows-alt"></i>
                          <div className="enterprise-feature-text">
                            <h5>{language === 'en' ? 'Scaling' : 'גמישות '}</h5>
                            <p>{language === 'en' 
                              ? 'scale resources based on demand'
                              : 'התאמה של משאבים לפי דרישה'}
                            </p>
                          </div>
                        </div>
                        <div className="enterprise-feature">
                          <i className="fas fa-shield-virus"></i>
                          <div className="enterprise-feature-text">
                            <h5>{language === 'en' ? 'High Availability' : 'זמינות גבוהה'}</h5>
                            <p>
                              {language === 'en'
                                ? 'Ensure maximum uptime (99.9% availability) with automated failover and load balancing across multiple zones'
                                : 'הבטחת זמינות מקסימלית (99.9% זמינות) עם גיבוי אוטומטי ואיזון עומסים במספר אזורים'}
                            </p>
                          </div>
                        </div>
                      </div>
                      <Button 
                        variant="outline-primary" 
                        className="contact-btn"
                      >
                        <i className="fas fa-envelope"></i>
                        <a href="/contact1" style={{ color: 'blue' }}>{language === 'en' ? 'Contact Sales' : 'צור קשר'}</a>
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="features-section">
                <div className="features-header">
                  <h4 className="features-title">
                    {language === 'en' ? 'All Plans Include' : 'כל התכניות כוללות'}
                  </h4>
                  <p className="features-subtitle">
                    {language === 'en' 
                      ? 'Everything you need to run your application successfully'
                      : 'כל מה שצריך כדי להפעיל את האפליקציה שלך בהצלחה'}
                  </p>
                </div>
                <div className="features-container">
                  {features[language].map((feature, index) => {
                    const featureIcons = [
                      "fa-cogs",
                      "fa-camera",
                      "fa-cloud",
                      "fa-shield-alt",
                      "fa-chart-line",
                      "fa-tools",
                      "fa-comments",
                      "fa-lock",
                      "fa-user-shield",
                      "fa-bolt"
                    ];

                    return (
                      <div key={index} className="feature-box">
                        <div className="feature-icon">
                          <i className={`fas ${featureIcons[index]}`}></i>
                        </div>
                        <div className="feature-text">
                          {feature}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* Add-on Options Section */}
              <div className="features-section upgrades-section">
                <div className="features-header">
                  <h4 className="features-title">
                    {language === 'en' ? 'Available Upgrades' : 'שדרוגים זמינים'}
                  </h4>
                  <p className="features-subtitle">
                    {language === 'en' 
                      ? 'Enhance your instance with these powerful add-ons after deployment'
                      : 'שדרג את השרת שלך עם תוספות לאחר ההטמעה'}
                  </p>
                </div>
                <div className="upgrades-container">
                  {/* Dedicated CPU Option */}
                  <div className="upgrade-box">
                    <div className="upgrade-icon">
                      <i className="fas fa-microchip"></i>
                    </div>
                    <div className="upgrade-content">
                      <h5>{language === 'en' ? 'Dedicated CPU Core' : 'ליבת מעבד ייעודית'}</h5>
                      <p>
                        {language === 'en'
                          ? 'Add dedicated CPU cores for enhanced performance and consistent processing power'
                          : 'הוסף ליבות מעבד ייעודיות לביצועים משופרים וכוח עיבוד עקבי'}
                      </p>
                      <div className="upgrade-price">
                        <span className="price">
                          {language === 'en' ? '+$29' : '+99 ₪'}
                        </span>
                        <span className="period">
                          {language === 'en' ? '/core/month' : '/ליבה/חודש'}
                        </span>
                      </div>
                      <div className="upgrade-note">
                        <i className="fas fa-info-circle"></i>
                        <span>
                          {language === 'en'
                            ? 'Available after deployment'
                            : 'זמין לאחר ההטמעה'}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* High Availability Option */}
                  <div className="upgrade-box">
                    <div className="upgrade-icon">
                      <i className="fas fa-server"></i>
                    </div>
                    <div className="upgrade-content">
                      <h5>{language === 'en' ? 'High Availability' : 'זמינות גבוהה'}</h5>
                      <p>
                        {language === 'en'
                          ? 'Ensure maximum uptime (99.9% availability) with automated failover and load balancing across multiple zones'
                          : 'הבטחת זמינות מקסימלית (99.9% זמינות) עם גיבוי אוטומטי ואיזון עומסים במספר אזורים'}
                      </p>
                      <div className="upgrade-price">
                        <span className="price">
                          {language === 'en' ? 'From $68' : 'החל מ- 219 ₪'}
                        </span>
                        <span className="period">
                          {language === 'en' ? '/month' : 'לחודש'}
                        </span>
                      </div>
                      <div className="upgrade-note">
                        <i className="fas fa-info-circle"></i>
                        <span>
                          {language === 'en'
                            ? 'Can be enabled post-deployment'
                            : 'ניתן להפעיל לאחר ההטמעה'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    </>
  );
};

export default Marketplace; 