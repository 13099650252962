export const categories = [
  { en: "All", he: "הכל", slug: "all", metaDescription: { en: "All cloud software solutions", he: "כל פתרונות התוכנה בענן" } },
  { en: "Development Tools", he: "כלי פיתוח", slug: "development-tools", metaDescription: { en: "Software development and deployment tools", he: "כלי פיתוח והטמעת תוכנה" } },
  { en: "Analytics & Monitoring", he: "ניתוח וניטור", slug: "analytics-monitoring", metaDescription: { en: "Data analytics and system monitoring tools", he: "כלי ניתוח נתונים וניטור מערכות" } },
  { en: "Content Management", he: "ניהול תוכן", slug: "content-management", metaDescription: { en: "Content management systems and tools", he: "מערכות וכלים לניהול תוכן" } },
  { en: "Productivity", he: "פרודוקטיביות", slug: "productivity", metaDescription: { en: "Productivity and workflow optimization tools", he: "כלים לייעול תהליכי עבודה ופרודוקטיביות" } },
  { en: "Security", he: "אבטחה", slug: "security", metaDescription: { en: "Security and authentication solutions", he: "פתרונות אבטחה ואימות" } },
  { en: "Database & Storage", he: "מסד נתונים ואחסון", slug: "database-storage", metaDescription: { en: "Database management and storage solutions", he: "פתרונות לניהול מסדי נתונים ואחסון" } },
  { en: "Communication", he: "תקשורת", slug: "communication", metaDescription: { en: "Communication and collaboration tools", he: "כלי תקשורת ושיתוף פעולה" } },
  { en: "AI & Machine Learning", he: "בינה מלאכותית ולמידת מכונה", slug: "ai-ml", metaDescription: { en: "Artificial Intelligence and Machine Learning solutions", he: "פתרונות בינה מלאכותית ולמידת מכונה" } }
];

export const products = [
    {
        "name": "ActivePieces",
        "nameHe": "אקטיבפיסס",
        "description": "No-code automation platform for streamlining business workflows. Build custom integrations and automate repetitive tasks without coding knowledge.",
        "descriptionHe": "פלטפורמת אוטומציה ללא קוד להתייעלות תהליכים עסקיים. בנה אינטגרציות מותאמות אישית ואוטומט משימות חוזרות ללא ידע בתכנות.",
        "category": "Development Tools",
        "provider": "Managed Instance",
        "features": ["Drag-and-drop interface", "Pre-built templates", "Custom workflows", "API integrations"],
        "tags": ["automation", "no-code", "workflow", "integration"]
    },
    {
        "name": "Authentik",
        "nameHe": "אותנטיק",
        "description": "An  identity solution that makes signing in simple and secure for any application.",
        "descriptionHe": "פתרון זהות  המקל על תהליך הכניסה ומספק אבטחה לכל יישום.",
        "category": "Security",
        "provider": "Managed Instance"
    },
    {
        "name": "Beszel",
        "nameHe": "בסזל",
        "description": "Easy-to-use server monitoring tool that shows history, container stats, and sends alerts when needed.",
        "descriptionHe": "כלי ניטור שרתים פשוט עם נתונים היסטוריים, סטטיסטיקות למכולות והתראות לפי צורך.",
        "category": "Analytics & Monitoring",
        "provider": "Managed Instance"
    },
    {
        "name": "Browserless",
        "nameHe": "בראוזרלס",
        "description": "Run Google Chrome without a user interface—perfect for automated tasks and testing.",
        "descriptionHe": "הפעלת כרום ללא ממשק משתמש—מעולה למשימות אוטומטיות ובדיקות.",
        "category": "Development Tools",
        "provider": "Managed Instance"
    },
    {
        "name": "BudgE",
        "nameHe": "באדג'",
        "description": "A simple app to track your spending and manage your personal budget with ease.",
        "descriptionHe": "אפליקציה קלה לניהול הוצאות ולמעקב אחרי התקציב האישי שלך.",
        "category": "Productivity",
        "provider": "Managed Instance"
    },
    {
        "name": "Code Server",
        "nameHe": "קוד סרבר",
        "description": "Access Visual Studio Code from any device through a web browser—no installation needed.",
        "descriptionHe": "גש ל-VS Code מכל מכשיר באמצעות הדפדפן—ללא צורך בהתקנה מקומית.",
        "category": "Development Tools",
        "provider": "Managed Instance"
    },
    {
        "name": "Dashboard",
        "nameHe": "לוח בקרה",
        "description": "A clean and user-friendly dashboard to keep track of your server at a glance.",
        "descriptionHe": "לוח בקרה נקי וקל לשימוש לניטור שרתך במבט מהיר.",
        "category": "Analytics & Monitoring",
        "provider": "Managed Instance"
    },
    {
        "name": "Dozzle",
        "nameHe": "דוזל",
        "description": "Real-time log viewer for your Docker containers, so you can see what's happening instantly.",
        "descriptionHe": "מציג לוגים בזמן אמת למכולות Docker, כך שתוכל לראות מה קורה מיד.",
        "category": "Analytics & Monitoring",
        "provider": "Managed Instance"
    },
    {
        "name": "Emby Stat",
        "nameHe": "אמבי סטאט",
        "description": "An easy-to-use dashboard that shows statistics about your Emby media server.",
        "descriptionHe": "לוח מחוונים פשוט המציג נתונים סטטיסטיים על שרת המדיה Emby שלך.",
        "category": "Analytics & Monitoring",
        "provider": "Managed Instance"
    },
    {
        "name": "Fider",
        "nameHe": "פיידר",
        "description": "Collect and organize customer feedback in one place—fast and simple.",
        "descriptionHe": "אסוף וארגן משוב לקוחות במקום אחד—מהיר ופשוט.",
        "category": "Communication",
        "provider": "Managed Instance"
    },
    {
        "name": "Formbricks",
        "nameHe": "פורמבריקס",
        "description": "Build and manage online forms for static websites easily, no extra setup needed.",
        "descriptionHe": "בנה ונהל טפסים לאתרים סטטיים בקלות, ללא הגדרות מורכבות.",
        "category": "Development Tools",
        "provider": "Managed Instance"
    },
    {
        "name": "Ghost",
        "nameHe": "גוסט",
        "description": "A powerful and modern platform for creating online publications and blogs.",
        "descriptionHe": "פלטפורמה חזקה ומודרנית ליצירת פרסומים ובלוגים מקוונים.",
        "category": "Content Management",
        "provider": "Managed Instance"
    },
    {
        "name": "Gitea",
        "nameHe": "גיטיה",
        "description": "A self-hosted Git service that's easy to set up and use, perfect for teams and individuals.",
        "descriptionHe": "שירות Git עצמאי שמאוד קל להתקין ולהשתמש בו, אידיאלי לצוותים ויחידים.",
        "category": "Development Tools",
        "provider": "Managed Instance"
    },
    {
        "name": "GitLab",
        "nameHe": "גיטלאב",
        "description": "All-in-one platform to develop, secure, and operate software together with your team.",
        "descriptionHe": "פלטפורמה הכל-באחד לפיתוח, אבטחה ותפעול תוכנה בשיתוף הצוות שלך.",
        "category": "Development Tools",
        "provider": "Managed Instance"
    },
    {
        "name": "Glance",
        "nameHe": "גלאנס",
        "description": "One-stop dashboard for managing your home server, services, and stats in one place.",
        "descriptionHe": "לוח בקרה הכל-במקום-אחד לניהול שרת ביתי, שירותים ונתונים סטטיסטיים.",
        "category": "Analytics & Monitoring",
        "provider": "Managed Instance"
    },
    {
        "name": "Gotenberg",
        "nameHe": "גוטנברג",
        "description": "Easily convert files to PDF with a simple, no-frills approach—no manual setup needed.",
        "descriptionHe": "המר קבצים ל-PDF בקלות וללא מורכבות—ללא צורך בהגדרות מיוחדות.",
        "category": "Development Tools",
        "provider": "Managed Instance"
    },
    {
        "name": "Heimdall",
        "nameHe": "היימדל",
        "description": "A stylish way to organize and quickly access your favorite web tools and apps.",
        "descriptionHe": "דרך אלגנטית לארגון וגישה מהירה לכלי הרשת והאפליקציות המועדפים שלך.",
        "category": "Productivity",
        "provider": "Managed Instance"
    },
    {
        "name": "Homepage",
        "nameHe": "דף הבית",
        "description": "Create a sleek, personalized homepage to manage links, widgets, and more for your server.",
        "descriptionHe": "צור דף בית מעוצב ומותאם אישית לניהול קישורים, ווידג'טים ועוד לשרת שלך.",
        "category": "Content Management",
        "provider": "Managed Instance"
    },
    {
        "name": "Infisical",
        "nameHe": "אינפיסיקל",
        "description": "Keep your confidential information safe with this  secret management platform.",
        "descriptionHe": "הגן על המידע הרגיש שלך באמצעות פלטפורמת ניהול סודות .",
        "category": "Security",
        "provider": "Managed Instance"
    },
    {
        "name": "Langfuse",
        "nameHe": "לאנגפיוז",
        "description": " platform that helps you build and refine AI language models more efficiently.",
        "descriptionHe": "פלטפורמה  המסייעת בבנייה ושיפור של מודלי שפה מבוססי בינה מלאכותית ביעילות.",
        "category": "AI & Machine Learning",
        "provider": "Managed Instance"
    },
    {
        "name": "Litellm",
        "nameHe": "לייטLLM",
        "description": " service for managing authentication, load balancing, and cost tracking across many AI models.",
        "descriptionHe": "שירות  לניהול אימות, איזון עומסים ומעקב הוצאות במגוון רחב של מודלי בינה מלאכותית.",
        "category": "AI & Machine Learning",
        "provider": "Managed Instance"
    },
    {
        "name": "Mailpit",
        "nameHe": "מיילפיט",
        "description": "A self-hosted tool to test and view emails before sending them out for real.",
        "descriptionHe": "כלי מאוחסן עצמאית לבדיקת הודעות דואר אלקטרוני לפני השליחה בפועל.",
        "category": "Development Tools",
        "provider": "Managed Instance"
    },
    {
        "name": "MeiliSearch",
        "nameHe": "מיליסרץ'",
        "description": "An easy-to-use, fast, and  search engine for any website or app.",
        "descriptionHe": "מנוע חיפוש מהיר, קל לשימוש ו המתאים לכל אתר או אפליקציה.",
        "category": "Development Tools",
        "provider": "Managed Instance"
    },
    {
        "name": "Nitropage",
        "nameHe": "ניטרופייג'",
        "description": "Drag-and-drop website builder to create modern sites quickly—no technical skills needed.",
        "descriptionHe": "בונה אתרים המבוסס על גרירה ושחרור, המאפשר הקמת אתרים מודרניים בקלות וללא ידע טכני.",
        "category": "Development Tools",
        "provider": "Managed Instance"
    },
    {
        "name": "Pairdrop",
        "nameHe": "פיירדרופ",
        "description": "A secure app for sending files between devices without relying on the internet.",
        "descriptionHe": "אפליקציה מאובטחת לשיתוף קבצים בין מכשירים ללא תלות באינטרנט.",
        "category": "Productivity",
        "provider": "Managed Instance"
    },
    {
        "name": "Plunk",
        "nameHe": "פלאנק",
        "description": "Stream and manage your music library on your own server—no monthly fees.",
        "descriptionHe": "נהל והזרם את ספריית המוזיקה שלך בשרת פרטי—ללא תשלומים חודשיים.",
        "category": "Content Management",
        "provider": "Managed Instance"
    },
    {
        "name": "Pocketbase",
        "nameHe": "פוקטבייס",
        "description": "All-in-one,  backend to power your apps with authentication and database features.",
        "descriptionHe": "פתרון צד שרת מקיף ו לאפליקציות, הכולל אימות ומשתמשים ומסד נתונים.",
        "category": "Development Tools",
        "provider": "Managed Instance"
    },
    {
        "name": "PostHog",
        "nameHe": "פוסטהוג",
        "description": "Understand user behavior and product usage with  analytics—no coding required.",
        "descriptionHe": "בצע ניתוח התנהגות משתמשים ושימוש במוצרים —בלי צורך בידע תכנותי.",
        "category": "Analytics & Monitoring",
        "provider": "Managed Instance"
    },
    {
        "name": "Reactive Resume",
        "nameHe": "קורות חיים ריאקטיביים",
        "description": "A free,  tool to create professional resumes quickly and easily.",
        "descriptionHe": "כלי חינמי ו ליצירת קורות חיים מקצועיים במהירות ובפשטות.",
        "category": "Productivity",
        "provider": "Managed Instance"
    },
    {
        "name": "Slash",
        "nameHe": "סלאש",
        "description": "Modern note-taking app with markdown support for organized, distraction-free writing.",
        "descriptionHe": "אפליקציית רישום הערות מודרנית עם תמיכה במרקדאון לכתיבה מאורגנת ללא הסחות דעת.",
        "category": "Productivity",
        "provider": "Managed Instance"
    },
    {
        "name": "Snapdrop",
        "nameHe": "סנאפדרופ",
        "description": "Quickly share files locally, inspired by Apple's AirDrop, without complicated setup.",
        "descriptionHe": "שיתוף קבצים מהיר ברשת המקומית, בהשראת AirDrop של אפל, ללא הגדרות מורכבות.",
        "category": "Productivity",
        "provider": "Managed Instance"
    },
    {
        "name": "Stirling PDF",
        "nameHe": "סטירלינג PDF",
        "description": " toolkit to handle PDFs easily—merge, split, or convert with just a few clicks.",
        "descriptionHe": "ערכת כלים  לטיפול בקבצי PDF—מיזוג, פיצול או המרה בכמה לחיצות בלבד.",
        "category": "Productivity",
        "provider": "Managed Instance"
    },
    {
        "name": "Trigger",
        "nameHe": "טריגר",
        "description": "Automate tasks without paying for subscription-based tools. A self-hosted Zapier alternative.",
        "descriptionHe": "אוטומציה של תהליכים ללא עלויות מנוי—אלטרנטיבה עצמאית ל-Zapier.",
        "category": "Development Tools",
        "provider": "Managed Instance"
    },
    {
        "name": "Twenty",
        "nameHe": "טוונטי",
        "description": "Personal dashboard to keep track of important links, notes, and tasks, all in one place.",
        "descriptionHe": "לוח בקרה אישי לאיסוף קישורים חשובים, הערות ומשימות במקום אחד.",
        "category": "Productivity",
        "provider": "Managed Instance"
    },
    {
        "name": "Umami",
        "nameHe": "אומאמי",
        "description": "A simple, privacy-friendly analytics service to see who's visiting your website.",
        "descriptionHe": "שירות ניתוח אתרים פשוט השומר על פרטיות, למעקב אחר מבקרי האתר שלך.",
        "category": "Analytics & Monitoring",
        "provider": "Managed Instance"
    },
    {
        "name": "Uptime Kuma",
        "nameHe": "אפטיים קומה",
        "description": "Monitor your websites and services with ease, and get alerts if something goes down.",
        "descriptionHe": "ניטור אתרים ושירותים בקלות, כולל התראות אם משהו מפסיק לפעול.",
        "category": "Analytics & Monitoring",
        "provider": "Managed Instance"
    },
    {
        "name": "Vaultwarden",
        "nameHe": "וולטוורדן",
        "description": "Securely store and manage passwords on your own server—Bitwarden-compatible and easy to set up.",
        "descriptionHe": "אחסן ונהל סיסמאות בשרת שלך באופן מאובטח—תואם Bitwarden והתקנה פשוטה.",
        "category": "Security",
        "provider": "Managed Instance"
    },
    {
        "name": "Vikunja",
        "nameHe": "ויקונג'ה",
        "description": "A to-do list manager to keep your tasks organized and your life on track.",
        "descriptionHe": "מנהל משימות לארגון המטלות ולשמירה על סדר היום שלך.",
        "category": "Productivity",
        "provider": "Managed Instance"
    },
    {
        "name": "Appsmith",
        "nameHe": "אפסמית'",
        "description": "A low-code platform to quickly create internal dashboards and apps without heavy coding.",
        "descriptionHe": "פלטפורמה בקוד נמוך לבנייה מהירה של לוחות בקרה ואפליקציות פנימיות ללא קוד מסובך.",
        "category": "Development Tools",
        "provider": "Managed Instance"
    },
    {
        "name": "Argilla",
        "nameHe": "ארגילה",
        "description": "Build, train, and test AI chat models easily— and perfect for conversational bots.",
        "descriptionHe": "פלטפורמה  לבניית, אימון ובדיקת מודלי בינה מלאכותית שיחתיים.",
        "category": "AI & Machine Learning",
        "provider": "Managed Instance"
    },
    {
        "name": "Change Detection",
        "nameHe": "זיהוי שינויים",
        "description": "Automatically watch for changes on any website and get alerts, so you never miss an update.",
        "descriptionHe": "נטר אוטומטית שינויים באתרים וקבל התראות כדי לא לפספס אף עדכון.",
        "category": "Analytics & Monitoring",
        "provider": "Managed Instance"
    },
    {
        "name": "Cloudflared",
        "nameHe": "קלאודפלרד",
        "description": "Securely connect to your server through Cloudflare—keep your services private and safe.",
        "descriptionHe": "התחבר לשרת שלך דרך Cloudflare באופן מאובטח—שמירה על פרטיות השירותים שלך.",
        "category": "Security",
        "provider": "Managed Instance"
    },
    {
        "name": "Docker Registry",
        "nameHe": "רשם דוקר",
        "description": "Store and manage Docker images in your own secure space—no external hosting needed.",
        "descriptionHe": "אחסן ונהל תמונות Docker במקום מאובטח משלך—ללא צורך באחסון חיצוני.",
        "category": "Database & Storage",
        "provider": "Managed Instance"
    },
    {
        "name": "Filebrowser",
        "nameHe": "דפדפן קבצים",
        "description": "A web-based file manager that lets you upload, download, and organize files on your server easily.",
        "descriptionHe": "מנהל קבצים מבוסס דפדפן המאפשר העלאה, הורדה וארגון קבצים בשרת שלך בנוחות.",
        "category": "Productivity",
        "provider": "Managed Instance"
    },
    {
        "name": "Kuzzle",
        "nameHe": "קוזל",
        "description": "Build modern web and mobile apps faster with a powerful backend that handles real-time data.",
        "descriptionHe": "בנה אפליקציות מודרניות לאינטרנט ולמובייל במהירות בעזרת צד שרת חזק לעיבוד נתונים בזמן אמת.",
        "category": "Development Tools",
        "provider": "Managed Instance"
    },
    {
        "name": "Label Studio",
        "nameHe": "סטודיו תוויות",
        "description": "A user-friendly platform for labeling data to train your machine learning models better.",
        "descriptionHe": "פלטפורמה נוחה לתיוג נתונים כדי לשפר את מודלי הבינה המלאכותית שלך.",
        "category": "AI & Machine Learning",
        "provider": "Managed Instance"
    },
    {
        "name": "Logto",
        "nameHe": "לוגטו",
        "description": "A straightforward alternative to Auth0, providing secure logins for modern web apps.",
        "descriptionHe": "אלטרנטיבה פשוטה ל-Auth0, המספקת חיבור מאובטח לאפליקציות רשת מודרניות.",
        "category": "Security",
        "provider": "Managed Instance"
    },
    {
        "name": "Metabase",
        "nameHe": "מטאבייס",
        "description": "Quickly turn your company data into charts and dashboards everyone can understand.",
        "descriptionHe": "הפוך את נתוני החברה לתרשימים ולוחות מחוונים בצורה מהירה וקלה להבנה.",
        "category": "Analytics & Monitoring",
        "provider": "Managed Instance"
    },
    {
        "name": "Openblocks",
        "nameHe": "אופנבלוקס",
        "description": "Low-code,  solution to build internal business tools effortlessly.",
        "descriptionHe": "פתרון בקוד נמוך ו לבניית כלים עסקיים פנימיים בקלות.",
        "category": "Development Tools",
        "provider": "Managed Instance"
    },
    {
        "name": "Plane",
        "nameHe": "פליין",
        "description": "Plan and track tasks and projects in one place—simple, , and easy to use.",
        "descriptionHe": "תכנן ועקוב אחר משימות ופרויקטים במקום אחד—פשוט, , וקל לשימוש.",
        "category": "Productivity",
        "provider": "Managed Instance"
    },
    {
        "name": "Prefect",
        "nameHe": "פריפקט",
        "description": "Orchestrate and manage data workflows seamlessly, from small tasks to big pipelines.",
        "descriptionHe": "תזמור וניהול תהליכי נתונים בקלות, ממשימות קטנות ועד צינורות מורכבים.",
        "category": "Development Tools",
        "provider": "Managed Instance"
    },
    {
        "name": "Rocket.Chat",
        "nameHe": "רוקט.צ'אט",
        "description": "A team chat platform that keeps your conversations secure and completely under your control.",
        "descriptionHe": "פלטפורמת צ'אט צוותי השומרת על שיחות מאובטחות ועל שליטתך המלאה בנתונים.",
        "category": "Communication",
        "provider": "Managed Instance"
    },
    {
        "name": "Supabase",
        "nameHe": "סופאבייס",
        "description": "An  alternative to Firebase that offers database, authentication, and more.",
        "descriptionHe": "אלטרנטיבה  ל-Firebase המציעה מסד נתונים, אימות ועוד.",
        "category": "Database & Storage",
        "provider": "Managed Instance"
    },
    {
        "name": "Tolgee",
        "nameHe": "טולג'י",
        "description": "Manage translations for your apps effortlessly with this  localization platform.",
        "descriptionHe": "ניהול תרגומים לאפליקציות שלך בקלות עם פלטפורמת לוקליזציה .",
        "category": "Development Tools",
        "provider": "Managed Instance"
    },
    {
        "name": "Unleash",
        "nameHe": "אנליש",
        "description": "Roll out new features to the right users at the right time with  feature management.",
        "descriptionHe": "השק תכונות חדשות למשתמשים הנכונים בזמן הנכון בעזרת ניהול תכונות .",
        "category": "Development Tools",
        "provider": "Managed Instance"
    },
    {
        "name": "Appwrite",
        "nameHe": "אפרייט",
        "description": "Simplify web and mobile app creation with this full-featured backend-as-a-service platform.",
        "descriptionHe": "פשט את תהליך פיתוח האפליקציות לאינטרנט ולמובייל בעזרת פלטפורמת צד שרת עשירה בתכונות.",
        "category": "Development Tools",
        "provider": "Managed Instance"
    },
    {
        "name": "Baby Buddy",
        "nameHe": "בייבי באדי",
        "description": "Track your baby's activities, growth, and health details all in one place.",
        "descriptionHe": "עקוב אחר פעילויות התינוק, גדילתו ונתוניו הבריאותיים במקום אחד.",
        "category": "Productivity",
        "provider": "Managed Instance"
    },
    {
        "name": "Outline",
        "nameHe": "אאוטליין",
        "description": "A team-friendly space to create, share, and manage knowledge and documentation.",
        "descriptionHe": "סביבה נוחה לצוותים ליצירה, שיתוף וניהול של ידע ותיעוד.",
        "category": "Productivity",
        "provider": "Managed Instance"
    },
    {
        "name": "ClassicPress",
        "nameHe": "קלאסיקפרס",
        "description": "A business-focused CMS that's stable, user-friendly, and powered by a passionate community.",
        "descriptionHe": "מערכת ניהול תוכן הממוקדת בעסקים, יציבה וידידותית, עם קהילה מחויבת.",
        "category": "Content Management",
        "provider": "Managed Instance"
    },
    {
        "name": "DokuWiki",
        "nameHe": "דוקוויקי",
        "description": "A straightforward wiki platform to create and organize documents easily—no database required.",
        "descriptionHe": "פלטפורמת ויקי פשוטה ליצירה וארגון של מסמכים ללא צורך במסד נתונים.",
        "category": "Content Management",
        "provider": "Managed Instance"
    },
    {
        "name": "Jellyfin",
        "nameHe": "ג'ליפין",
        "description": "Manage and stream your movies, TV shows, and music at home for free—no subscriptions.",
        "descriptionHe": "נהל והזרם סרטים, סדרות ומוזיקה בחינם לגמרי, ללא דמי מנוי.",
        "category": "Content Management",
        "provider": "Managed Instance"
    },
    {
        "name": "MediaWiki",
        "nameHe": "מדיהויקי",
        "description": "The  wiki software behind Wikipedia—create a collaborative knowledge base for your team.",
        "descriptionHe": "תוכנת הוויקי  המפעילה את ויקיפדיה—צור בסיס ידע שיתופי לצוות שלך.",
        "category": "Content Management",
        "provider": "Managed Instance"
    },
    {
        "name": "phpMyAdmin",
        "nameHe": "פי-אייץ'-פי-מיי-אדמין",
        "description": "A convenient web tool for managing MySQL databases—perfect for anyone, not just developers.",
        "descriptionHe": "כלי אינטרנטי נוח לניהול מסדי נתונים MySQL—מתאים לכל אחד, לא רק למפתחים.",
        "category": "Database & Storage",
        "provider": "Managed Instance"
    },
    {
        "name": "WordPress",
        "nameHe": "וורדפרס",
        "description": "Create websites or blogs with endless customization. Perfect for beginners and pros alike.",
        "descriptionHe": "בנה אתרים או בלוגים עם אפשרויות התאמה בלתי מוגבלות. מעולה למתחילים ולמקצוענים.",
        "category": "Content Management",
        "provider": "Managed Instance"
    },
    {
        "name": "Directus",
        "nameHe": "דירקטוס",
        "description": "Easily manage content with this  headless CMS and API tailored to your data.",
        "descriptionHe": "נהל תוכן בקלות באמצעות CMS ללא ראש  ו-API המותאמים לנתונים שלך.",
        "category": "Content Management",
        "provider": "Managed Instance"
    },
    {
        "name": "Firefly III",
        "nameHe": "פיירפליי III",
        "description": "Take full control of your personal finances—track expenses, budgets, and savings all in one place.",
        "descriptionHe": "נהל באופן מלא את כספיך—עקוב אחר הוצאות, תקציבים וחיסכון במקום אחד.",
        "category": "Productivity",
        "provider": "Managed Instance"
    },
    {
        "name": "Kimai",
        "nameHe": "קימאי",
        "description": "Keep track of time spent on tasks and projects—ideal for freelancers and teams of any size.",
        "descriptionHe": "נהל את זמנך המושקע במשימות ופרויקטים—מתאים לפרילנסרים ולצוותים בכל גודל.",
        "category": "Productivity",
        "provider": "Managed Instance"
    },
    {
        "name": "Metube",
        "nameHe": "מטיוב",
        "description": "Host and share your own videos without depending on external services or ads.",
        "descriptionHe": "אחסן ושתף סרטונים בעצמך ללא תלות בשירותים חיצוניים או פרסומות.",
        "category": "Content Management",
        "provider": "Managed Instance"
    },
    {
        "name": "Moodle",
        "nameHe": "מודל",
        "description": "Create engaging online courses and learning experiences with this  education platform.",
        "descriptionHe": "צור קורסים וחוויות למידה מקוונות בצורה מעודדת מעורבות, בפלטפורמה חינמית .",
        "category": "Content Management",
        "provider": "Managed Instance"
    },
    {
        "name": "Weaviate",
        "nameHe": "וויביאט",
        "description": "An  database designed for AI and semantic search, making data queries more intuitive.",
        "descriptionHe": "מסד נתונים  המיועד לבינה מלאכותית וחיפוש סמנטי, המאפשר שאילתות נתונים אינטואיטיביות יותר.",
        "category": "AI & Machine Learning",
        "provider": "Managed Instance"
    }
];

// Add SEO helper functions
export const getMetaForCategory = (categorySlug, language = 'en') => {
  const category = categories.find(c => c.slug === categorySlug);
  return category ? category.metaDescription[language] : '';
};

export const getProductsByCategory = (categorySlug) => {
  return products.filter(product => {
    const category = categories.find(c => c.slug === categorySlug);
    return category ? product.category === category.en : false;
  });
};

// Add search optimization helpers
export const getSearchableText = (product, language = 'en') => {
  return language === 'en'
    ? `${product.name} ${product.description} ${product.category} ${product.provider} ${product.tags.join(' ')}`
    : `${product.nameHe} ${product.descriptionHe} ${product.category} ${product.provider} ${product.tags.join(' ')}`;
}; 