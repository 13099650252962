import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import './GlobalDeploy.css';

const GlobalDeploy = () => {
  const [showModal, setShowModal] = useState(false);
  const [language, setLanguage] = useState('en');

  const plans = {
    en: [
      {
        name: "Starter",
        specs: {
          cpu: "1 Core",
          ram: "2GB RAM",
          storage: "100GB SSD",
          bandwidth: "20Mbps",
          traffic: "Unlimited"
        },
        price: "$68"
      },
      {
        name: "Growth",
        specs: {
          cpu: "2 Cores",
          ram: "4GB RAM",
          storage: "100GB SSD",
          bandwidth: "20Mbps",
          traffic: "Unlimited"
        },
        price: "$139"
      },
      {
        name: "Advanced",
        specs: {
          cpu: "4 Cores",
          ram: "8GB RAM",
          storage: "200GB SSD",
          bandwidth: "50Mbps",
          traffic: "Unlimited"
        },
        price: "$219"
      }
    ],
    he: [
      {
        name: "סטנדרטי",
        specs: {
          cpu: "ליבה 1",
          ram: "זיכרון RAM: 2GB",
          storage: "אחסון: 100GB SSD",
          bandwidth: "רוחב פס: 20Mbps",
          traffic: "תעבורה: ללא הגבלה"
        },
        price: "219 ₪"
      },
      {
        name: "עסקי",
        specs: {
          cpu: "2 ליבות",
          ram: "זיכרון RAM: 4GB",
          storage: "אחסון: 100GB SSD",
          bandwidth: "רוחב פס: 20Mbps",
          traffic: "תעבורה: ללא הגבלה"
        },
        price: "439 ₪"
      },
      {
        name: "מתקדם",
        specs: {
          cpu: "4 ליבות",
          ram: "זיכרון RAM: 8GB",
          storage: "אחסון: 200GB SSD",
          bandwidth: "רוחב פס: 50Mbps",
          traffic: "תעבורה: ללא הגבלה"
        },
        price: "699 ₪"
      }
    ]
  };

  const features = {
    en: [
      "Full Management",
      "Remote Cloud Backup (7 days)",
      "Updates & Security Patches",
      "Server Monitoring",  
      "Infrastructure Support",
      "Technical Consulting",
      "SSL Certificate",
      "Cyber Security System",
      "High-Speed SSD Infrastructure"
    ],
    he: [
      "ניהול מלא",
      "גיבוי לענן מרוחק (7 ימים)",
      "עדכונים ועדכוני אבטחה",
      "ניטור שרת",
      "תמיכה בתשתית",
      "ייעוץ טכני",
      "תעודת SSL",
      "מערכת הגנת סייבר",
    ]
  };

  useEffect(() => {
    let scriptElement = null;
    
    const loadPayPalScript = () => {
      if (scriptElement) {
        document.body.removeChild(scriptElement);
      }

      scriptElement = document.createElement('script');
      scriptElement.src = `https://www.paypal.com/sdk/js?client-id=AdWIx81WbV4y0eGSaVvcJ46FVo9-eSxz1Ao2rOy0glrPDdU_343yTk4xYg_NxffTknRiAbaYbNByFNcf&vault=true&intent=subscription&locale=${language === 'en' ? 'en_US' : 'he_IL'}`;
      scriptElement.setAttribute('data-sdk-integration-source', 'button-factory');
      scriptElement.async = true;

      scriptElement.onload = () => {
        console.log('PayPal script loaded successfully');
        initializePayPalButtons();
      };

      document.body.appendChild(scriptElement);
    };

    if (showModal) {
      loadPayPalScript();
    }

    return () => {
      if (scriptElement && document.body.contains(scriptElement)) {
        document.body.removeChild(scriptElement);
      }
    };
  }, [showModal, language]);

  const initializePayPalButtons = () => {
    if (!window.paypal) return;

    try {
      const buttonConfigs = language === 'en' ? [
        {
          containerId: '#paypal-button-container-P-8YC13099PR982634MM6RGEZY',
          planId: 'P-8YC13099PR982634MM6RGEZY'
        },
        {
          containerId: '#paypal-button-container-P-32508119SA155922SM6RGJSQ',
          planId: 'P-32508119SA155922SM6RGJSQ'
        },
        {
          containerId: '#paypal-button-container-P-4UD04094D3902033KM6RGLBQ',
          planId: 'P-4UD04094D3902033KM6RGLBQ'
        }
      ] : [
        {
          containerId: '#paypal-button-container-P-40696904NP245520GM6RGYYY',
          planId: 'P-40696904NP245520GM6RGYYY'
        },
        {
          containerId: '#paypal-button-container-P-9Y5799824B107742KM6RG5LY',
          planId: 'P-9Y5799824B107742KM6RG5LY'
        },
        {
          containerId: '#paypal-button-container-P-30208148FW311183BM6RG6PQ',
          planId: 'P-30208148FW311183BM6RG6PQ'
        }
      ];

      buttonConfigs.forEach(config => {
        const container = document.querySelector(config.containerId);
        if (container) {
          window.paypal.Buttons({
            style: {
              shape: 'rect',
              color: 'blue',
              layout: 'vertical',
              label: 'subscribe'
            },
            createSubscription: function(data, actions) {
              return actions.subscription.create({
                plan_id: config.planId
              });
            },
            onApprove: function(data, actions) {
              alert(data.subscriptionID);
            }
          }).render(config.containerId);
        }
      });
    } catch (error) {
      console.error('Error initializing PayPal buttons:', error);
    }
  };

  return (
    <>
      <button 
        className="global-deploy-button"
        onClick={() => setShowModal(true)}
      >
        <i className="fas fa-cloud-upload-alt"></i>
        {language === 'en' ? 'Deploy Instance' : 'הטמעת שרת'}
      </button>

      <Modal 
        show={showModal} 
        onHide={() => setShowModal(false)}
        size="xl"
        dir={language === 'he' ? 'rtl' : 'ltr'}
        className="deploy-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {language === 'en' 
              ? 'Choose Deployment Plan' 
              : 'בחר תכנית הטמעה'}
          </Modal.Title>
          <div className="language-switch">
            <Button 
              variant={language === 'en' ? 'primary' : 'outline-primary'}
              onClick={() => setLanguage('en')}
              size="sm"
            >
              EN
            </Button>
            <Button 
              variant={language === 'he' ? 'primary' : 'outline-primary'}
              onClick={() => setLanguage('he')}
              size="sm"
            >
              עב
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="pricing-plans-container">
            <div className="deployment-info">
              <div className="info-box">
                <i className="fas fa-clock"></i>
                <p>
                  {language === 'en' 
                    ? 'Average deployment time: ~2 hours'
                    : 'זמן התקנה ממוצע: כשעתיים'}
                </p>
              </div>
              <div className="info-box highlight">
                <i className="fas fa-cogs"></i>
                <p>
                  {language === 'en'
                    ? 'Fully Managed Instance'
                    : 'תשתית ענן מנוהלת'}
                </p>
              </div>
              <div className="info-box">
                <i className="fas fa-envelope"></i>
                <p>
                  {language === 'en'
                    ? 'After ordering, please contact: '
                    : 'לאחר ההזמנה, יש ליצור קשר: '}
                  <a href="mailto:support@epicfinal.com">support@epicfinal.com</a>
                </p>
              </div>
            </div>

            <Row className="pricing-row">
              {plans[language].map((plan, index) => (
                <Col md={4} key={index}>
                  <div className={`pricing-card ${index === 1 ? 'popular' : ''}`}>
                    {index === 1 && (
                      <div className="popular-badge">
                        {language === 'en' ? 'Most Popular' : 'הכי פופולרי'}
                      </div>
                    )}
                    <div className="pricing-card-header">
                      <h3 className="plan-name">{plan.name}</h3>
                      <div className="price-container">
                        <span className="price">{plan.price}</span>
                        <span className="period">/{language === 'en' ? 'month' : 'חודש'}</span>
                      </div>
                    </div>
                    
                    <div className="pricing-card-body">
                      <ul className="specs-list">
                        {Object.entries(plan.specs).map(([key, value]) => (
                          <li key={key}>
                            <i className={`fas fa-${key === 'cpu' ? 'microchip' : 
                              key === 'ram' ? 'memory' : 
                              key === 'storage' ? 'hdd' : 
                              key === 'bandwidth' ? 'network-wired' : 
                              'exchange-alt'}`}></i>
                            <span>{value}</span>
                          </li>
                        ))}
                      </ul>
                      <div id={`paypal-button-container-${language === 'en' ? 
                        ['P-8YC13099PR982634MM6RGEZY', 'P-32508119SA155922SM6RGJSQ', 'P-4UD04094D3902033KM6RGLBQ'][index] : 
                        ['P-40696904NP245520GM6RGYYY', 'P-9Y5799824B107742KM6RG5LY', 'P-30208148FW311183BM6RG6PQ'][index]}`} />
                    </div>
                  </div>
                </Col>
              ))}
            </Row>

            <div className="features-section">
              <div className="features-header">
                <h2 className="features-title">
                  {language === 'en' ? 'All Plans Include' : 'כל התכניות כוללות'}
                </h2>
                <div className="features-subtitle">
                  {language === 'en' 
                    ? 'Everything you need to grow your business' 
                    : 'כל מה שאתה צריך כדי להצמיח את העסק שלך'}
                </div>
              </div>
              <div className="features-grid">
                {features[language].map((feature, index) => (
                  <div key={index} className="feature-card">
                    <div className="feature-icon-wrapper">
                      <i className={`fas ${[
                        "fa-cogs",
                        "fa-camera",
                        "fa-cloud",
                        "fa-shield-alt",
                        "fa-chart-line",
                        "fa-tools",
                        "fa-comments",
                        "fa-lock",
                        "fa-user-shield",
                        "fa-bolt"
                      ][index]}`}></i>
                    </div>
                    <div className="feature-content">
                      <h3 className="feature-name">{feature}</h3>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GlobalDeploy; 