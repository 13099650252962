import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './HerosectionL4.css';

function HerosectionL4() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <motion.section 
      className="hero-section"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      style={{
        background: 'rgba(255, 255, 255, 0.95)',
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
      }}
    >
      <div className="hero-background">
        <div className="gradient-sphere gradient-sphere-1"></div>
        <div className="gradient-sphere gradient-sphere-2"></div>
        <div className="gradient-sphere gradient-sphere-3"></div>
      </div>

      <div className="container position-relative">
        <div className="row align-items-center">
          <motion.div 
            className="col-lg-6"
            variants={itemVariants}
          >
            <div className="hero-content">
              <motion.div 
                className="badge-container"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.2 }}
              >
                <span className="hero-badge">
                  <i className="las la-rocket me-2"></i>
                  Innovation Hub
                </span>
              </motion.div>

              <motion.h1 
                className="hero-title"
                variants={itemVariants}
              >
                <span className="gradient-text" style={{
                  background: "linear-gradient(135deg, #2563eb 0%, #1e40af 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  display: "inline-block"
                }}>Custom Software</span>
                <span className="highlight-text">That Drives Growth</span>
              </motion.h1>

              <motion.p 
                className="hero-description"
                variants={itemVariants}
              >
                end-to-end Software Slutions. From startup MVPs to enterprise-grade systems, Your vision, our expertise – let's scale together.
              </motion.p>

              <motion.div 
                className="hero-buttons"
                variants={itemVariants}
              >
                <motion.a 
                  href="https://boarding.epicfinal.com"
                  className="btn btn-primary btn-glow"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Start Your Growth Journey
                  <i className="las la-rocket ms-2"></i>
                </motion.a>
                <motion.a 
                  href="/MARKETPLACE"
                  className="btn btn-primary btn-glow"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Cloud Marketplace
                  <i className="las la-cloud ms-2"></i>
                </motion.a>
                <motion.a 
                  href="https://meetings.hubspot.com/viacheslav-seifer"
                  className="btn btn-primary btn-glow"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Book a Meeting
                  <i className="las la-envelope ms-2"></i>
                </motion.a>
              </motion.div>

              <motion.div 
                className="hero-stats"
                variants={itemVariants}
              >
                <div className="stat-item">
                  <motion.span 
                    className="stat-number"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.5 }}
                    style={{ background: 'linear-gradient(45deg, #4285F4, #34A853)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}
                  >
                    300%
                  </motion.span>
                  <span className="stat-label">Productivity Boost</span>
                </div>
                <div className="stat-item">
                  <motion.span 
                    className="stat-number"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.6 }}
                    style={{ background: 'linear-gradient(45deg, #FBBC04, #EA4335)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}
                  >
                    360°
                  </motion.span>    
                  <span className="stat-label">Expert Support</span>
                </div>
                <div className="stat-item">
                  <motion.span 
                    className="stat-number"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.7 }}
                  >
                  </motion.span>
                  {/* <span className="stat-label">Expertise</span> */}
                </div>
              </motion.div>
            </div>
          </motion.div>

          <motion.div 
            className="col-lg-6"
            variants={itemVariants}
          >
            <div className="hero-image-wrapper">
              <motion.div 
                className="hero-video"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                style={{
                  position: 'relative',
                  paddingBottom: '56.25%', /* 16:9 Aspect Ratio */
                  height: 0,
                  overflow: 'hidden',
                  maxWidth: '100%',
                  borderRadius: '12px',
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                }}
              >
                <iframe
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    border: 'none'
                  }}
                  src="https://www.youtube.com/embed/AsEa4XD4ZSA?si=y3wapsIHOB874gmc"
                  title="SaaS Demo Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>

              <motion.div 
                className="tech-bubble tech-bubble-1"
                animate={{
                  y: [0, -20, 0],
                  rotate: [0, 10, 0]
                }}
                transition={{
                  duration: 4,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                <i className="lab la-docker"></i>
              </motion.div>

              <motion.div 
                className="tech-bubble tech-bubble-3"
                animate={{
                  y: [-10, 10, -10],
                  x: [-10, 10, -10]
                }}
                transition={{
                  duration: 6,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                <i className="lab la-aws"></i>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>

      <div className="scroll-indicator">
        <motion.div
          animate={{
            y: [0, 10, 0],
          }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        >
          <i className="las la-mouse"></i>
          <span>Scroll to explore</span>
        </motion.div>
      </div>
    </motion.section>
  );
}

export default HerosectionL4;
