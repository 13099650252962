import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import './WooCommerce.css';
import patternSvg from './pattern.svg';

const WooCommerce = () => {
  const [showPricingModal, setShowPricingModal] = useState(false);

  // Add PayPal script load handler
  useEffect(() => {
    let scriptElement = null;
    
    const loadPayPalScript = () => {
      // Remove existing script if any
      if (scriptElement) {
        document.body.removeChild(scriptElement);
      }

      // Create new script element
      scriptElement = document.createElement('script');
      scriptElement.src = `https://www.paypal.com/sdk/js?client-id=AdWIx81WbV4y0eGSaVvcJ46FVo9-eSxz1Ao2rOy0glrPDdU_343yTk4xYg_NxffTknRiAbaYbNByFNcf&vault=true&intent=subscription&locale=he_IL`;
      scriptElement.setAttribute('data-sdk-integration-source', 'button-factory');
      scriptElement.async = true;

      // Add load and error handlers
      scriptElement.onload = () => {
        console.log('PayPal script loaded successfully');
      };

      scriptElement.onerror = (error) => {
        console.error('Error loading PayPal script:', error);
      };

      // Append script to body
      document.body.appendChild(scriptElement);
    };

    loadPayPalScript();

    // Cleanup function
    return () => {
      if (scriptElement && document.body.contains(scriptElement)) {
        document.body.removeChild(scriptElement);
      }
    };
  }, []);

  // Add PayPal button renderer with error handling
  const renderPayPalButton = (planIndex) => {
    if (!window.paypal) {
      console.log('PayPal script not loaded yet');
      return null;
    }

    try {
      // Hebrew PayPal buttons
      if (planIndex === 0) {
        return (
          <div id="paypal-button-container-P-40696904NP245520GM6RGYYY"></div>
        );
      } else if (planIndex === 1) {
        return (
          <div id="paypal-button-container-P-9Y5799824B107742KM6RG5LY"></div>
        );
      } else if (planIndex === 2) {
        return (
          <div id="paypal-button-container-P-30208148FW311183BM6RG6PQ"></div>
        );
      }
    } catch (error) {
      console.error('Error rendering PayPal button:', error);
      return null;
    }
    return null;
  };

  useEffect(() => {
    const initializePayPalButtons = () => {
      if (!window.paypal) {
        console.log('PayPal not loaded yet, waiting...');
        return;
      }

      try {
        // Hebrew PayPal buttons configuration
        const hebrewButtonConfigs = [
          {
            containerId: '#paypal-button-container-P-40696904NP245520GM6RGYYY',
            planId: 'P-40696904NP245520GM6RGYYY'
          },
          {
            containerId: '#paypal-button-container-P-9Y5799824B107742KM6RG5LY',
            planId: 'P-9Y5799824B107742KM6RG5LY'
          },
          {
            containerId: '#paypal-button-container-P-30208148FW311183BM6RG6PQ',
            planId: 'P-30208148FW311183BM6RG6PQ'
          }
        ];

        hebrewButtonConfigs.forEach(config => {
          const container = document.querySelector(config.containerId);
          if (container) {
            window.paypal.Buttons({
              style: {
                shape: 'rect',
                color: 'blue',
                layout: 'vertical',
                label: 'subscribe'
              },
              createSubscription: function(data, actions) {
                return actions.subscription.create({
                  plan_id: config.planId
                });
              },
              onApprove: function(data, actions) {
                alert(data.subscriptionID);
              },
              onError: function(err) {
                console.error('PayPal button error:', err);
              }
            }).render(config.containerId);
          }
        });
      } catch (error) {
        console.error('Error initializing PayPal buttons:', error);
      }
    };

    // Initialize buttons when modal is shown
    if (showPricingModal) {
      // Add a small delay to ensure DOM elements are ready
      const timer = setTimeout(initializePayPalButtons, 500);
      return () => clearTimeout(timer);
    }
  }, [showPricingModal]);

  useEffect(() => {
    // Initialize AOS animation library
    if (typeof window !== 'undefined') {
      const AOS = require('aos');
      AOS.init({
        duration: 1000,
        once: true,
      });
    }
  }, []);

  const plans = {
    name: "סטנדרטי",
    specs: {
      cpu: "ליבה 1",
      ram: "זיכרון RAM: 2GB",
      storage: "אחסון: 100GB SSD",
      bandwidth: "רוחב פס: 20Mbps",
      traffic: "תעבורה: ללא הגבלה"
    },
    price: "219 ₪"
  };

  const features = [
    "ניהול מלא",
    "גיבוי Snapshot",
    "גיבוי לענן מרוחק (7 ימים)",
    "עדכונים ועדכוני אבטחה",
    "ניטור שרת",
    "תמיכה בתשתית",
    "ייעוץ טכני",
    "תעודת SSL",
    "מערכת הגנת סייבר",
  ];

  const handleStartNow = (e) => {
    e.preventDefault();
    setShowPricingModal(true);
  };

  const fadeInUp = {
    initial: { y: 60, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    transition: { duration: 0.6 }
  };

  return (
    <div className="woocommerce-container">
      <Helmet>
        <html lang="he" dir="rtl" />
        <title>אחסון WooCommerce מנוהל | חנות וירטואלית מקצועית | EpicFinal</title>
        <meta name="description" content="פתרון אחסון WooCommerce מנוהל מקצועי עם ביצועים מעולים, אבטחה מתקדמת ותמיכה 24/7. הקם חנות וירטואלית מקצועית עם כל הכלים להצלחה עסקית." />
        <meta name="keywords" content="WooCommerce, חנות וירטואלית, אחסון מנוהל, אחסון בענן, חנות אינטרנטית, ecommerce, אתר מכירות, סליקת אשראי, חנות אונליין" />
        <meta property="og:title" content="אחסון WooCommerce מנוהל | חנות וירטואלית מקצועית | EpicFinal" />
        <meta property="og:description" content="הקם חנות וירטואלית מקצועית עם פתרון WooCommerce המנוהל שלנו. אחסון מהיר, אבטחה מתקדמת ותמיכה מקצועית 24/7." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://epicfinal.com/woocommerce" />
        <meta property="og:site_name" content="EpicFinal" />
        <meta property="og:locale" content="he_IL" />
        <meta property="og:image" content="https://epicfinal.com/Woo_logo_white.svg" />
        <meta property="og:image:alt" content="WooCommerce Logo" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="אחסון WooCommerce מנוהל | חנות וירטואלית מקצועית" />
        <meta name="twitter:description" content="הקם חנות וירטואלית מקצועית עם פתרון WooCommerce המנוהל שלנו. אחסון מהיר, אבטחה מתקדמת ותמיכה מקצועית 24/7." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://epicfinal.com/woocommerce" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "אחסון WooCommerce מנוהל",
            "description": "פתרון אחסון WooCommerce מנוהל מקצועי עם ביצועים מעולים, אבטחה מתקדמת ותמיכה 24/7",
            "brand": {
              "@type": "Brand",
              "name": "EpicFinal"
            },
            "offers": {
              "@type": "AggregateOffer",
              "priceCurrency": "ILS",
              "lowPrice": "219",
              "highPrice": "699",
              "offerCount": "3",
              "priceValidUntil": new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0]
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.8",
              "reviewCount": "127"
            }
          })}
        </script>
        <link href="https://unpkg.com/aos@2.3.1/dist/aos.css" rel="stylesheet" />
      </Helmet>

      <div className="woocommerce-content" dir="rtl">
        <motion.div 
          className="woocommerce-hero"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div className="hero-overlay" style={{ background: `url(${patternSvg}) repeat` }}></div>
          <div className="container">
            <div className="hero-content">
              <motion.div 
                className="woocommerce-logo"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <img src="/Woo_logo_white.svg" alt="WooCommerce Logo" />
              </motion.div>
              <motion.h1 
                className="main-title"
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.6 }}
              >
                חנות וירטואלית WooCommerce בענן מנוהל
                <span className="gradient-text">הפתרון המושלם לעסק המקוון שלכם</span>
              </motion.h1>
              <motion.p 
                className="meta-description"
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.6 }}
              >
                הכירו את פתרון WooCommerce בענן המנוהל שלנו: אחסון מהיר, חנות וירטואלית מאובטחת, תמיכה מקצועית , התאמה מלאה ל-SEO ואופטימיזציה לביצועים כדי שתוכלו למקסם את המכירות שלכם.
              </motion.p>
              <motion.div 
                className="hero-buttons"
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.6 }}
              >
                <a href="#" className="btn-primary" onClick={handleStartNow}>
                  <i className="fas fa-rocket"></i>
                  התחל עכשיו
                </a>
              </motion.div>
            </div>
          </div>
        </motion.div>

        <section id="features" className="features-section">
          <div className="container">
            <div className="section-header" data-aos="fade-up">
              <span className="section-subtitle">למה לבחור בנו?</span>
              <h2 className="section-title">למה לבחור בחנות וירטואלית WooCommerce בענן מנוהל?</h2>
            </div>
            <div className="features-grid">
              <motion.div 
                className="feature-card"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="feature-icon">
                  <i className="fas fa-tasks"></i>
                </div>
                <h3>ניהול מלא וחיסכון בזמן</h3>
                <ul>
                  <li><i className="fas fa-check-circle"></i>אין צורך לבזבז זמן על תחזוקת שרתים</li>
                  <li><i className="fas fa-check-circle"></i>ריכוז כל הניהול במקום אחד</li>
                  <li><i className="fas fa-check-circle"></i>התמקדות בשיווק ומכירות</li>
                </ul>
              </motion.div>

              <motion.div 
                className="feature-card"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="feature-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                <h3>ביצועים גבוהים וחוויית קנייה משופרת</h3>
                <ul>
                  <li><i className="fas fa-check-circle"></i>תשתית אחסון בענן אופטימלית</li>
                  <li><i className="fas fa-check-circle"></i>זמני טעינה מהירים</li>
                  <li><i className="fas fa-check-circle"></i>שיפור שיעור ההמרה</li>
                </ul>
              </motion.div>

              <motion.div 
                className="feature-card"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="feature-icon">
                  <i className="fas fa-shield-alt"></i>
                </div>
                <h3>אבטחה מתקדמת ורב-שכבתית</h3>
                <ul>
                  <li><i className="fas fa-check-circle"></i>הגנה מקצועית מפני התקפות סייבר</li>
                  <li><i className="fas fa-check-circle"></i>גיבויים אוטומטיים יומיים</li>
                  <li><i className="fas fa-check-circle"></i>תעודת SSL מובנית</li>
                </ul>
              </motion.div>
            </div>
          </div>
        </section>

        <section className="seo-benefits">
          <div className="container">
            <div className="section-header" data-aos="fade-up">
              <span className="section-subtitle">קידום אתרים</span>
              <h2 className="section-title">התאמה ל-SEO ושיווק דיגיטלי</h2>
            </div>
            <div className="benefits-grid" data-aos="fade-up" data-aos-delay="100">
              <div className="benefit-card">
                <i className="fas fa-server"></i>
                <h3>תשתית מתקדמת</h3>
                <p>שרתים מותאמים לפרוטוקולי HTTP/2 ו-HTTPS</p>
              </div>
              <div className="benefit-card">
                <i className="fas fa-search"></i>
                <h3>אופטימיזציה מובנית</h3>
                <p>תמיכה בתוספי SEO מובילים</p>
              </div>
              <div className="benefit-card">
                <i className="fas fa-link"></i>
                <h3>מבנה URL ידידותי</h3>
                <p>תגיות מטא ושליטה מלאה בהגדרות</p>
              </div>
            </div>
          </div>
        </section>

        <section className="support-section">
          <div className="container">
            <div className="section-header" data-aos="fade-up">
              <span className="section-subtitle">תמיכה מקצועית</span>
              <h2 className="section-title">תמיכה טכנית </h2>
            </div>
            <div className="support-grid" data-aos="fade-up" data-aos-delay="100">
              <div className="support-image">
                <svg width="400" height="300" viewBox="0 0 400 300" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <linearGradient id="supportGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                      <stop offset="0%" stopColor="#96588a" />
                      <stop offset="100%" stopColor="#7c3977" />
                    </linearGradient>
                  </defs>
                  
                  {/* Background Circle */}
                  <circle cx="200" cy="150" r="120" fill="url(#supportGradient)" opacity="0.1"/>
                  
                  {/* Support Team Icons */}
                  <g transform="translate(130,90)">
                    <circle cx="70" cy="70" r="50" fill="url(#supportGradient)"/>
                    <path d="M70 40 A30 30 0 0 1 100 70 A30 30 0 0 1 70 100 A30 30 0 0 1 40 70 A30 30 0 0 1 70 40" 
                          fill="white" opacity="0.9"/>
                    <circle cx="70" cy="60" r="15" fill="white"/>
                  </g>
                  
                  {/* Support Chat Bubbles */}
                  <g transform="translate(220,120)">
                    <rect x="0" y="0" width="80" height="40" rx="20" fill="white"/>
                    <rect x="-60" y="50" width="60" height="30" rx="15" fill="white"/>
                  </g>
                </svg>
              </div>
              <div className="support-content">
                <div className="support-feature">
                  <i className="fas fa-users"></i>
                  <div>
                    <h3>צוות מקצועי</h3>
                    <p>בעל ניסיון רב ב-תשתיות מחשוב ואפליקציות</p>
                  </div>
                </div>
                <div className="support-feature">
                  <i className="fas fa-tools"></i>
                  <div>
                    <h3>סיוע טכני</h3>
                    <p>בהטמעת תוספי סליקה וניהול מלאי</p>
                  </div>
                </div>
                <div className="support-feature">
                  <i className="fas fa-chart-line"></i>
                  <div>
                    <h3>ליווי עסקי</h3>
                    <p>בצמיחה העסקית שלכם</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="scalability-section">
          <div className="container">
            <div className="section-header" data-aos="fade-up">
              <span className="section-subtitle">צמיחה עסקית</span>
              <h2 className="section-title">סקיילביליות וצמיחה ללא גבולות</h2>
            </div>
            <div className="scalability-grid" data-aos="fade-up" data-aos-delay="100">
              <div className="scalability-card">
                <i className="fas fa-server"></i>
                <h3>הרחבת משאבים</h3>
                <p>אפשרות להרחבת משאבי השרת בקלות</p>
              </div>
              <div className="scalability-card">
                <i className="fas fa-tags"></i>
                <h3>ניהול מלא</h3>
                <p>ניהול מלא של התשתית והפלאגינים</p>
              </div>
              <div className="scalability-card">
                <i className="fas fa-cloud"></i>
                <h3>אפשרות לתשתית שרידה </h3>
                <p> זמינות של 99.99% בתשתית שרידה</p>
              </div>
            </div>
          </div>
        </section>

        <section className="cta-section">
          <div className="container">
            <div className="cta-content" data-aos="fade-up">
              <h2>מעוניינים לקחת את החנות הווירטואלית שלכם לשלב הבא?</h2>
              <p style={{color: '#ffffff'}}>השאירו פרטים או התקשרו אלינו לקבלת שיחת ייעוץ חינם</p>
              <div className="cta-buttons">
                <Link to="/contact1" className="btn-primary">
                  <i className="fas fa-paper-plane"></i>
                  צור קשר
                </Link>            
              </div>
            </div>
          </div>
        </section>

        <section className="summary-section">
          <div className="container">
            <div className="summary-content" data-aos="fade-up">
              <div className="summary-icon">
                <i className="fas fa-rocket"></i>
              </div>
              <h2>תנו לנו לדאוג לטכנולוגיה – ואתם תתמקדו בהגדלת המכירות!</h2>
              <p>בחרו בפתרון WooCommerce בענן המנוהל שלנו והתחילו למכור באינטרנט בצורה חכמה ויעילה.</p>
              <Link to="/contact1" className="btn-primary" onClick={handleStartNow}>
                <i className="fas fa-arrow-right"></i>
                התחל עכשיו
              </Link>
            </div>
          </div>
        </section>

        {/* Pricing Modal */}
        <Modal 
          show={showPricingModal} 
          onHide={() => setShowPricingModal(false)}
          size="xl"
          className="pricing-modal"
        >
          <div dir="rtl">
            <Modal.Header closeButton>
              <Modal.Title>בחר תכנית אחסון</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="pricing-plans-container">
                <div className="deployment-info">
                  <div className="info-box">
                    <i className="fas fa-clock"></i>
                    <p>זמן הטמעה ממוצע: כשעתיים</p>
                  </div>
                  <div className="info-box highlight">
                    <i className="fas fa-cogs"></i>
                    <p>תשתית ענן מנוהלת</p>
                  </div>
                  <div className="info-box">
                    <i className="fas fa-envelope"></i>
                    <p>
                      לאחר ההזמנה, יש ליצור קשר:
                      <a href="mailto:support@epicfinal.com">support@epicfinal.com</a>
                    </p>
                  </div>
                </div>
                
                <Row className="pricing-row">
                  {[
                    {
                      name: "סטנדרטי",
                      englishName: "Starter",
                      price: "219 ₪",
                      specs: {
                        cpu: "ליבה 1",
                        ram: "זיכרון RAM: 2GB",
                        storage: "אחסון: 100GB SSD",
                        bandwidth: "רוחב פס: 20Mbps",
                        traffic: "תעבורה: ללא הגבלה"
                      }
                    },
                    {
                      name: "עסקי",
                      englishName: "Growth",
                      price: "439 ₪",
                      specs: {
                        cpu: "2 ליבות",
                        ram: "זיכרון RAM: 4GB",
                        storage: "אחסון: 100GB SSD",
                        bandwidth: "רוחב פס: 20Mbps",
                        traffic: "תעבורה: ללא הגבלה"
                      }
                    },
                    {
                      name: "מתקדם",
                      englishName: "Advanced",
                      price: "699 ₪",
                      specs: {
                        cpu: "4 ליבות",
                        ram: "זיכרון RAM: 8GB",
                        storage: "אחסון: 200GB SSD",
                        bandwidth: "רוחב פס: 50Mbps",
                        traffic: "תעבורה: ללא הגבלה"
                      }
                    }
                  ].map((plan, index) => (
                    <Col md={4} key={index}>
                      <div className={`pricing-card ${index === 1 ? 'popular' : ''}`}>
                        {index === 1 && (
                          <div className="popular-badge">הכי פופולרי</div>
                        )}
                        <div className="pricing-card-header">
                          <h3 className="plan-name">
                            {plan.name} <span className="english-name">({plan.englishName})</span>
                          </h3>
                          <div className="price-container">
                            <span className="price">{plan.price}</span>
                            <span className="period">/חודש</span>
                          </div>
                        </div>
                        
                        <div className="pricing-card-body">
                          <ul className="specs-list">
                            <li>
                              <i className="fas fa-microchip"></i>
                              <span>{plan.specs.cpu}</span>
                            </li>
                            <li>
                              <i className="fas fa-memory"></i>
                              <span>{plan.specs.ram}</span>
                            </li>
                            <li>
                              <i className="fas fa-hdd"></i>
                              <span>{plan.specs.storage}</span>
                            </li>
                            <li>
                              <i className="fas fa-network-wired"></i>
                              <span>{plan.specs.bandwidth}</span>
                            </li>
                            <li>
                              <i className="fas fa-exchange-alt"></i>
                              <span>{plan.specs.traffic}</span>
                            </li>
                          </ul>
                          <div className="terms-disclaimer">
                            <small>
                              בביצוע הרכישה, הנך מסכים/ה ל
                                <a href="/terms_conditions" target="_blank" rel="noopener noreferrer">
                                  תנאי השימוש וההתקשרות
                                </a>
                              </small>
                          </div>
                          {index === 0 || index === 1 || index === 2 ? (
                            renderPayPalButton(index)
                          ) : (
                            <Link to="/contact1" className="btn btn-primary select-plan-btn">
                              בחר תכנית
                            </Link>
                          )}
                        </div>
                      </div>
                    </Col>
                  ))}
                  
                  {/* Enterprise Plan */}
                  <Col md={4}>
                    <div className="pricing-card custom-plan">
                      <div className="pricing-card-header">
                        <h3 className="plan-name">ארגוני</h3>
                        <div className="price-container custom">
                          <span className="custom-text">פתרון מותאם אישית</span>
                        </div>
                      </div>
                      <div className="pricing-card-body">
                        <div className="custom-description">
                          <i className="fas fa-building"></i>
                          <p>פתרונות מותאמים אישית לארגונים גדולים עם דרישות ספציפיות</p>
                        </div>
                        <div className="enterprise-features">
                          <div className="enterprise-feature">
                            <i className="fas fa-expand-arrows-alt"></i>
                            <div className="enterprise-feature-text">
                              <h5>גמישות</h5>
                              <p>התאמה של משאבים לפי דרישה</p>
                            </div>
                          </div>
                          <div className="enterprise-feature">
                            <i className="fas fa-shield-virus"></i>
                            <div className="enterprise-feature-text">
                              <h5>זמינות גבוהה</h5>
                              <p>הבטחת זמינות מקסימלית (99.9% זמינות) עם גיבוי אוטומטי ואיזון עומסים במספר אזורים</p>
                            </div>
                          </div>
                        </div>
                        <Link to="/contact1" className="btn btn-outline-primary contact-btn">
                          <i className="fas fa-envelope"></i>
                          צור קשר
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>

                <div className="features-section">
                  <div className="features-header">
                    <h4 className="features-title">כל התכניות כוללות</h4>
                    <p className="features-subtitle">כל מה שצריך כדי להפעיל את האפליקציה שלך בהצלחה</p>
                  </div>
                  <div className="features-container">
                    {features.map((feature, index) => {
                      const featureIcons = [
                        "fa-cogs",
                        "fa-camera",
                        "fa-cloud",
                        "fa-shield-alt",
                        "fa-chart-line",
                        "fa-tools",
                        "fa-comments",
                        "fa-lock",
                        "fa-user-shield"
                      ];

                      return (
                        <div key={index} className="feature-box">
                          <div className="feature-icon">
                            <i className={`fas ${featureIcons[index]}`}></i>
                          </div>
                          <div className="feature-text">{feature}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default WooCommerce; 