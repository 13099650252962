import React, { useState } from 'react';
import axios from 'axios';
import './Calculator.css'

const Claculator = () => {
  const [answers, setAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [result, setResult] = useState(null);
  const [appType, setAppType] = useState(null);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleAnswer = (value, checked) => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [value]: { value, selected: checked }
    }));

  };

  const handleNext = () => {
    let totalQuestions = 0;
    if (appType === 'SaaS') {
      totalQuestions = saasQuestions.length;
    } else if (appType === 'LandingPage') {
      totalQuestions = landingPageQuestions.length;
    } else if (appType === 'DevOps') {
      totalQuestions = devOpsQuestions.length;
    }

    if (currentQuestionIndex < totalQuestions - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      calculateResult();
    }
    if (window.innerWidth < 768) {
      // Scroll the page to the top
      window.scrollTo({ top: 900, behavior: 'smooth' });
    }
  };

  const handleAppTypeSelection = (type) => {
    setAppType(type);
    setCurrentQuestionIndex(0);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleNext();
    }
  };

  const calculateResult = () => {
    let totalDays = 0;
    const selectedOptions = [];

    let questionsArray;
    if (appType === 'SaaS') {
      questionsArray = saasQuestions;
    } else if (appType === 'LandingPage') {
      questionsArray = landingPageQuestions;
    } else if (appType === 'DevOps') {
      questionsArray = devOpsQuestions;
    }

    Object.values(answers).forEach(({ value, selected }) => {
      if (selected) {
        totalDays += parseInt(value.split('_')[0]);
        const question = questionsArray.find(question => question.options.find(option => option.value === value));
        const selectedOption = question.options.find(option => option.value === value);
        selectedOptions.push({ question: question.text, option: selectedOption.label });
      }
    });

    setResult(totalDays);

    axios.post('https://zksw0sw4gcsk40gogck0cw8c.epicfinal-cloud.com/send-email', { result: totalDays, selectedOptions })
      .then(response => {
        console.log('Email sent successfully:', response.data);
      })
      .catch(error => {
        console.error('Error sending email:', error);
      });
  };

  const handleSubmit = () => {
    axios.post('https://zksw0sw4gcsk40gogck0cw8c.epicfinal-cloud.com/send-email-submit', {email, phoneNumber }) // Fixed: Added selectedOptions to the POST data
      .then(response => {
        console.log('Email sent successfully:', response.data);
        setSubmitted(true);
      })
      .catch(error => {
        console.error('Error sending email:', error);
      });
  }; 

  const saasQuestions = [
    {
      id: 0,
      text: 'Preferred platform?',
      options: [
        { label: 'Web App', value: '3_Web' },
        { label: 'Android', value: '5_Android' },
        { label: 'IOS', value: '5_iOS' },
        { label: 'Windows Desktop', value: '4_Windows Dekstop' }
      ]
    },
    {
      id: 1,
      text: 'How big is your app?',
      options: [
        { label: '(Small) POC / 3-4 Features', value: '10_small' },
        { label: '(Medium) MVP / 5-9 Features', value: '30_medium' },
        { label: '(Large) FullRelease / 10-14 Features', value: '50_large' }
      ]
    },
    {
      id: 2,
      text: 'What is the Complexity of UI?',
      options: [
        { label: 'Minimal', value: '10_mvp' },
        { label: 'Regular', value: '30_basic' },
        { label: 'Advanced', value: '70_polished' }
      ]
    },
    {
      id: 3,
      text: 'Accounts',
      options: [
        { label: 'Email / Password Sign Up', value: '1_email_password' },
        { label: 'Facebook Sign Up', value: '2_facebook' },
        { label: 'Twitter Sign Up', value: '2_twitter' },
        { label: 'Google Sign Up', value: '2_google' },
        { label: 'LinkedIn Sign Up', value: '2_linkedin' },
        { label: 'Github Sign Up', value: '2_github' },
        { label: 'User Invitation Emails', value: '2_invitation_emails' },
        { label: 'Multi-tenant Accounts', value: '3_multi_tenant' },
        { label: 'Subdomains', value: '4_subdomains' },
        { label: 'Custom Domains', value: '4_custom_domains' }
      ]
    },
    {
      id: 4,
      text: 'Content',
      options: [
        { label: 'Dashboard', value: '5_dashboard' },
        { label: 'Activity Feed', value: '4_activity_feed' },
        { label: 'File Uploading', value: '4_file_uploading' },
        { label: 'User Profiles', value: '2_user_profiles' },
        { label: 'Transactional Emails', value: '2_transactional_emails' },
        { label: 'Tags', value: '2_tags' },
        { label: 'Ratings or reviews', value: '5_ratings_reviews' },
        { label: 'Audio/Video processing', value: '5_audio_video' },
        { label: 'Free text searching', value: '4_text_searching' }
      ]
    },
    {
      id: 5,
      text: 'Calendar & Locations',
      options: [
        { label: 'Calendaring', value: '7_calendaring' },
        { label: 'Display of Map data / Geolocation', value: '3_map_data' },
        { label: 'Display of custom map markers/regions', value: '3_custom_map' },
        { label: 'Bookings', value: '8_bookings' }
      ]
    },
    {
      id: 6,
      text: 'Engagement',
      options: [
        { label: 'Messaging', value: '6_messaging' },
        { label: 'Forums or commenting', value: '5_forums_commenting' },
        { label: 'Social Sharing', value: '2_social_sharing' },
        { label: 'Push to Facebook Open Graph', value: '5_facebook_open_graph' }
      ]
    },
    {
      id: 7,
      text: 'Billing & eCommerce',
      options: [
        { label: 'Subscription plans', value: '8_subscription_plans' },
        { label: 'Payment processing', value: '5_payment_processing' },
        { label: 'Shopping Cart', value: '8_shopping_cart' },
        { label: 'User Marketplace', value: '12_user_marketplace' },
        { label: 'Product Management', value: '4_product_management' }
      ]
    },
    {
      id: 8,
      text: 'Analytics, Feedback and Admin ',
      options: [
        { label: 'CMS Integration', value: '7_cms_integration' },
        { label: 'User Admin pages', value: '4_user_admin_pages' },
        { label: 'Moderation / Content Approval', value: '4_moderation_content_approval' },
        { label: 'Intercom', value: '3_intercom' },
        { label: 'Usage Analytics', value: '3_usage_analytics' },
        { label: 'Crash Reporting', value: '1_crash_reporting' },
        { label: 'Performance Monitoring', value: '1_performance_monitoring' },
        { label: 'Multilingual Support', value: '4_multilingual_support' }
      ]
    },
    {
      id: 9,
      text: 'Third party Integrations & APIs',
      options: [
        { label: 'Connect to one or more third party services', value: '6_third_party_services' },
        { label: 'An API for others to integrate with your app', value: '8_api_integration' },
        { label: 'SMS Messaging', value: '4_sms_messaging' },
        { label: 'Phone Number Masking', value: '4_phone_number_masking' }
      ]
    },
    {
      id: 10,
      text: 'Security',
      options: [
        { label: 'Two Factor Authentication', value: '5_two_factor_auth' }
      ]
    }
  ];

  const landingPageQuestions = [
    {
      id: 0,
      text: 'What is the primary goal of your landing page?',
      options: [
        { label: 'Lead Generation', value: '0_Lead_Generation' },
        { label: 'Product Promotion', value: '0_Product_Promotion' },
        { label: 'Event Registration', value: '0_Event_Registration' },
        { label: 'Brand Awareness', value: '0_Brand_Awareness' },
        { label: 'Other', value: '0_Other_Goal' }
      ]
    },
    {
      id: 1,
      text: 'Preferred Design Style?',
      options: [
        { label: 'Minimalist', value: '0_Minimalist' },
        { label: 'Modern', value: '1_Modern' },
        { label: 'Illustrative', value: '1_Illustrative' },
        { label: 'Corporate', value: '2_Corporate' },
        { label: 'Other', value: '0_Other_Style' }
      ]
    },
    {
      id: 2,
      text: 'Target Audience?',
      options: [
        { label: 'Consumer (B2C)', value: '0_Consumer_B2C' },
        { label: 'Business (B2B)', value: '0_Business_B2B' },
        { label: 'Both', value: '0_Both_Audiences' }
      ]
    },
    {
      id: 3,
      text: 'Integration with Social Media?',
      options: [
        { label: 'Yes', value: '0_Social_Media_Yes' },
        { label: 'No', value: '0_Social_Media_No' }
      ]
    },
    {
      id: 4,
      text: 'Contact Form?',
      options: [
        { label: 'Yes', value: '1_Contact_Form_Yes' },
        { label: 'No', value: '0_Contact_Form_No' }
      ]
    },
    {
      id: 5,
      text: 'Preferred Call to Action (CTA)?',
      options: [
        { label: 'Contact Us', value: '0_Contact_Us' },
        { label: 'Subscribe', value: '0_Subscribe' },
        { label: 'Download', value: '1_Download' },
        { label: 'Learn More', value: '0_Learn_More' },
        { label: 'Other', value: '0_Other_CTA' }
      ]
    },
    {
      id: 6,
      text: 'Responsive Design?',
      options: [
        { label: 'Yes', value: '1_Responsive_Yes' },
        { label: 'No', value: '0_Responsive_No' }
      ]
    },
    {
      id: 7,
      text: 'Preferred Color Scheme?',
      options: [
        { label: 'Blue', value: '0_Color_Blue1' },
        { label: 'Green', value: '0_Color_Green2' },
        { label: 'Red', value: '0_Color_Red3' },
        { label: 'Maple', value: '0_Color_Yellow4' },
        { label: 'Orange', value: '0_Color_Yellow5' },
        { label: 'Sky-Blue', value: '0_Color_Yellow6' },
        { label: 'Other', value: '0_Other_Color9' }
      ]
    },
    {
      id: 8,
      text: 'Content Ready?',
      options: [
        { label: 'Yes', value: '0_Content_Yes' },
        { label: 'No', value: '1_Content_No' }
      ]
    },
    {
      id: 9,
      text: 'Images and Graphics?',
      options: [
        { label: 'Yes', value: '0_Images_Yes' },
        { label: 'No', value: '0_Images_No' }
      ]
    },
    {
      id: 10,
      text: 'Number of Pages?',
      options: [
        { label: '1', value: '1_Images_No1' },
        { label: '2', value: '1_Images_No2' },
        { label: '3', value: '1_Images_No3' },
        { label: '4', value: '2_Images_No4' },
        { label: '5', value: '2_Images_No5' },
        { label: '6', value: '2_Images_No6' },
        { label: 'more then 6', value: '3_Images_No7' },
      ]
    },
    {
      id: 10,
      text: 'do you need CMS??',
      options: [
        { label: 'Yes', value: '1_Images_No' },
        { label: 'No', value: '0_Images_No' },
     
      ]
    }
  ];

  const devOpsQuestions = [
    {
      id: 0,
      text: 'Cloud Provider?',
      options: [
        { label: 'AWS', value: '3_aws' },
        { label: 'Google Cloud Platform (GCP)', value: '3_gcp' },
        { label: 'Microsoft Azure', value: '5_azure' },
        { label: 'Self Hosted', value: '3_other' },
        { label: 'No Server Infrastructure', value: '3_other1' }
      ]
    },
    {
      id: 0,
      text: 'Implementing Private Cloud?',
      options: [
        { label: 'Yes', value: '50_yescloud' },
        { label: 'No', value: '0_nocloud' },
      ]
    },
    {
      id: 1,
      text: 'Need help with Containerization Technology?',
      options: [
        { label: 'Docker', value: '3_docker' },
        { label: 'Kubernetes', value: '15_kubernetes' },
        { label: 'No', value: '0_other' }
      ]
    },
    {
      id: 2,
      text: 'Setting up Continuous Integration/Continuous Deployment (CI/CD) infrastructure ?',
      options: [
        { label: 'Yes', value: '20_yes' },
        { label: 'No', value: '0_no' }
      ]
    },
    {
      id: 3,
      text: ' Implementing Monitoring and Logging Solutions?',
      options: [
        { label: 'Yes', value: '20_prometheus_grafana' },
        { label: 'No', value: '0_other' }
      ]
    },
    {
      id: 3,
      text: 'Disaster Recovery & Backups?',
      options: [
        { label: 'Yes', value: '15_prometheus_grafana' },
        { label: 'No', value: '0_other' }
      ]
    },
    {
      id: 3,
      text: 'Cyber Security Solutions?',
      options: [
        { label: 'Yes', value: '0_backup_grafana' },
        { label: 'No', value: '0_other' }
      ]
    },
    {
      id: 3,
      text: 'Automation?',
      options: [
        { label: 'Yes', value: '10_auth_grafana' },
        { label: 'No', value: '0_other' }
      ]
    },
    {
      id: 3,
      text: 'Infrastructure as Code?',
      options: [
        { label: 'Yes', value: '10_iac_grafana' },
        { label: 'No', value: '0_other' }
      ]
    },
    {
      id: 3,
      text: 'Integration?',
      options: [
        { label: 'Yes', value: '10_in_grafana' },
        { label: 'No', value: '0_other' }
      ]
    },
    {
      id: 3,
      text: 'Scalability or High Avaiability ?',
      options: [
        { label: 'Yes', value: '10_ha_grafana' },
        { label: 'No', value: '0_other' }
      ]
    },
    {
      id: 3,
      text: 'Incident Response?',
      options: [
        { label: 'Yes', value: '10_ir_grafana' },
        { label: 'No', value: '0_other' }
      ]
    },
    {
      id: 3,
      text: 'Training ?',
      options: [
        { label: 'Yes', value: '10_Training_grafana' },
        { label: 'No', value: '0_other' }
      ]
    },
    {
      id: 3,
      text: 'Managed Services ?',
      options: [
        { label: 'Yes', value: '10_Ma_grafana' },
        { label: 'No', value: '0_other' }
      ]
    },
    {
      id: 3,
      text: 'Research ?',
      options: [
        { label: 'Yes', value: '0_Re_grafana' },
        { label: 'No', value: '0_other' }
      ]
    },
    {
      id: 3,
      text: 'Consulting ?',
      options: [
        { label: '1 Hour ', value: '1_Re_grafana1' },
        { label: '2 Hours', value: '2_othe1r' },
        { label: '3 Hours', value: '3_other2' },
        { label: '4 Hours', value: '4_other3' },
        { label: '5 Hours', value: '5_other4' },
        { label: '6 Hours', value: '6_other5' },
        { label: '7+ Hours Consulting ', value: '0_other6' },
        
      ]
    },
  ];

  return (
    <section className='calculator'>
    <div className="container">
        <div className="row justify-content-center">
      <h1 className="header">Development Time Estimator</h1>
      {appType === null ? (
        <div>
          <h2 className="question">What type of project do you want to build?</h2>
          <div className="options-container">
            <button className="button" onClick={() => handleAppTypeSelection('SaaS')}>Custom SaaS App</button>
            <button className="button" onClick={() => handleAppTypeSelection('LandingPage')}>Landing Page / Blog</button>
            <button className="button" onClick={() => handleAppTypeSelection('DevOps')}>Operations, Security & DevOps </button>
          </div>
        </div>
      ) : (
        result === null ? (
          <div>
            <h2 className="question">{appType === 'SaaS' ? saasQuestions[currentQuestionIndex].text : (appType === 'LandingPage' ? landingPageQuestions[currentQuestionIndex].text : devOpsQuestions[currentQuestionIndex].text)}</h2>
            {appType === 'SaaS' ? (
              <div className="options-container">
                {saasQuestions[currentQuestionIndex].options.map(option => (
                  <label
                    key={option.value}
                    className={`option-label ${answers[option.value] && answers[option.value].selected ? 'selected' : ''}`}
                  >
                    <input
                      type="checkbox"
                      style={{margin:'5px'}}
                      checked={answers[option.value] ? answers[option.value].selected : false}
                      onChange={e => handleAnswer(option.value, e.target.checked)}
                      className="option-checkbox"
                    />
                    <span className="checkbox"></span>
                    {option.label}
                  </label>
                ))}
              </div>
            ) : (appType === 'LandingPage' ? (
              <div className="options-container">
                {landingPageQuestions[currentQuestionIndex].options.map(option => (
                  <label
                    key={option.value}
                    className={`option-label ${answers[option.value] && answers[option.value].selected ? 'selected' : ''}`}
                  >
                    <input
                      type="checkbox"
                      style={{margin:'5px'}}
                      checked={answers[option.value] ? answers[option.value].selected : false}
                      onChange={e => handleAnswer(option.value, e.target.checked)}
                      className="option-checkbox"
                    />
                    <span className="checkbox"></span>
                    {option.label}
                  </label>
                ))}
              </div>
            ) : (
              <div className="options-container">
                {devOpsQuestions[currentQuestionIndex].options.map(option => (
                  <label
                    key={option.value}
                    className={`option-label ${answers[option.value] && answers[option.value].selected ? 'selected' : ''}`}
                  >
                    <input
                      type="checkbox"
                      style={{margin:'5px'}}
                      checked={answers[option.value] ? answers[option.value].selected : false}
                      onChange={e => handleAnswer(option.value, e.target.checked)}
                      className="option-checkbox"
                    />
                    <span className="checkbox"></span>
                    {option.label}
                  </label>
                ))}
              </div>
            ))}
            <div className="button-group">
              <button 
                className="button"
                onClick={handleNext}
                onKeyPress={handleKeyPress}
              >
                Next
              </button>
            </div>
          </div>
        ) : (
          <div>
            <h2>Estimated Development Time: {result} days</h2>
            {!submitted && result !== null && (
  // Render contact form if not submitted, and result is not null
                    <div className="contact-form">
                      <h2>Receive your offer today by completing the calculation.</h2>
                      <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <input
                        type="tel"
                        placeholder="Enter your phone number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                      <button onClick={handleSubmit}>Complete</button>
                    </div>
                  )}
                  {submitted && (
                    // Render message based on whether the form has been submitted
                    <div>
                      <h2>{result === null ? "We will shortly reach you" : "Thanks!"}</h2>
                    </div>
                  )}
          </div>
          
        )
      )}
      </div>
    </div>
    </section>
  );
};

export default Claculator;
