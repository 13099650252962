import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './WordPress.css';

const WordPress = () => {
  const [language, setLanguage] = useState('he');

  // Add PayPal script load handler
  useEffect(() => {
    let scriptElement = null;
    
    const loadPayPalScript = () => {
      if (scriptElement) {
        document.body.removeChild(scriptElement);
      }

      scriptElement = document.createElement('script');
      scriptElement.src = `https://www.paypal.com/sdk/js?client-id=AdWIx81WbV4y0eGSaVvcJ46FVo9-eSxz1Ao2rOy0glrPDdU_343yTk4xYg_NxffTknRiAbaYbNByFNcf&vault=true&intent=subscription&locale=${language === 'en' ? 'en_US' : 'he_IL'}`;
      scriptElement.setAttribute('data-sdk-integration-source', 'button-factory');
      scriptElement.async = true;

      scriptElement.onload = () => {
        console.log('PayPal script loaded successfully');
        initializePayPalButtons();
      };

      scriptElement.onerror = (error) => {
        console.error('Error loading PayPal script:', error);
      };

      document.body.appendChild(scriptElement);
    };

    loadPayPalScript();

    return () => {
      if (scriptElement && document.body.contains(scriptElement)) {
        document.body.removeChild(scriptElement);
      }
    };
  }, [language]);

  // Add PayPal button initialization
  const initializePayPalButtons = () => {
    if (!window.paypal) {
      console.log('PayPal not loaded yet');
      return;
    }

    try {
      const buttonConfigs = [
        {
          containerId: 'paypal-button-container-P-40696904NP245520GM6RGYYY',
          planId: 'P-40696904NP245520GM6RGYYY'
        },
        {
          containerId: 'paypal-button-container-P-9Y5799824B107742KM6RG5LY',
          planId: 'P-9Y5799824B107742KM6RG5LY'
        },
        {
          containerId: 'paypal-button-container-P-30208148FW311183BM6RG6PQ',
          planId: 'P-30208148FW311183BM6RG6PQ'
        }
      ];

      buttonConfigs.forEach(config => {
        const container = document.querySelector(`#${config.containerId}`);
        if (container) {
          window.paypal.Buttons({
            style: {
              shape: 'rect',
              color: 'blue',
              layout: 'vertical',
              label: 'subscribe'
            },
            createSubscription: function(data, actions) {
              return actions.subscription.create({
                plan_id: config.planId
              });
            },
            onApprove: function(data, actions) {
              alert(data.subscriptionID);
            },
            onError: function(err) {
              console.error('PayPal button error:', err);
            }
          }).render(`#${config.containerId}`);
        }
      });
    } catch (error) {
      console.error('Error initializing PayPal buttons:', error);
    }
  };

  const plans = {
    en: [
      {
        name: "Starter",
        specs: {
          cpu: "1 Core",
          ram: "2GB RAM",
          storage: "100GB SSD",
          bandwidth: "20Mbps",
          traffic: "Unlimited"
        },
        price: "$68"
      },
      {
        name: "Growth",
        specs: {
          cpu: "2 Cores",
          ram: "4GB RAM",
          storage: "100GB SSD",
          bandwidth: "20Mbps",
          traffic: "Unlimited"
        },
        price: "$139"
      },
      {
        name: "Advanced",
        specs: {
          cpu: "4 Cores",
          ram: "8GB RAM",
          storage: "200GB SSD",
          bandwidth: "50Mbps",
          traffic: "Unlimited"
        },
        price: "$219"
      }
    ],
    he: [
      {
        name: "סטנדרטי",
        specs: {
          cpu: "ליבה 1",
          ram: "זיכרון RAM: 2GB",
          storage: "אחסון: 100GB SSD",
          bandwidth: "רוחב פס: 20Mbps",
          traffic: "תעבורה: ללא הגבלה"
        },
        price: "219 ₪"
      },
      {
        name: "עסקי",
        specs: {
          cpu: "2 ליבות",
          ram: "זיכרון RAM: 4GB",
          storage: "אחסון: 100GB SSD",
          bandwidth: "רוחב פס: 20Mbps",
          traffic: "תעבורה: ללא הגבלה"
        },
        price: "439 ₪"
      },
      {
        name: "מתקדם",
        specs: {
          cpu: "4 ליבות",
          ram: "זיכרון RAM: 8GB",
          storage: "אחסון: 200GB SSD",
          bandwidth: "רוחב פס: 50Mbps",
          traffic: "תעבורה: ללא הגבלה"
        },
        price: "699 ₪"
      }
    ]
  };

  const features = {
    he: [
      "ניהול מלא",
      "גיבוי Snapshot",
      "גיבוי לענן מרוחק (7 ימים)",
      "עדכונים ועדכוני אבטחה",
      "ניטור שרת",
      "תמיכה בתשתית",
      "ייעוץ טכני",
      "תעודת SSL",
      "מערכת הגנת סייבר",
    ]
  };

  return (
    <>
      <Helmet>
        <html lang="he" />
        <title>אחסון וורדפרס מנוהל בענן | EpicFinal - אחסון WordPress מקצועי</title>
        <meta name="description" content="שירותי אחסון WordPress מנוהל בענן עם ביצועים גבוהים, אבטחה מתקדמת ותמיכה מקצועית 24/7. מחירים תחרותיים, גיבויים אוטומטיים ומערכת ניהול מתקדמת." />
        <meta name="keywords" content="אחסון וורדפרס, אחסון WordPress, אחסון מנוהל, אחסון בענן, וורדפרס מנוהל, WordPress hosting" />
        <meta property="og:title" content="אחסון וורדפרס מנוהל בענן | EpicFinal" />
        <meta property="og:description" content="שירותי אחסון WordPress מנוהל בענן עם ביצועים גבוהים, אבטחה מתקדמת ותמיכה מקצועית 24/7." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://epicfinal.com/wordpress" />
        <link rel="canonical" href="https://epicfinal.com/wordpress" />
        <meta name="robots" content="index, follow" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Product",
              "name": "אחסון וורדפרס מנוהל בענן",
              "description": "שירותי אחסון WordPress מנוהל בענן עם ביצועים גבוהים, אבטחה מתקדמת ותמיכה מקצועית",
              "provider": {
                "@type": "Organization",
                "name": "EpicFinal",
                "url": "https://epicfinal.com"
              },
              "offers": {
                "@type": "AggregateOffer",
                "priceCurrency": "ILS",
                "lowPrice": "219",
                "highPrice": "699",
                "offerCount": "3"
              },
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "150"
              }
            }
          `}
        </script>
      </Helmet>
      <div className="wordpress-container">
        <div className="wordpress-hero">
          <div className="container">
            <div className="wordpress-logo">
              <svg viewBox="0 0 122.52 122.523" style={{ width: "80px", height: "80px", marginBottom: "30px" }}>
                <g>
                  <path fill="#ffffff" d="M8.708,61.26c0,20.802,12.089,38.779,29.619,47.298L13.258,39.872C10.342,46.408,8.708,53.641,8.708,61.26z
                    M96.74,58.608c0-6.495-2.333-10.993-4.334-14.494c-2.664-4.329-5.161-7.995-5.161-12.324c0-4.831,3.664-9.328,8.825-9.328
                    c0.233,0,0.454,0.029,0.681,0.042c-9.35-8.566-21.807-13.796-35.489-13.796c-18.36,0-34.513,9.42-43.91,23.688
                    c1.233,0.037,2.395,0.063,3.382,0.063c5.497,0,14.006-0.667,14.006-0.667c2.833-0.167,3.167,3.994,0.337,4.329
                    c0,0-2.847,0.335-6.015,0.501L48.2,93.547l11.501-34.493l-8.188-22.434c-2.83-0.166-5.511-0.501-5.511-0.501
                    c-2.832-0.166-2.5-4.496,0.332-4.329c0,0,8.679,0.667,13.843,0.667c5.496,0,14.006-0.667,14.006-0.667
                    c2.835-0.167,3.168,3.994,0.337,4.329c0,0-2.853,0.335-6.015,0.501l18.992,56.494l5.242-17.517
                    C95.011,68.328,96.74,63.107,96.74,58.608z M61.262,64.756l-15.742,45.75c4.708,1.384,9.687,2.141,14.846,2.141
                    c6.12,0,11.989-1.058,17.452-2.979c-0.141-0.225-0.269-0.464-0.374-0.724L61.262,64.756z M107.167,36.046
                    c0.226,1.674,0.354,3.471,0.354,5.404c0,5.333-0.996,11.328-3.996,18.824l-16.053,46.413
                    c15.624-9.111,26.133-26.038,26.133-45.426C113.604,52.124,111.23,43.532,107.167,36.046z M61.26,0
                    C27.419,0,0,27.42,0,61.26c0,33.841,27.419,61.263,61.26,61.263c33.841,0,61.264-27.422,61.264-61.263
                    C122.522,27.42,95.101,0,61.26,0z M61.26,119.715c-32.22,0-58.455-26.235-58.455-58.455c0-32.22,26.235-58.451,58.455-58.451
                    c32.22,0,58.457,26.231,58.457,58.451C119.717,93.48,93.48,119.715,61.26,119.715z"/>
                </g>
              </svg>
            </div>
            <h1 className="main-title">אחסון וורדפרס מנוהל בענן – הפתרון המושלם לאתר מהיר, מאובטח ומקודם</h1>
            <p className="meta-description">
              מחפשים אחסון וורדפרס מנוהל בענן? הגעתם למקום הנכון! אנו מציעים שירות אחסון בענן עם ניהול מלא, מהירות גבוהה, אבטחה מתקדמת ותמיכה מקצועית – כל מה שהאתר שלכם צריך כדי להצליח ולבלוט במנועי החיפוש.
            </p>
          </div>
        </div>

        <section className="features-section">
          <div className="container">
            <h2 className="section-title">למה לבחור בשירות אחסון וורדפרס מנוהל בענן?</h2>
            <div className="features-grid">
              <div className="feature-card">
                <h3>ניהול מלא (Fully Managed Service)</h3>
                <p>אנחנו דואגים לכל ההיבטים הטכניים של האתר שלכם – מעדכוני אבטחה, דרך גיבויים אוטומטיים ועד אופטימיזציה של מהירות. כך, תוכלו להתמקד ביצירת תוכן ובקידום העסק, בלי לדאוג לצדדים הטכנולוגיים.</p>
              </div>
              <div className="feature-card">
                <h3>מהירות טעינה ואופטימיזציה ל-SEO</h3>
                <p>זמן טעינה מהיר משפיע ישירות על דירוג האתר במנועי החיפוש. תשתיות הענן שלנו מכוונות לביצועים גבוהים ומבוססות על טכנולוגיות מתקדמות (כגון CDN ומטמונים חכמים), כדי לוודא שהאתר שלכם ירוץ חלק וביעילות מקסימלית.</p>
              </div>
              <div className="feature-card">
                <h3>אבטחה מתקדמת 24/7</h3>
                <p>שירות האחסון המנוהל שלנו כולל שכבות הגנה מתקדמות: סריקות אבטחה יומיות, חומות אש (Firewall), וחסימת מתקפות נפוצות (DDoS). בנוסף, אנו מספקים גיבויים אוטומטיים ושחזורים מהירים, כך שהמידע שלכם בטוח בכל מצב.</p>
              </div>
              <div className="feature-card">
                <h3>סקלאביליות מלאה (Scalability) לפי צורך</h3>
                <p>צמיחה מהירה של האתר או העסק? אין בעיה. פתרון הענן המנוהל שלנו מאפשר להגדיל משאבים בקליק אחד: נפח אחסון, רוחב פס ויכולות עיבוד, בהתאם לביקושים ולגדילה הטבעית של האתר.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="seo-benefits">
          <div className="container">
            <h2 className="section-title">כיצד אירוח וורדפרס מנוהל עוזר בקידום אתרים (SEO)?</h2>
            <div className="benefits-list">
              <ul>
                <li>מהירות אתר משופרת: גורם קריטי לדירוג במנועי חיפוש כמו גוגל.</li>
                <li>אבטחה חזקה: אתרים מאובטחים זוכים לאמינות גבוהה יותר בעיני מנועי החיפוש.</li>
                <li>זמינות גבוהה: שרתים איכותיים מפחיתים זמן השבתה ומאפשרים למנועי החיפוש לסרוק את האתר ללא הפרעות.</li>
                <li>תשתית טכנולוגית מעודכנת: עדכוני גרסה אחרונים של וורדפרס, PHP ו-MySQL משפרים ביצועים וחוויית משתמש.</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="faq-section">
          <div className="container">
            <h2 className="section-title">שאלות נפוצות (FAQ)</h2>
            <div className="faq-grid">
              <div className="faq-item">
                <h3>האם אני יכול להעביר אתר וורדפרס קיים לשירות האחסון המנוהל שלכם בקלות?</h3>
                <p>בהחלט! צוות המומחים שלנו מלווה אתכם בתהליך ההעברה (Migration) ללא כל עלות נוספת, ודואג שהמעבר יתבצע באופן חלק ומהיר.</p>
              </div>
              <div className="faq-item">
                <h3>מה קורה אם אני צריך עוד משאבים בעתיד?</h3>
                <p>הפתרון שלנו בנוי להתרחבות מהירה. תוכלו להגדיל את המשאבים (RAM, CPU, שטח אחסון, רוחב פס) בלחיצת כפתור, בהתאם לצרכים המשתנים של האתר.</p>
              </div>
              <div className="faq-item">
                <h3>האם ניתן להתקין תוספים חיצוניים לוורדפרס?</h3>
                <p>בוודאי. מדובר בשירות מנוהל, אך הוא גמיש ומותאם לצרכים שלכם. תוכלו להתקין תוספים ותבניות כרצונכם, ואנחנו נדאג שהם פועלים בצורה מיטבית.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="cta-section">
          <div className="container">
            <h2>רוצים להתחיל?</h2>
            <p>הצטרפו אלינו עוד היום ותיהנו מפתרון ענן מנוהל שממנף את אתר הוורדפרס שלכם לגבהים חדשים.</p>
            <div className="cta-buttons">
              <a href="https://meetings.hubspot.com/viacheslav-seifer" target="_blank" rel="noopener nodreferrer" className="btn-primary">קבלו דמו חינם</a>
              <Link to="/contact1" className="btn-secondary">צור קשר עם נציג</Link>
            </div>
          </div>
        </section>

        <section className="summary-section">
          <div className="container">
            <h2>סיכום</h2>
            <p>הבחירה בשירות אירוח וורדפרס מנוהל בענן היא הבחירה הטובה ביותר כדי לוודא שהאתר שלכם מהיר, מאובטח ומקודם במנועי החיפוש. אנו מזמינים אתכם להצטרף אלינו וליהנות ממעטפת שירות מלאה – ניהול טכני, אבטחה מתקדמת, תמיכה מקצועית ומקסימום ביצועים, תחת קורת גג אחת.</p>
            <div className="final-cta">
              <button className="btn-primary">הצטרפו עכשיו!</button>
            </div>
          </div>
        </section>

        <section className="pricing-section">
          <div className="container">
            <h2 className="section-title">תוכניות אחסון</h2>
            <div className="pricing-grid">
              {plans[language].map((plan, index) => (
                <div key={index} className={`pricing-card ${index === 1 ? 'popular' : ''}`}>
                  {index === 1 && (
                    <div className="popular-badge">הכי פופולרי</div>
                  )}
                  <div className="pricing-card-header">
                    <h3 className="plan-name">{plan.name}</h3>
                    <div className="price-container">
                      <span className="price">{plan.price}</span>
                      <span className="period">/חודש</span>
                    </div>
                  </div>
                  
                  <div className="pricing-card-body">
                    <ul className="specs-list">
                      <li>
                        <i className="fas fa-microchip"></i>
                        <span>{plan.specs.cpu}</span>
                      </li>
                      <li>
                        <i className="fas fa-memory"></i>
                        <span>{plan.specs.ram}</span>
                      </li>
                      <li>
                        <i className="fas fa-hdd"></i>
                        <span>{plan.specs.storage}</span>
                      </li>
                      <li>
                        <i className="fas fa-network-wired"></i>
                        <span>{plan.specs.bandwidth}</span>
                      </li>
                      <li>
                        <i className="fas fa-exchange-alt"></i>
                        <span>{plan.specs.traffic}</span>
                      </li>
                    </ul>
                    <div id={`paypal-button-container-${index === 0 ? 'P-40696904NP245520GM6RGYYY' : 
                                                        index === 1 ? 'P-9Y5799824B107742KM6RG5LY' : 
                                                        'P-30208148FW311183BM6RG6PQ'}`}>
                    </div>
                  </div>
                </div>
              ))}

              <div className="pricing-card custom-plan">
                <div className="pricing-card-header">
                  <h3 className="plan-name">ארגוני</h3>
                  <div className="price-container custom">
                    <span className="custom-text">פתרון מותאם אישית</span>
                  </div>
                </div>
                <div className="pricing-card-body">
                  <div className="custom-description">
                    <i className="fas fa-building"></i>
                    <p>פתרונות מותאמים אישית לארגונים גדולים עם דרישות ספציפיות</p>
                  </div>
                  <div className="enterprise-features">
                    <div className="enterprise-feature">
                      <i className="fas fa-expand-arrows-alt"></i>
                      <div className="enterprise-feature-text">
                        <h5>גמישות</h5>
                        <p>התאמה של משאבים לפי דרישה</p>
                      </div>
                    </div>
                    <div className="enterprise-feature">
                      <i className="fas fa-shield-virus"></i>
                      <div className="enterprise-feature-text">
                        <h5>זמינות גבוהה</h5>
                        <p>הבטחת זמינות מקסימלית (99.9% זמינות) עם גיבוי אוטומטי ואיזון עומסים במספר אזורים</p>
                      </div>
                    </div>
                  </div>
                  <Link to="/contact1" className="btn-secondary contact-btn">
                    <i className="fas fa-envelope"></i>
                    צור קשר
                  </Link>
                </div>
              </div>
            </div>

            <div className="features-section included-features">
              <div className="features-header">
                <h4 className="features-title">כל התכניות כוללות</h4>
                <p className="features-subtitle">שירות מנוהל</p>
              </div>
              <div className="features-container">
                {features[language].map((feature, index) => {
                  const featureIcons = [
                    "fa-cogs",
                    "fa-camera",
                    "fa-cloud",
                    "fa-shield-alt",
                    "fa-chart-line",
                    "fa-tools",
                    "fa-comments",
                    "fa-lock",
                    "fa-user-shield"
                  ];

                  return (
                    <div key={index} className="feature-box">
                      <div className="feature-icon">
                        <i className={`fas ${featureIcons[index]}`}></i>
                      </div>
                      <div className="feature-text">
                        {feature}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default WordPress; 