import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './CloudEn.css';

const CloudEn = () => {
  const plans = [
    {
      name: "Standard",
      specs: {
        cpu: "1 Core",
        ram: "RAM: 2GB",
        storage: "Storage: 100GB SSD",
        bandwidth: "Bandwidth: 20Mbps",
        traffic: "Traffic: Unlimited"
      },
      price: "$68"
    },
    {
      name: "Business",
      specs: {
        cpu: "2 Cores",
        ram: "RAM: 4GB",
        storage: "Storage: 100GB SSD",
        bandwidth: "Bandwidth: 20Mbps",
        traffic: "Traffic: Unlimited"
      },
      price: "$139"
    },
    {
      name: "Advanced",
      specs: {
        cpu: "4 Cores",
        ram: "RAM: 8GB",
        storage: "Storage: 200GB SSD",
        bandwidth: "Bandwidth: 50Mbps",
        traffic: "Traffic: Unlimited"
      },
      price: "$219"
    }
  ];

  const features = [
    "Full Management",
    "Snapshot Backup",
    "Remote Cloud Backup (7 days)",
    "Updates & Security Patches",
    "Server Monitoring",
    "Infrastructure Support",
    "Technical Consulting",
    "SSL Certificate",
    "Cyber Security System",
  ];

  // Add structured data for SEO
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": "Managed Hybrid Cloud Service",
    "description": "A managed hybrid cloud solution combining the benefits of private and public clouds, with full management and excellent performance",
    "offers": {
      "@type": "AggregateOffer",
      "priceCurrency": "USD",
      "lowPrice": "68",
      "highPrice": "219",
      "offerCount": "3"
    }
  };

  // Add PayPal script load handler
  useEffect(() => {
    let scriptElement = null;
    
    const loadPayPalScript = () => {
      if (scriptElement) {
        document.body.removeChild(scriptElement);
      }

      scriptElement = document.createElement('script');
      scriptElement.src = `https://www.paypal.com/sdk/js?client-id=AdWIx81WbV4y0eGSaVvcJ46FVo9-eSxz1Ao2rOy0glrPDdU_343yTk4xYg_NxffTknRiAbaYbNByFNcf&vault=true&intent=subscription&locale=en_US`;
      scriptElement.setAttribute('data-sdk-integration-source', 'button-factory');
      scriptElement.async = true;

      scriptElement.onload = () => {
        console.log('PayPal script loaded successfully');
        initializePayPalButtons();
      };

      scriptElement.onerror = (error) => {
        console.error('Error loading PayPal script:', error);
      };

      document.body.appendChild(scriptElement);
    };

    loadPayPalScript();

    return () => {
      if (scriptElement && document.body.contains(scriptElement)) {
        document.body.removeChild(scriptElement);
      }
    };
  }, []);

  const initializePayPalButtons = () => {
    if (!window.paypal) {
      console.log('PayPal not loaded yet');
      return;
    }

    try {
      const buttonConfigs = [
        {
          containerId: '#paypal-button-container-P-8YC13099PR982634MM6RGEZY',
          planId: 'P-8YC13099PR982634MM6RGEZY'
        },
        {
          containerId: '#paypal-button-container-P-32508119SA155922SM6RGJSQ',
          planId: 'P-32508119SA155922SM6RGJSQ'
        },
        {
          containerId: '#paypal-button-container-P-4UD04094D3902033KM6RGLBQ',
          planId: 'P-4UD04094D3902033KM6RGLBQ'
        }
      ];

      buttonConfigs.forEach(config => {
        const container = document.querySelector(config.containerId);
        if (container) {
          window.paypal.Buttons({
            style: {
              shape: 'rect',
              color: 'blue',
              layout: 'vertical',
              label: 'subscribe'
            },
            createSubscription: function(data, actions) {
              return actions.subscription.create({
                plan_id: config.planId
              });
            },
            onApprove: function(data, actions) {
              alert(data.subscriptionID);
            },
            onError: function(err) {
              console.error('PayPal button error:', err);
            }
          }).render(config.containerId);
        }
      });
    } catch (error) {
      console.error('Error initializing PayPal buttons:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Managed Hybrid Cloud Service | EpicFinal</title>
        <meta name="description" content="Managed hybrid cloud service combining the benefits of private and public clouds. Perfect solution for businesses seeking flexibility, security, and excellent performance." />
        <meta name="keywords" content="hybrid cloud, cloud services, cloud storage, managed servers, private cloud, public cloud, data security" />
        <meta property="og:title" content="Managed Hybrid Cloud Service | EpicFinal" />
        <meta property="og:description" content="A managed hybrid cloud solution combining the benefits of private and public clouds, with full management and excellent performance" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <link rel="canonical" href="https://epicfinal.com/cloud-en" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <main className="cloud-page-en">
        <div className="cloud-container">
          <div className="hero-section">
            <h1>Managed Hybrid Cloud Service – Your Perfect Cloud Solution for Business Growth</h1>
            <div className="cloud-intro">
              <p>In today's advanced digital age, businesses don't have the time or resources to invest in building and maintaining complex cloud infrastructures. This is where our Managed Hybrid Cloud Service comes in – a solution that combines the benefits of private and public clouds, giving you access to managed cloud servers (similar to EC2 or ECS) without the hassle of managing independent infrastructure.</p>
            </div>
          </div>

          <section className="cloud-what-is">
            <div className="section-content">
              <h2>What is Managed Hybrid Cloud Service?</h2>
              <p>A hybrid cloud is a working model that combines private and public cloud infrastructures to provide your business with maximum flexibility, high scalability, and significant cost savings. When the hybrid cloud is fully managed by a team of experts, you get a perfect combination of both worlds – without worrying about technology, security, or ongoing maintenance.</p>
            </div>
          </section>

          <section className="cloud-benefits">
            <h2>Key Benefits of Our Managed Hybrid Cloud Service</h2>
            <div className="benefits-grid">
              <div className="benefit-item">
                <div className="benefit-content">
                  <h3>Flexibility & Scalability</h3>
                  <p>Resources can be added or removed according to business needs, and configuration can be easily and quickly adjusted to match desired traffic and performance variables.</p>
                </div>
              </div>
              <div className="benefit-item">
                <div className="benefit-content">
                  <h3>Full Management</h3>
                  <p>Our expert team handles all technological aspects – from server deployment through security and backup settings to routine software updates.</p>
                </div>
              </div>
              <div className="benefit-item">
                <div className="benefit-content">
                  <h3>Advanced Security</h3>
                  <p>We use the most advanced technologies and strict security standards to ensure your data is protected at all times.</p>
                </div>
              </div>
              <div className="benefit-item">
                <div className="benefit-content">
                  <h3>Cost Savings</h3>
                  <p>The hybrid model allows you to pay only for what you actually consume. No need for significant upfront investment in hardware infrastructure or expensive IT resources.</p>
                </div>
              </div>
              <div className="benefit-item">
                <div className="benefit-content">
                  <h3>High Availability</h3>
                  <p>Thanks to load distribution between private and public infrastructure and our professional management, business continuity is maintained even during peak periods or isolated incidents.</p>
                </div>
              </div>
              <div className="benefit-item">
                <div className="benefit-content">
                  <h3>Professional Support</h3>
                  <p>We provide support services with experts who accompany you at every stage – from implementation to ongoing maintenance.</p>
                </div>
              </div>
            </div>
          </section>

          <section className="cloud-suitable">
            <h2>Who Is It For?</h2>
            <ul>
              <li>Growing companies and organizations looking to rapidly enhance their digital and data capabilities.</li>
              <li>Technology startups seeking fast-to-implement infrastructure with flexible expansion options without heavy upfront investment.</li>
              <li>Enterprise organizations looking to manage large and complex workloads in a secure cloud environment.</li>
              <li>Financial and security institutions requiring strict security and regulatory solutions.</li>
              <li>Any business wanting to combine public and private cloud and focus on core business activities.</li>
            </ul>
          </section>

          <section className="cloud-process">
            <h2>How It Works</h2>
            <div className="process-steps">
              <div className="step">
                <h3>Analysis & Consulting</h3>
                <p>In the first stage, we learn about your business needs and goals, and perform an initial characterization of the hybrid cloud infrastructure.</p>
              </div>
              <div className="step">
                <h3>Deployment & Setup</h3>
                <p>We'll set up your hybrid cloud environment, ensure secure connection between public and private clouds, and configure managed servers according to your requirements.</p>
              </div>
              <div className="step">
                <h3>Integration & Customization</h3>
                <p>We'll connect to existing systems, integrate CI/CD automations as needed, and set up backup and disaster recovery mechanisms.</p>
              </div>
              <div className="step">
                <h3>Ongoing Management & Support</h3>
                <p>Our team ensures 24/7 monitoring, security updates, troubleshooting, upgrades, and required changes.</p>
              </div>
            </div>
          </section>

          <section className="cloud-why-us">
            <h2>Why Choose Us?</h2>
            <ul>
              <li>Full Customization: We build the solution flexibly to provide precise answers to your organization's needs.</li>
              <li>End-to-End Service: Close support at all project stages – from consulting and implementation to maintenance and control.</li>
              <li>Technology Partnerships: We work with the world's leading cloud providers.</li>
            </ul>
          </section>

          <section className="pricing-section">
            <h2>Plans & Pricing</h2>
            <div className="pricing-plans-container">
              <div className="deployment-info">
                <div className="info-box">
                  <i className="fas fa-clock"></i>
                  <p>Average deployment time: ~2 hours</p>
                </div>
                <div className="info-box highlight">
                  <i className="fas fa-cogs"></i>
                  <p>Managed Cloud Infrastructure</p>
                </div>
                <div className="info-box">
                  <i className="fas fa-envelope"></i>
                  <p>
                    After ordering, please contact:
                    <a href="mailto:support@epicfinal.com">support@epicfinal.com</a>
                  </p>
                </div>
              </div>

              <div className="pricing-row">
                {plans.map((plan, index) => (
                  <div className={`pricing-card ${index === 1 ? 'popular' : ''}`} key={index}>
                    {index === 1 && (
                      <div className="popular-badge">Most Popular</div>
                    )}
                    <div className="pricing-card-header">
                      <h3 className="plan-name">{plan.name}</h3>
                      <div className="price-container">
                        <span className="price">{plan.price}</span>
                        <span className="period">/month</span>
                      </div>
                    </div>
                    
                    <div className="pricing-card-body">
                      <ul className="specs-list">
                        <li>
                          <i className="fas fa-microchip"></i>
                          <span>{plan.specs.cpu}</span>
                        </li>
                        <li>
                          <i className="fas fa-memory"></i>
                          <span>{plan.specs.ram}</span>
                        </li>
                        <li>
                          <i className="fas fa-hdd"></i>
                          <span>{plan.specs.storage}</span>
                        </li>
                        <li>
                          <i className="fas fa-network-wired"></i>
                          <span>{plan.specs.bandwidth}</span>
                        </li>
                        <li>
                          <i className="fas fa-exchange-alt"></i>
                          <span>{plan.specs.traffic}</span>
                        </li>
                      </ul>
                      <div className="terms-disclaimer">
                        <small>
                          By making a purchase, you agree to our
                          <a href="/terms_conditions" target="_blank" rel="noopener noreferrer">
                            Terms & Conditions
                          </a>
                        </small>
                      </div>
                      <div id={`paypal-button-container-P-${['8YC13099PR982634MM6RGEZY', '32508119SA155922SM6RGJSQ', '4UD04094D3902033KM6RGLBQ'][index]}`}></div>
                    </div>
                  </div>
                ))}

                <div className="pricing-card custom-plan">
                  <div className="pricing-card-header">
                    <h3 className="plan-name">Enterprise</h3>
                    <div className="price-container custom">
                      <span className="custom-text">Custom Solutions</span>
                    </div>
                  </div>
                  <div className="pricing-card-body">
                    <div className="custom-description">
                      <i className="fas fa-building"></i>
                      <p>Custom solutions for large organizations with specific requirements</p>
                    </div>
                    <div className="enterprise-features">
                      <div className="enterprise-feature">
                        <i className="fas fa-expand-arrows-alt"></i>
                        <div className="enterprise-feature-text">
                          <h5>Flexibility</h5>
                          <p>Resource customization on demand</p>
                        </div>
                      </div>
                      <div className="enterprise-feature">
                        <i className="fas fa-shield-virus"></i>
                        <div className="enterprise-feature-text">
                          <h5>High Availability</h5>
                          <p>Ensuring maximum availability (99.9% uptime) with automatic backup and load balancing across multiple regions</p>
                        </div>
                      </div>
                    </div>
                    <button className="contact-btn">
                      <i className="fas fa-envelope"></i>
                      <a href="/contact1">Contact Us</a>
                    </button>
                  </div>
                </div>
              </div>

              <div className="features-section">
                <div className="features-header">
                  <h4 className="features-title">All Plans Include</h4>
                  <p className="features-subtitle">Everything you need to run your application successfully</p>
                </div>
                <div className="features-container">
                  {features.map((feature, index) => {
                    const featureIcons = [
                      "fa-cogs",
                      "fa-camera",
                      "fa-cloud",
                      "fa-shield-alt",
                      "fa-chart-line",
                      "fa-tools",
                      "fa-comments",
                      "fa-lock",
                      "fa-user-shield"
                    ];

                    return (
                      <div key={index} className="feature-box">
                        <div className="feature-icon">
                          <i className={`fas ${featureIcons[index]}`}></i>
                        </div>
                        <div className="feature-text">
                          {feature}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>

          <section className="cloud-cta">
            <h2>Upgrade to Managed Hybrid Cloud Today</h2>
            <p>Don't stay behind in the digital age. Infrastructure limitations and technical concerns shouldn't hold back your organization's success.</p>
            <a href="/contact1" className="contact-button">Contact Us</a>
          </section>

          <div className="cloud-closing">
            <p>Join the world of managed hybrid cloud today and enjoy all the benefits of advanced cloud – with zero technical hassle and maximum business results!</p>
          </div>
        </div>
      </main>
    </>
  );
};

export default CloudEn; 