import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './Cloud.css';

const Cloud = () => {
  const plans = [
    {
      name: "סטנדרטי",
      specs: {
        cpu: "ליבה 1",
        ram: "זיכרון RAM: 2GB",
        storage: "אחסון: 100GB SSD",
        bandwidth: "רוחב פס: 20Mbps",
        traffic: "תעבורה: ללא הגבלה"
      },
      price: "219 ₪"
    },
    {
      name: "עסקי",
      specs: {
        cpu: "2 ליבות",
        ram: "זיכרון RAM: 4GB",
        storage: "אחסון: 100GB SSD",
        bandwidth: "רוחב פס: 20Mbps",
        traffic: "תעבורה: ללא הגבלה"
      },
      price: "439 ₪"
    },
    {
      name: "מתקדם",
      specs: {
        cpu: "4 ליבות",
        ram: "זיכרון RAM: 8GB",
        storage: "אחסון: 200GB SSD",
        bandwidth: "רוחב פס: 50Mbps",
        traffic: "תעבורה: ללא הגבלה"
      },
      price: "699 ₪"
    }
  ];

  const features = [
    "ניהול מלא",
    "גיבוי Snapshot",
    "גיבוי לענן מרוחק (7 ימים)",
    "עדכונים ועדכוני אבטחה",
    "ניטור שרת",
    "תמיכה בתשתית",
    "ייעוץ טכני",
    "תעודת SSL",
    "מערכת הגנת סייבר",
  ];

  // Add structured data for SEO
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": "שירות ענן היברידי מנוהל",
    "description": "פתרון ענן היברידי מנוהל המשלב בין היתרונות של ענן פרטי לענן ציבורי, עם ניהול מלא וביצועים מעולים",
    "offers": {
      "@type": "AggregateOffer",
      "priceCurrency": "ILS",
      "lowPrice": "219",
      "highPrice": "699",
      "offerCount": "3"
    }
  };

  // Add PayPal script load handler
  useEffect(() => {
    let scriptElement = null;
    
    const loadPayPalScript = () => {
      if (scriptElement) {
        document.body.removeChild(scriptElement);
      }

      scriptElement = document.createElement('script');
      scriptElement.src = `https://www.paypal.com/sdk/js?client-id=AdWIx81WbV4y0eGSaVvcJ46FVo9-eSxz1Ao2rOy0glrPDdU_343yTk4xYg_NxffTknRiAbaYbNByFNcf&vault=true&intent=subscription&locale=he_IL`;
      scriptElement.setAttribute('data-sdk-integration-source', 'button-factory');
      scriptElement.async = true;

      scriptElement.onload = () => {
        console.log('PayPal script loaded successfully');
        initializePayPalButtons();
      };

      scriptElement.onerror = (error) => {
        console.error('Error loading PayPal script:', error);
      };

      document.body.appendChild(scriptElement);
    };

    loadPayPalScript();

    return () => {
      if (scriptElement && document.body.contains(scriptElement)) {
        document.body.removeChild(scriptElement);
      }
    };
  }, []);

  const initializePayPalButtons = () => {
    if (!window.paypal) {
      console.log('PayPal not loaded yet');
      return;
    }

    try {
      const buttonConfigs = [
        {
          containerId: '#paypal-button-container-P-40696904NP245520GM6RGYYY',
          planId: 'P-40696904NP245520GM6RGYYY'
        },
        {
          containerId: '#paypal-button-container-P-9Y5799824B107742KM6RG5LY',
          planId: 'P-9Y5799824B107742KM6RG5LY'
        },
        {
          containerId: '#paypal-button-container-P-30208148FW311183BM6RG6PQ',
          planId: 'P-30208148FW311183BM6RG6PQ'
        }
      ];

      buttonConfigs.forEach(config => {
        const container = document.querySelector(config.containerId);
        if (container) {
          window.paypal.Buttons({
            style: {
              shape: 'rect',
              color: 'blue',
              layout: 'vertical',
              label: 'subscribe'
            },
            createSubscription: function(data, actions) {
              return actions.subscription.create({
                plan_id: config.planId
              });
            },
            onApprove: function(data, actions) {
              alert(data.subscriptionID);
            },
            onError: function(err) {
              console.error('PayPal button error:', err);
            }
          }).render(config.containerId);
        }
      });
    } catch (error) {
      console.error('Error initializing PayPal buttons:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title>שירות ענן היברידי מנוהל | EpicFinal</title>
        <meta name="description" content="שירות ענן היברידי מנוהל המשלב בין היתרונות של ענן פרטי לענן ציבורי. פתרון מושלם לעסקים המחפשים גמישות, אבטחה וביצועים מעולים." />
        <meta name="keywords" content="ענן היברידי, שירותי ענן, אחסון ענן, שרתים מנוהלים, ענן פרטי, ענן ציבורי, אבטחת מידע" />
        <meta property="og:title" content="שירות ענן היברידי מנוהל | EpicFinal" />
        <meta property="og:description" content="פתרון ענן היברידי מנוהל המשלב בין היתרונות של ענן פרטי לענן ציבורי, עם ניהול מלא וביצועים מעולים" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="he_IL" />
        <link rel="canonical" href="https://epicfinal.com/cloud" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <main className="cloud-page">
        <div className="cloud-container">
          <div className="hero-section">
            <h1>שירות ענן היברידי מנוהל – פתרון הענן המושלם לצמיחה העסקית שלך</h1>
            <div className="cloud-intro">
              <p>בעידן הדיגיטלי המתקדם של היום, לעסקים אין זמן או משאבים להשקיע בבנייה ותחזוקה של תשתיות ענן מורכבות. כאן נכנס לתמונה שירות הענן ההיברידי המנוהל שלנו – פתרון המשלב בין היתרונות של ענן פרטי לענן ציבורי, ומעניק לך גישה לשרתי ענן מנוהלים (בדומה ל-EC2 או ECS) ללא כאבי הראש הכרוכים בניהול תשתית עצמאית.</p>
            </div>
          </div>

          <section className="cloud-what-is">
            <div className="section-content">
              <h2>מהו שירות ענן היברידי מנוהל?</h2>
              <p>ענן היברידי הוא מודל עבודה המשלב בין תשתיות ענן פרטיות לתשתיות ענן ציבוריות, כדי לספק לעסק שלך גמישות מקסימלית, סקלאביליות גבוהה, וחיסכון משמעותי בעלויות. כאשר הענן ההיברידי מנוהל במלואו על ידי צוות מומחים, אתה נהנה משילוב מושלם בין שני העולמות – מבלי לדאוג לטכנולוגיה, לאבטחה או לתחזוקה שוטפת.</p>
            </div>
          </section>

          <section className="cloud-benefits">
            <h2>יתרונות מרכזיים של שירות הענן ההיברידי המנוהל שלנו</h2>
            <div className="benefits-grid">
              <div className="benefit-item">
                <div className="benefit-content">
                  <h3>גמישות וסקלביליות</h3>
                  <p>ניתן להוסיף או להסיר משאבים לפי הצורך העסקי, ולהתאים את התצורה למשתני התנועה והביצועים הרצויים בקלות ובמהירות.</p>
                </div>
              </div>
              <div className="benefit-item">
                <div className="benefit-content">
                  <h3>ניהול מלא</h3>
                  <p>הצוות המומחה שלנו דואג לכל ההיבטים הטכנולוגיים – החל מפריסת השרתים, דרך הגדרות אבטחה וגיבוי, ועד עדכוני תוכנה שוטפים.</p>
                </div>
              </div>
              <div className="benefit-item">
                <div className="benefit-content">
                  <h3>אבטחת מידע מתקדמת</h3>
                  <p>אנו משתמשים בטכנולוגיות המתקדמות ביותר ובתקני אבטחה מחמירים, כך שהמידע שלך מוגן בכל רגע נתון.</p>
                </div>
              </div>
              <div className="benefit-item">
                <div className="benefit-content">
                  <h3>חסכון בעלויות</h3>
                  <p>מודל היברידי מאפשר לך לשלם רק על מה שאתה צורך בפועל. אין צורך בהשקעה משמעותית מראש בתשתית חומרה או משאבי IT יקרים.</p>
                </div>
              </div>
              <div className="benefit-item">
                <div className="benefit-content">
                  <h3>זמינות ושרידות גבוהה</h3>
                  <p>בזכות פיזור העומסים בין תשתית פרטית וציבורית, והניהול המקצועי שלנו, נשמרת רציפות פעילות גם בתקופות עומס או תקלות נקודתיות.</p>
                </div>
              </div>
              <div className="benefit-item">
                <div className="benefit-content">
                  <h3>תמיכה מקצועית</h3>
                  <p>אנו מספקים שירותי תמיכה, עם מומחים שמלווים אותך בכל שלב – מההטמעה ועד לתחזוקה השוטפת.</p>
                </div>
              </div>
            </div>
          </section>

          <section className="cloud-suitable">
            <h2>למי זה מתאים?</h2>
            <ul>
              <li>חברות וארגונים בצמיחה המעוניינים להגביר את יכולות הדיגיטל והדאטה שלהם במהירות וביעילות.</li>
              <li>סטארטאפים טכנולוגיים שמחפשים תשתית מהירה להטמעה, עם אפשרות להתרחב באופן גמיש ללא השקעה כבדה מראש.</li>
              <li>ארגוני אנטרפרייז המעוניינים לנהל עומסי עבודה גדולים ומורכבים בסביבת ענן מאובטחת.</li>
              <li>גופים פיננסיים וביטחוניים שזקוקים לפתרונות אבטחה ורגולציה מחמירים.</li>
              <li>כל עסק שרוצה לשלב בין ענן ציבורי לענן פרטי ולהתמקד בפעילות הליבה העסקית.</li>
            </ul>
          </section>

          <section className="cloud-process">
            <h2>כיצד זה עובד?</h2>
            <div className="process-steps">
              <div className="step">
                <h3>אפיון וייעוץ</h3>
                <p>בשלב הראשון אנחנו לומדים את הצרכים והמטרות העסקיות שלך, ומבצעים אפיון ראשוני של תשתית הענן ההיברידית.</p>
              </div>
              <div className="step">
                <h3>פריסה והקמה</h3>
                <p>נקים עבורך את סביבת הענן ההיברידית, נדאג לקישור מאובטח בין הענן הציבורי לענן הפרטי, ונגדיר את השרתים המנוהלים בהתאם לדרישותיך.</p>
              </div>
              <div className="step">
                <h3>אינטגרציות והתאמות</h3>
                <p>נבצע חיבור למערכות קיימות, נשלב אוטומציות ותהליכי CI/CD בהתאם לצורך, ונגדיר מנגנוני גיבוי והתאוששות מאסון.</p>
              </div>
              <div className="step">
                <h3>ניהול שוטף ותמיכה</h3>
                <p>הצוות שלנו דואג לניטור 24/7, עדכוני אבטחה, תקלות, שדרוגים ושינויים נדרשים.</p>
              </div>
            </div>
          </section>

          <section className="cloud-why-us">
            <h2>למה לבחור דווקא בנו?</h2>
            <ul>
              <li>התאמה אישית מלאה: אנו בונים את הפתרון באופן גמיש כדי לתת מענה מדויק לצרכי הארגון שלך.</li>
              <li>שירות מקצה לקצה: ליווי צמוד בכל שלבי הפרויקט – מייעוץ והטמעה ועד לתחזוקה ובקרה.</li>
              <li>שותפויות טכנולוגיות: אנו עובדים עם ספקי הענן המובילים בעולם.</li>
            </ul>
          </section>

          <section className="pricing-section">
            <h2>תכניות ומחירים</h2>
            <div className="pricing-plans-container">
              <div className="deployment-info">
                <div className="info-box">
                  <i className="fas fa-clock"></i>
                  <p>זמן הטמעה ממוצע: כשעתיים</p>
                </div>
                <div className="info-box highlight">
                  <i className="fas fa-cogs"></i>
                  <p>תשתית ענן מנוהלת</p>
                </div>
                <div className="info-box">
                  <i className="fas fa-envelope"></i>
                  <p>
                    לאחר ההזמנה, יש ליצור קשר:
                    <a href="mailto:support@epicfinal.com">support@epicfinal.com</a>
                  </p>
                </div>
              </div>

              <div className="pricing-row">
                {plans.map((plan, index) => (
                  <div className={`pricing-card ${index === 1 ? 'popular' : ''}`} key={index}>
                    {index === 1 && (
                      <div className="popular-badge">הכי פופולרי</div>
                    )}
                    <div className="pricing-card-header">
                      <h3 className="plan-name">{plan.name}</h3>
                      <div className="price-container">
                        <span className="price">{plan.price}</span>
                        <span className="period">/חודש</span>
                      </div>
                    </div>
                    
                    <div className="pricing-card-body">
                      <ul className="specs-list">
                        <li>
                          <i className="fas fa-microchip"></i>
                          <span>{plan.specs.cpu}</span>
                        </li>
                        <li>
                          <i className="fas fa-memory"></i>
                          <span>{plan.specs.ram}</span>
                        </li>
                        <li>
                          <i className="fas fa-hdd"></i>
                          <span>{plan.specs.storage}</span>
                        </li>
                        <li>
                          <i className="fas fa-network-wired"></i>
                          <span>{plan.specs.bandwidth}</span>
                        </li>
                        <li>
                          <i className="fas fa-exchange-alt"></i>
                          <span>{plan.specs.traffic}</span>
                        </li>
                      </ul>
                      <div className="terms-disclaimer">
                        <small>
                          בביצוע הרכישה, הנך מסכים/ה ל
                          <a href="/terms_conditions" target="_blank" rel="noopener noreferrer">
                            תנאי השימוש וההתקשרות
                          </a>
                        </small>
                      </div>
                      <div id={`paypal-button-container-P-${['40696904NP245520GM6RGYYY', '9Y5799824B107742KM6RG5LY', '30208148FW311183BM6RG6PQ'][index]}`}></div>
                    </div>
                  </div>
                ))}

                <div className="pricing-card custom-plan">
                  <div className="pricing-card-header">
                    <h3 className="plan-name">ארגוני</h3>
                    <div className="price-container custom">
                      <span className="custom-text">פתרונות מותאמים אישית</span>
                    </div>
                  </div>
                  <div className="pricing-card-body">
                    <div className="custom-description">
                      <i className="fas fa-building"></i>
                      <p>פתרונות מותאמים אישית לארגונים גדולים עם דרישות ספציפיות</p>
                    </div>
                    <div className="enterprise-features">
                      <div className="enterprise-feature">
                        <i className="fas fa-expand-arrows-alt"></i>
                        <div className="enterprise-feature-text">
                          <h5>גמישות</h5>
                          <p>התאמה של משאבים לפי דרישה</p>
                        </div>
                      </div>
                      <div className="enterprise-feature">
                        <i className="fas fa-shield-virus"></i>
                        <div className="enterprise-feature-text">
                          <h5>זמינות גבוהה</h5>
                          <p>הבטחת זמינות מקסימלית (99.9% זמינות) עם גיבוי אוטומטי ואיזון עומסים במספר אזורים</p>
                        </div>
                      </div>
                    </div>
                    <button className="contact-btn">
                      <i className="fas fa-envelope"></i>
                      <a href="/contact1">צור קשר</a>
                    </button>
                  </div>
                </div>
              </div>

              <div className="features-section">
                <div className="features-header">
                  <h4 className="features-title">כל התכניות כוללות</h4>
                  <p className="features-subtitle">כל מה שצריך כדי להפעיל את האפליקציה שלך בהצלחה</p>
                </div>
                <div className="features-container">
                  {features.map((feature, index) => {
                    const featureIcons = [
                      "fa-cogs",
                      "fa-camera",
                      "fa-cloud",
                      "fa-shield-alt",
                      "fa-chart-line",
                      "fa-tools",
                      "fa-comments",
                      "fa-lock",
                      "fa-user-shield"
                    ];

                    return (
                      <div key={index} className="feature-box">
                        <div className="feature-icon">
                          <i className={`fas ${featureIcons[index]}`}></i>
                        </div>
                        <div className="feature-text">
                          {feature}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>

          <section className="cloud-cta">
            <h2>התקדם עוד היום לענן היברידי מנוהל</h2>
            <p>אל תישאר מאחור בעידן הדיגיטלי. הגבלות תשתית וחששות טכניים לא צריכים לעכב את הצלחת הארגון שלך.</p>
            <a href="/contact1" className="contact-button">צור קשר</a>
          </section>

          <div className="cloud-closing">
            <p>הצטרף עוד היום לעולם של ענן היברידי מנוהל ותהנה מכל היתרונות של הענן המתקדם – עם אפס התעסקות טכנית ומקסימום תוצאות עסקיות!</p>
          </div>
        </div>
      </main>
    </>
  );
};

export default Cloud; 