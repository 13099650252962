import React, { useRef } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';

// Separate English and Hebrew sections
const englishSectionsData = [
    {
        heading: 'epicfinal, DevOps Services Terms and Conditions. 1. Description of Service',
        content: [
            ' 1. Scope of Services, 1.1 epicfinal agrees to provide DevOps services to the client as outlined in the agreed-upon statement of work. 1.2 The client acknowledges that the nature of DevOps involves continuous integration and deployment processes, and epicfinal will make every effort to ensure seamless operations.',
        ],
    },
    {
        heading: ' 2. Responsibilities',
        content: [
            '2.1 The client agrees to provide necessary access, information, and cooperation to facilitate the DevOps process. 2.2 epicfinal will exercise due diligence in implementing DevOps practices but does not guarantee absolute error-free performance.',
        ],
    },
    {
        heading: ' 3. Security and Data Protection',
        content: [
            '3.1 epicfinal will implement industry-standard security measures to protect client data. 3.2 The client is responsible for ensuring the security of their systems, applications, and data.',
        ],
    },
    {
        heading: '4. Continuous Improvement   ',
        content: [
            '4.1 epicfinal will work towards continuous improvement of DevOps processes to enhance efficiency and reduce risks.',
        ],
        // checkList: [
        //     'Lorem ipsum dolor sit amet, consectetur',
        //     'Quidem error quae illo excepturi nostrum blanditiis laboriosam',
        //     'Molestias, eum nihil expedita dolorum odio dolorem',
        //     'Eum nihil expedita dolorum odio dolorem',
        //     'Explicabo rem illum magni perferendis',
        // ],
    },
    {
        heading: ' 5. Limitation of Liability',
        content: [
            '5.1 epicfinal shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use or inability to use the DevOps services. 5.2 The client acknowledges that the DevOps processes involve inherent risks, and epicfinal shall not be held liable for any disruptions or loss of data.',
        ],
    },
    {
        heading: ' 5. 6. Termination',
        content: [
            '6.1 Either party may terminate the agreement with written notice if the other party breaches any material terms. 6.2 Upon termination, the client shall pay for any services rendered up to the termination date.'
        ],
        
    },
    {
        heading: ' 7. 7. Confidentiality ',
        content: [
            '7.1 Both parties agree to maintain the confidentiality of proprietary information and trade secrets disclosed during the course of the engagement. ',
        ],
    },
    {
        heading: ' 8. Governing Law ',
        content: [
            '8.1 This agreement shall be governed by and construed in accordance with the state of israel laws ',
        ],
    },
    {
        heading: ' 9. Managed Services',
        content: [
            'Remote server operation',
            'Automated remote support (automation)',
            'System monitoring, software updates, and security updates (Patch Management & Monitoring)',
            'Endpoint Protection / Antivirus (Endpoint Protection)',
            'Remote backups',
            'Liaison with the hosting provider',
            'Quarterly reporting'
        ],
    },
    {
        heading: ' 10. Description of Services',
        content: [
            'EpicFinal (epicfi), hereinafter referred to as the "Service Provider," will supply maintenance and support for the existing software on the equipment listed in the Appendix, as well as for any installed third-party software and business applications. The Service Provider will provide support and troubleshooting for remote access solutions, including setting up remote access for off-site computers. The Service Provider will make every effort to resolve issues remotely.',
            'Projects beyond the scope described above—such as managing expansions of the existing network or performing work beyond what the current servers are used for—are not covered under this agreement. Such projects include, but are not limited to, setting up additional servers or making system changes for expansion. Payments for these projects will be billed separately at the Service Provider\'s hourly rate of EpicFinal (epicfi) of 420 NIS per hour.'
        ],
    },
    {
        heading: ' 11. Automatic Support',
        content: [
            'Support will be provided by using software and technological solutions on an unlimited basis through these systems. This includes monitoring systems, installing critical operating system updates, information security updates, restarting or monitoring failed services, optimization, software integrity checks, and scanning for malware (viruses, malicious software) via the installed protection system.'
        ],
    },
    {
        heading: ' 12. Endpoint Protection / Antivirus',
        content: [
            'Under this agreement, the managed server will receive a license for the Antivirus/Anti-Virus Protection System. This is first-tier commercial software. The type and brand of the protection system may change from time to time at the discretion of the Service Provider, EpicFinal (epicfi). Providing a protection system shall not be considered a commitment or assumption of liability in the field of information security by EpicFinal (epicfi) toward the managed service client.'
        ],
    },
    {
        heading: ' 13. Cloud Backup',
        content: [
            'EpicFinal (epicfi) performs automatic backups of all files on the server, up to a limited storage size of 50 GB. The backup is performed to a secure online location. Any additional storage beyond what is stated in this contract requires an additional fee. Backups are performed at night to avoid disrupting system functionality. EpicFinal will make every effort to protect the data on the server, but cannot be held liable for any data that is lost or destroyed on the systems or within the backups. Performing backups requires an upload/download internet speed from the hosting provider of at least several megabits per second.'
        ],
    },
    {
        heading: ' 14. Managed Services Response Times',
        content: [
            'Service completely unavailable/System down: 8-hour response time (9am–5:30pm Business Days), 8-hour response time (no business hours Weekends/Holidays)',
            'Significant disruption/Business functions impaired: 12-hour response time (9am–5:30pm Business Days), 12-hour response time (no business hours Weekends/Holidays)',
            'Limited disruption/Business can continue: 24-hour response time (9am–5:30pm Business Days), 24-hour response time (no business hours Weekends/Holidays)',
            'Minor disruption: 48-hour response time (9am–5:30pm Business Days), 48-hour response time (no business hours Weekends/Holidays)',
            '* Weekend/holiday support incurs an additional charge of 420 NIS per hour if approved only.',
            '* The client acknowledges that weather conditions, traffic, force majeure, or even employee illness can affect response times.'
        ],
    },
    {
        heading: ' 15. Managed Service Requirements',
        content: [
            'The servers or endpoint workstations must be less than 5 years old.',
            'Clients that use various network components or special software not included in the server itself—such as routers, firewalls, switches, and specialized software applications—are responsible for maintaining a service/support agreement or special software license.',
            'If the client has special business software installed on its network, the client is responsible for acquiring installation, training, and ongoing technical support from the software vendor. EpicFinal (epicfi) can assist with network/server support, but they are not experts on every software application and rely on the software vendor to provide software support at the client\'s expense.',
            'For any additional server added during the term of this agreement, beyond the existing computers/servers, the monthly managed service fee will increase by 68 $ per month for each CPU core (vCPU/CPU CORE) and by 68 USD for each 2GB of RAM in the server.',
            'All software on the servers must be licensed and have support from the software vendor.',
            'The server or network must have a server-based backup solution with a valid license and manufacturer support, capable of being monitored and sending notifications of backup successes and failures.',
            'The network must have a firewall with a valid license and vendor support, located between the internal network and the Internet.',
            'There must be a static public IP address assigned to a network device enabling VPN access.',
            'When service begins for the client, EpicFinal will conduct an assessment of the client\'s network to determine whether all managed service requirements are met. If not, EpicFinal will install the necessary services. Payments for bringing the network into compliance with the requirements will be billed as additional services based on the set hourly rate.'
        ],
    },
    {
        heading: ' 16. Agreement Terms',
        content: [
            'The contract term is 36 months. The agreement will renew automatically for an additional 36-month period unless at least 30 days before the end of any such period, EpicFinal or the client terminates the contract.',
            'The client commits to paying a monthly fee for the managed service by the 10th of each month or earlier, but not later.',
            'The hourly rate for EpicFinal for additional services is 420 NIS per hour on business days between 9:00 and 17:00.',
            'Adding new sites, IT assets, services, support hours, or days not listed in this agreement will incur additional service or project fees.',
            'The cost of any hardware required to perform additional services or projects will be paid by the client before installation.',
            'Network documentation will be maintained, including hardware, software, network settings, IP addresses, firewall configurations.',
            'Discounted prices offered on servers and IT assets.',
            'The client agrees to all requirements outlined in this document.'
        ],
    },
    {
        heading: ' 17. Terms and Conditions',
        content: [
            'Confidentiality: During this agreement\'s term and forever afterward, neither party will reveal the other party\'s confidential information to anyone without prior written consent.',
            'Non-Solicitation: The parties agree not to solicit, hire, or contract with each other\'s employees or contractors during the agreement and for 24 months after termination.',
            'Force Majeure: Neither party shall be liable for failures or delays caused by events beyond reasonable control.',
            'Limitation of Liability: The Service Provider shall not be liable for any damages whatsoever arising out of services or equipment provided.',
            'Contractors: We are not responsible for actions of technicians not under our direct control.',
            'Force Majeure Work Conditions: No obligation to send technicians to dangerous conditions.',
            'Insurance: Client must maintain liability and property insurance.',
            'Third-Party Engagement: Client is solely responsible for third-party engagements.',
            'Failure to Pay: Service may be suspended if payment not received by the 10th.',
            'Termination Rights: Either party may terminate with 30 days\' notice.',
            'Backup Transfer: Client responsible for transferring backups upon termination.',
            'License Removal: Client must grant access to remove licenses upon termination.',
            'No Employer-Employee Relationship: Parties are independent entities.',
            'No Insurance for Data Loss or Cyber Attack.',
            'Dispute Resolution: Parties will seek resolution through negotiation or mediation.',
            'Governing Law: Israeli law applies exclusively.'
        ],
    },
    {
        heading: ' 19. General Provisions',
        content: [
            'Each party must pay its own taxes as required by law.',
            'This agreement constitutes the entire understanding between the parties.',
            'Amendments must be in writing and signed by both parties.',
            'Invalid provisions do not affect remaining provisions.',
            'Notices sent by registered mail considered received within 72 hours.',
            'No rights created in favor of third parties.'
        ],
    },
    {
        heading: ' 10. Amendments   ',
        content: [
            ' 10.1 Any amendments to this agreement must be made in writing and agreed upon by both parties.  By agreeing to these terms, the client acknowledges and accepts the risks associated with DevOps processes. ',
        ],
    },
];

const hebrewSectionsData = [
    {
        heading: 'השירות המנוהל כולל',
        content: [
            'תפעול השרת מרחוק',
            'תמיכה אוטומטית (automation) מרחוק',
            'ניטור מערכות, עדכוני תוכנה ואבטחה (Patch Management & Monitoring)',
            'מערכת הגנה לעמדת קצה / אנטי-וירוס (Endpoint Protection)',
            'גיבויים מרוחקים',
            'תפעול התקשרות מול ספק אחסון',
            'דיווח רבעוני'
        ],
    },
    {
        heading: 'תיאור שירותים',
        content: [
            'אפיק פינאל (epicfi) להלן: נותן השירות, תספק תחזוקה ותמיכה לתוכנה קיימת לציוד המפורט בנספח כמו גם תוכנה של צד שלישי מותקנת ויישומים עסקיים. תספק תמיכה וניפוי בעיות עבור פתרונות גישה מרחוק, כולל הקמת גישה מרחוק של מחשבים מחוץ לאתר. תעשה מאמץ רב ככל האפשר לפתור בעיות מרחוק.',
            'פרויקטים מחוץ להיקף הנ"ל כגון ניהול על הרשת הקיימת יחויבו בנפרד כולל אך לא מוגבלים להקמת שרתים נוספים או שינויי מערכת לצורך התרחבות, מעבר למה שנמצא כרגע בשימוש השרתים, הם מחוץ להיקף הסכם זה. תשלומים יחויבו בנפרד בשער שעתי של נותן השירות אפיק פינאל (epicfi) לשירות, בתעריף של 420 ש"ח.'
        ],
    },
    {
        heading: 'תמיכה אוטומטית',
        content: [
            'יינתן תמיכה באמצעות שימוש בתוכנות ופתרונות טכנולוגיים בצורה בלתי מוגבלת על ידי מערכות אלו, זה כולל ניטור המערכות, התקנת עדכונים קריטיים עבור מערכת ההפעלה, עדכוני אבטחת מידע, שירותים נבחרים שנכשלו, אופטימיזציה, בדיקות תקינות התוכנה, סריקות של נוזקות כגון וירוסים, תוכנות זדוניות ע"י מערכת ההגנה שתותקן.'
        ],
    },
    {
        heading: 'הגנת נקודת קצה / אנטי-וירוס',
        content: [
            'בהסכם זה השרת המנוהל יקבל רישוי עבור תוכנת מערכת הגנה (Antivirus), מערכת ההגנה היא תוכנה מסחרית, מדרגה ראשונה, סוג וחברת המערכת ההגנה משתנה מעת-לעת, לבחירת ולשיקול דעתה של נותן השירות אפיק פינאל (epicfinal). בהספקת מערכת הגנה אין לראות התחייבות או לקיחת אחריות בנושא אבטחת מידע מצד נותן השירות אפיק פינאל (epicfinal) למבקש השירות המנוהל.'
        ],
    },
    {
        heading: 'גיבוי לענן',
        content: [
            'אפיק פינאל (epicfinal) מבצעת גיבויי אוטומטי לכל הקבצים הקיימים בשרת, עד גודל אחסון מוגבל של: 50 GB, הגיבוי מתבצע למיקום מקוון ומאובטח, לכל יחידת אחסון נוספת מעבר לכתוב בחוזה זה, נדרש תשלום נוסף. הגיבויים יבוצעו בשעות הלילה על מנת לא להפריע לתקינות וכשירות המערכות. אפיק פינאל תעשה ככל שביכולתה לשמור על המידע שנמצא בשרת, אך אין לראות בחברת אפיק פינאל אחראית על מידע שאבד או הושמד במערכות או בגיבויים. ביצוע גיבוי דורש מחברת ספקית האחסון תקשורת במהירות של מגהביט לשניה או יותר העלאה והורדה.'
        ],
    },
    {
        heading: 'זמני תגובה לשירותים מנוהלים',
        content: [
            'השירות לא זמין כלל / המערכת לא זמינה: 8 שעות זמן תגובה (9am-5:30pm בימי עסקים), 8 שעות זמן תגובה (אין פעילות בסופי שבוע וחגים)',
            'פגיעה משמעותית / פונקציות עסקיות נפגעו: 12 שעות זמן תגובה (9am-5:30pm בימי עסקים), 12 שעות זמן תגובה (אין פעילות בסופי שבוע וחגים)',
            'פגיעה מוגבלת / פונקציות עסקיות יכולות להמשיך: 24 שעות זמן תגובה (9am-5:30pm בימי עסקים), 24 שעות זמן תגובה (אין פעילות בסופי שבוע וחגים)',
            'פגיעה בסדר גודל קטן: 48 שעות זמן תגובה (9am-5:30pm בימי עסקים), 48 שעות זמן תגובה (אין פעילות בסופי שבוע וחגים)',
            '* תמיכה בסופי שבוע / חגים כרוכה בתוספת תשלום של 420 ש"ח לשעת עבודה.',
            '* הלקוח מאשר שתנאי מזג האוויר, תנועה, או כל גורם כח עליון ואפילו מחלת עובד, יכול לשנות את זמני התגובה.'
        ],
    },
    {
        heading: 'דרישות השירות המנוהל',
        content: [
            'השרתים או העמדות קצה חייבים להיות בני פחות מ-5 שנים לפחות',
            'לקוחות העושים שימוש ברכיבי תקשורת שונים או תוכנות מיוחדות שלא כוללים את השרת עצמו כגון נתבים, חומות אש, מתגים ויישומי תוכנה מיוחדים, הלקוח אחראי על חוזה שירות / תמיכה או ניהול רשיון מיוחד לתוכנה.',
            'אם ללקוח יש תוכנה מיוחדת לעסק שלו, אשר מותקנת על רשתו, הלקוח אחראי להשיג התקנה, הדרכה ותמיכה טכנית מתמשכת מספק התוכנה. חברת אפיק פינאל (epicfi) מסוגלת לסייע עם תמיכה ברשת / בשרת אך הם אינם מומחים לכל יישומי התוכנה ותלויים ביצרן התוכנה לספק תמיכת תוכנה על חשבון הלקוח.',
            'עבור כל שרת נוסף שיתווסף במהלך תקופת הסכם זה, מעבר למחשבים/שרתים, דמי השירות המנוהל החודשיים יגדלו בתעריף של 219 ש"ח לחודש לכל יחידת ליבת מעבד (vCPU/CPU CORE) וגם 219 ש"ח לכל 2GB RAM בשרת.',
            'כל התוכנות על גבי השרתים חייבים להיות בעלי רישיון ועם תמיכה של ספק התוכנה.',
            'על השרת או הרשת להחזיק בפתרון גיבוי מבוסס שרת עם רישיון בתוקף ותמיכה מהיצרן, ניתן לפקח עליו ולשלוח הודעות על כישלונות והצלחות במשימות.',
            'על הרשת להחזיק בחומת אש עם רישיון בתוקף ותמיכה מהיצרן, הממוקמת בין הרשת הפנימית לאינטרנט.',
            'חייבת להיות כתובת IP סטטית חיצונית המוקצית להתקן רשת, המאפשרת גישת VPN.',
            'בעת התחלת השירות עבור הלקוח, אפיק פינאל תבצע הערכה של רשת הלקוח ותקבע האם כל דרישות השירותים המנוהלים מתקיימים ואם לא, תתקין את השירותים הנדרשים. תשלומים עבור הבאת הרשת לתאימות עם הדרישות יחויבו כשירותים נוספים לפי התעריף.'
        ],
    },
    {
        heading: 'הסכם',
        content: [
            'אפיק פינאל תספק את השירותים המנוהלים המפורטים בהצעת השירותים המנוהלים. תקופת ההסכם היא 36 חודשים, המתחילים בתאריך: שהוסכם. ההסכם יחודש באופן אוטומטי לתקופה נוספת של 36 חודשים אלא אם כן לפחות 30 ימים לפני תום כל תקופה כזו, חברת אפיק פינאל או הלקוח יכולים לסיים את החוזה.',
            'הלקוח מתחייב לשלם תשלום חודשי עבור עלות ספק השירות המנוהל בסכום שהוסכם בכל ה-10 לחודש או לפני מועד זה אך לא מעבר.',
            'המחיר השעתי של חברת אפיק פינאל עבור שירותים נוספים הוא 420 ש"ח לשעה, בימי עסקים בין השעות 9:00 ל-17:00.',
            'הוספה של מיקומים, נכסי IT, שירותים, שעות שירות וימים מוגדרים שלא צוינו בהסכם זה תחייב חיובים כשירותים נוספים או פרויקט.',
            'העלויות של כל ציוד הנדרש עבור ביצוע השירותים הנוספים או הפרויקט ישולמו על ידי הלקוח לפני ההתקנה.',
            'אנו נתחיל ונשמור על תיעוד הרשת: תיעוד מתמשך של חומרה, תוכנה, הגדרות רשת, כתובות IP, הגדרות חומת אש ומידע רשת קשור.',
            'אנו נציע ללקוח מחירים מוזלים על שרתים ונכסי IT.',
            'הלקוחות ישמרו ויסכימו למדיניות ולאחריות וגם לדרישות השירותים המנוהלים.'
        ],
    },
    {
        heading: 'תנאים והגבלות',
        content: [
            'סודיות: במהלך תקופת הסכם זה ולאחר מכן לנצח, לא תחשוף אף אחת מהצדדים ללא הסכמה מראש בכתב של האחרת, לאף אחד מידע סודי של האחר.',
            'אי-שידול: הצדדים מסכימים שלא לפנות לשכיר ולא להעסיק עובדים או קבלנים של הצד השני במשך תקופת ההסכם ו-24 חודשים לאחר סיומו.',
            'כוח עליון: אף צד לא יהיה אחראי לכישלון או עיכוב הנובע מנסיבות מעבר לשליטתו.',
            'הגבלת אחריות: נותן השירות לא יהיה אחראי לנזקים כלשהם הנובעים מהשירות או הציוד.',
            'קבלנים: איננו אחראים לפעולות של טכנאים שאינם תחת שליטתנו הישירה.',
            'תנאי כוח עליון: אין חובה לשלוח טכנאים למצבים מסוכנים.',
            'ביטוח: הלקוח חייב להחזיק ביטוח אחריות ורכוש.',
            'התקשרות עם צד שלישי: הלקוח אחראי בלעדית להתקשרויות עם צד שלישי.',
            'אי תשלום: השירות עשוי להיות מושהה אם התשלום לא מתקבל עד ה-10 לחודש.',
            'זכויות סיום: כל צד רשאי לסיים בהודעה של 30 יום.',
            'העברת גיבויים: הלקוח אחראי להעברת גיבויים בעת סיום.',
            'הסרת רישיונות: הלקוח חייב לאפשר גישה להסרת רישיונות בעת סיום.',
            'אין יחסי עובד-מעביד: הצדדים הם ישויות עצמאיות.',
            'אין ביטוח לאובדן נתונים או התקפת סייבר.',
            'יישוב סכסוכים: הצדדים יפעלו ליישוב באמצעות משא ומתן או גישור.',
            'חוק: חוקי מדינת ישראל חלים בלעדית.'
        ],
    },
    {
        heading: 'הוראות כלליות',
        content: [
            'כל צד ישלם את המיסים שלו כנדרש בחוק.',
            'הסכם זה מהווה את כל ההבנות בין הצדדים.',
            'תיקונים חייבים להיות בכתב ובחתימת שני הצדדים.',
            'הוראות לא תקפות אינן משפיעות על שאר ההוראות.',
            'הודעות בדואר רשום נחשבות כהתקבלו תוך 72 שעות.',
            'אין זכויות לטובת צדדים שלישיים.'
        ],
    }
];

const Terms = () => {
    const scrollToHebrew = () => {
        const hebrewSection = document.getElementById('hebrew-section');
        hebrewSection?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <>
            <section className="terms-section">
                <Container>
                    {/* Scroll to Hebrew Button */}
                    <Row className="mb-4 sticky-top bg-white py-3">
                        <Col lg="12" md="12" className="text-end">
                            <Button 
                                color="primary" 
                                onClick={scrollToHebrew}
                                style={{ 
                                    direction: 'rtl', 
                                    fontFamily: 'Arial, sans-serif',
                                    position: 'fixed',
                                    top: '20px',
                                    left: '20px',
                                    zIndex: 1000
                                }}
                            >
                                מעבר לגרסה בעברית ⇩
                            </Button>
                        </Col>
                    </Row>

                    {/* English Content */}
                    <Row>
                        <Col lg="12" md="12">
                            <div className="mb-5">
                                <h3 className="text-primary mb-4">English Version</h3>
                                {englishSectionsData.map((section, index) => (
                                    <React.Fragment key={index}>
                                        <h4 className="text-primary">{section.heading}</h4>
                                        {section.content.map((paragraph, pIndex) => (
                                            <p key={pIndex} className="mb-3" style={{ direction: 'ltr', textAlign: 'left' }}>
                                                {paragraph}
                                            </p>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </div>
                        </Col>
                    </Row>

                    {/* Hebrew Content */}
                    <Row id="hebrew-section" style={{ scrollMarginTop: '100px' }}>
                        <Col lg="12" md="12">
                            <div className="mb-5">
                                <h3 className="text-primary mb-4" style={{ direction: 'rtl', textAlign: 'right' }}>גרסה בעברית</h3>
                                {hebrewSectionsData.map((section, index) => (
                                    <React.Fragment key={index}>
                                        <h4 className="text-primary" style={{ direction: 'rtl', textAlign: 'right' }}>
                                            {section.heading}
                                        </h4>
                                        {section.content.map((paragraph, pIndex) => (
                                            <p key={pIndex} className="mb-3" style={{ direction: 'rtl', textAlign: 'right' }}>
                                                {paragraph}
                                            </p>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <style jsx>{`
                /* RTL Support Styles */
                [dir="rtl"] {
                    text-align: right;
                }
                [dir="rtl"] .text-primary {
                    text-align: right;
                }
                [dir="rtl"] p {
                    text-align: right;
                }
                .terms-section {
                    padding-top: 80px;
                }
            `}</style>
        </>
    );
};

export default Terms;
