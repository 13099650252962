import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './WordPressEn.css';

const WordPressEn = () => {
  const [language, setLanguage] = useState('en');

  // Add PayPal script load handler
  useEffect(() => {
    let scriptElement = null;
    
    const loadPayPalScript = () => {
      if (scriptElement) {
        document.body.removeChild(scriptElement);
      }

      scriptElement = document.createElement('script');
      scriptElement.src = `https://www.paypal.com/sdk/js?client-id=AdWIx81WbV4y0eGSaVvcJ46FVo9-eSxz1Ao2rOy0glrPDdU_343yTk4xYg_NxffTknRiAbaYbNByFNcf&vault=true&intent=subscription&locale=en_US`;
      scriptElement.setAttribute('data-sdk-integration-source', 'button-factory');
      scriptElement.async = true;

      scriptElement.onload = () => {
        console.log('PayPal script loaded successfully');
        initializePayPalButtons();
      };

      scriptElement.onerror = (error) => {
        console.error('Error loading PayPal script:', error);
      };

      document.body.appendChild(scriptElement);
    };

    loadPayPalScript();

    return () => {
      if (scriptElement && document.body.contains(scriptElement)) {
        document.body.removeChild(scriptElement);
      }
    };
  }, []);

  // Add PayPal button initialization
  const initializePayPalButtons = () => {
    if (!window.paypal) {
      console.log('PayPal not loaded yet');
      return;
    }

    try {
      const buttonConfigs = [
        {
          containerId: 'paypal-button-container-P-8YC13099PR982634MM6RGEZY',
          planId: 'P-8YC13099PR982634MM6RGEZY'
        },
        {
          containerId: 'paypal-button-container-P-32508119SA155922SM6RGJSQ',
          planId: 'P-32508119SA155922SM6RGJSQ'
        },
        {
          containerId: 'paypal-button-container-P-4UD04094D3902033KM6RGLBQ',
          planId: 'P-4UD04094D3902033KM6RGLBQ'
        }
      ];

      buttonConfigs.forEach(config => {
        const container = document.querySelector(`#${config.containerId}`);
        if (container) {
          window.paypal.Buttons({
            style: {
              shape: 'rect',
              color: 'blue',
              layout: 'vertical',
              label: 'subscribe'
            },
            createSubscription: function(data, actions) {
              return actions.subscription.create({
                plan_id: config.planId
              });
            },
            onApprove: function(data, actions) {
              alert(data.subscriptionID);
            },
            onError: function(err) {
              console.error('PayPal button error:', err);
            }
          }).render(`#${config.containerId}`);
        }
      });
    } catch (error) {
      console.error('Error initializing PayPal buttons:', error);
    }
  };

  const plans = {
    en: [
      {
        name: "Starter",
        specs: {
          cpu: "1 Core",
          ram: "2GB RAM",
          storage: "100GB SSD",
          bandwidth: "20Mbps",
          traffic: "Unlimited"
        },
        price: "$68"
      },
      {
        name: "Growth",
        specs: {
          cpu: "2 Cores",
          ram: "4GB RAM",
          storage: "100GB SSD",
          bandwidth: "20Mbps",
          traffic: "Unlimited"
        },
        price: "$139"
      },
      {
        name: "Advanced",
        specs: {
          cpu: "4 Cores",
          ram: "8GB RAM",
          storage: "200GB SSD",
          bandwidth: "50Mbps",
          traffic: "Unlimited"
        },
        price: "$219"
      }
    ]
  };

  const features = {
    en: [
      "Full Management",
      "Snapshot Backup",
      "Remote Cloud Backup (7 days)",
      "Updates & Security Patches",
      "Server Monitoring",
      "Infrastructure Support",
      "Technical Consulting",
      "SSL Certificate",
      "Cyber Security System",
    ]
  };

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Managed Cloud WordPress Hosting | EpicFinal - Professional WordPress Hosting</title>
        <meta name="description" content="Premium managed WordPress hosting in the cloud with high performance, advanced security, and 24/7 professional support. Competitive pricing, automatic backups, and advanced management system." />
        <meta name="keywords" content="WordPress hosting, managed hosting, cloud hosting, managed WordPress, WordPress cloud hosting" />
        <meta property="og:title" content="Managed Cloud WordPress Hosting | EpicFinal" />
        <meta property="og:description" content="Premium managed WordPress hosting in the cloud with high performance, advanced security, and 24/7 professional support." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://epicfinal.com/wordpress-en" />
        <link rel="canonical" href="https://epicfinal.com/wordpress-en" />
        <meta name="robots" content="index, follow" />
        <link rel="alternate" hreflang="he" href="https://epicfinal.com/wordpress" />
        <link rel="alternate" hreflang="en" href="https://epicfinal.com/wordpress-en" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Product",
              "name": "Managed Cloud WordPress Hosting",
              "description": "Premium managed WordPress hosting in the cloud with high performance, advanced security, and professional support",
              "provider": {
                "@type": "Organization",
                "name": "EpicFinal",
                "url": "https://epicfinal.com"
              },
              "offers": {
                "@type": "AggregateOffer",
                "priceCurrency": "USD",
                "lowPrice": "68",
                "highPrice": "219",
                "offerCount": "3"
              },
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "150"
              }
            }
          `}
        </script>
      </Helmet>
      <div className="wordpress-container">
        <div className="wordpress-hero">
          <div className="container">
            <div className="wordpress-logo">
              <svg viewBox="0 0 122.52 122.523" style={{ width: "80px", height: "80px", marginBottom: "30px" }}>
                <g>
                  <path fill="#ffffff" d="M8.708,61.26c0,20.802,12.089,38.779,29.619,47.298L13.258,39.872C10.342,46.408,8.708,53.641,8.708,61.26z
                    M96.74,58.608c0-6.495-2.333-10.993-4.334-14.494c-2.664-4.329-5.161-7.995-5.161-12.324c0-4.831,3.664-9.328,8.825-9.328
                    c0.233,0,0.454,0.029,0.681,0.042c-9.35-8.566-21.807-13.796-35.489-13.796c-18.36,0-34.513,9.42-43.91,23.688
                    c1.233,0.037,2.395,0.063,3.382,0.063c5.497,0,14.006-0.667,14.006-0.667c2.833-0.167,3.167,3.994,0.337,4.329
                    c0,0-2.847,0.335-6.015,0.501L48.2,93.547l11.501-34.493l-8.188-22.434c-2.83-0.166-5.511-0.501-5.511-0.501
                    c-2.832-0.166-2.5-4.496,0.332-4.329c0,0,8.679,0.667,13.843,0.667c5.496,0,14.006-0.667,14.006-0.667
                    c2.835-0.167,3.168,3.994,0.337,4.329c0,0-2.853,0.335-6.015,0.501l18.992,56.494l5.242-17.517
                    C95.011,68.328,96.74,63.107,96.74,58.608z M61.262,64.756l-15.742,45.75c4.708,1.384,9.687,2.141,14.846,2.141
                    c6.12,0,11.989-1.058,17.452-2.979c-0.141-0.225-0.269-0.464-0.374-0.724L61.262,64.756z M107.167,36.046
                    c0.226,1.674,0.354,3.471,0.354,5.404c0,5.333-0.996,11.328-3.996,18.824l-16.053,46.413
                    c15.624-9.111,26.133-26.038,26.133-45.426C113.604,52.124,111.23,43.532,107.167,36.046z M61.26,0
                    C27.419,0,0,27.42,0,61.26c0,33.841,27.419,61.263,61.26,61.263c33.841,0,61.264-27.422,61.264-61.263
                    C122.522,27.42,95.101,0,61.26,0z M61.26,119.715c-32.22,0-58.455-26.235-58.455-58.455c0-32.22,26.235-58.451,58.455-58.451
                    c32.22,0,58.457,26.231,58.457,58.451C119.717,93.48,93.48,119.715,61.26,119.715z"/>
                </g>
              </svg>
            </div>
            <h1 className="main-title">Managed Cloud WordPress Hosting - The Perfect Solution for a Fast, Secure, and SEO-Optimized Website</h1>
            <p className="meta-description">
              Looking for managed WordPress hosting in the cloud? You've come to the right place! We offer cloud hosting with full management, high performance, advanced security, and professional support – everything your website needs to succeed and stand out in search engines.
            </p>
          </div>
        </div>

        <section className="features-section">
          <div className="container">
            <h2 className="section-title">Why Choose Our Managed WordPress Hosting Service?</h2>
            <div className="features-grid">
              <div className="feature-card">
                <h3>Fully Managed Service</h3>
                <p>We take care of all technical aspects of your website – from security updates and automatic backups to speed optimization. This allows you to focus on creating content and growing your business, without worrying about the technical details.</p>
              </div>
              <div className="feature-card">
                <h3>Loading Speed & SEO Optimization</h3>
                <p>Fast loading time directly impacts your site's search engine ranking. Our cloud infrastructure is tuned for high performance and based on advanced technologies (such as CDN and smart caching) to ensure your site runs smoothly and efficiently.</p>
              </div>
              <div className="feature-card">
                <h3>24/7 Advanced Security</h3>
                <p>Our managed hosting service includes advanced protection layers: daily security scans, firewalls, and DDoS attack prevention. Plus, we provide automatic backups and quick recoveries, ensuring your data is safe in any situation.</p>
              </div>
              <div className="feature-card">
                <h3>Full Scalability On-Demand</h3>
                <p>Rapid website or business growth? No problem. Our managed cloud solution allows you to increase resources with one click: storage space, bandwidth, and processing capabilities, according to your site's natural growth and demands.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="seo-benefits">
          <div className="container">
            <h2 className="section-title">How Does Managed WordPress Hosting Help with SEO?</h2>
            <div className="benefits-list">
              <ul>
                <li>Improved Site Speed: A critical factor for ranking in search engines like Google.</li>
                <li>Strong Security: Secure websites gain higher trust from search engines.</li>
                <li>High Availability: Quality servers reduce downtime and allow search engines to crawl your site without interruptions.</li>
                <li>Updated Technology Stack: Latest versions of WordPress, PHP, and MySQL improve performance and user experience.</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="faq-section">
          <div className="container">
            <h2 className="section-title">Frequently Asked Questions (FAQ)</h2>
            <div className="faq-grid">
              <div className="faq-item">
                <h3>Can I easily migrate my existing WordPress site to your managed hosting service?</h3>
                <p>Absolutely! Our expert team guides you through the migration process at no additional cost, ensuring a smooth and quick transition.</p>
              </div>
              <div className="faq-item">
                <h3>What if I need more resources in the future?</h3>
                <p>Our solution is built for rapid expansion. You can increase resources (RAM, CPU, storage space, bandwidth) with a single click, adapting to your site's changing needs.</p>
              </div>
              <div className="faq-item">
                <h3>Can I install external WordPress plugins?</h3>
                <p>Of course. While it's a managed service, it's flexible and adaptable to your needs. You can install plugins and themes as you wish, and we'll ensure they work optimally.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="cta-section">
          <div className="container">
            <h2>Ready to Get Started?</h2>
            <p>Join us today and enjoy a managed cloud solution that elevates your WordPress site to new heights.</p>
            <div className="cta-buttons">
              <a href="https://meetings.hubspot.com/viacheslav-seifer" target="_blank" rel="noopener noreferrer" className="btn-primary">Get Free Demo</a>
              <Link to="/contact1" className="btn-secondary">Contact Sales</Link>
            </div>
          </div>
        </section>

        <section className="summary-section">
          <div className="container">
            <h2>Summary</h2>
            <p>Choosing managed WordPress hosting in the cloud is the best choice to ensure your site is fast, secure, and well-ranked in search engines. We invite you to join us and enjoy comprehensive service coverage – technical management, advanced security, professional support, and maximum performance, all under one roof.</p>
            <div className="final-cta">
              <button className="btn-primary">Join Now!</button>
            </div>
          </div>
        </section>

        <section className="pricing-section">
          <div className="container">
            <h2 className="section-title">Hosting Plans</h2>
            <div className="pricing-grid">
              {plans[language].map((plan, index) => (
                <div key={index} className={`pricing-card ${index === 1 ? 'popular' : ''}`}>
                  {index === 1 && (
                    <div className="popular-badge">Most Popular</div>
                  )}
                  <div className="pricing-card-header">
                    <h3 className="plan-name">{plan.name}</h3>
                    <div className="price-container">
                      <span className="price">{plan.price}</span>
                      <span className="period">/month</span>
                    </div>
                  </div>
                  
                  <div className="pricing-card-body">
                    <ul className="specs-list">
                      <li>
                        <i className="fas fa-microchip"></i>
                        <span>{plan.specs.cpu}</span>
                      </li>
                      <li>
                        <i className="fas fa-memory"></i>
                        <span>{plan.specs.ram}</span>
                      </li>
                      <li>
                        <i className="fas fa-hdd"></i>
                        <span>{plan.specs.storage}</span>
                      </li>
                      <li>
                        <i className="fas fa-network-wired"></i>
                        <span>{plan.specs.bandwidth}</span>
                      </li>
                      <li>
                        <i className="fas fa-exchange-alt"></i>
                        <span>{plan.specs.traffic}</span>
                      </li>
                    </ul>
                    <div id={`paypal-button-container-${index === 0 ? 'P-8YC13099PR982634MM6RGEZY' : 
                                                        index === 1 ? 'P-32508119SA155922SM6RGJSQ' : 
                                                        'P-4UD04094D3902033KM6RGLBQ'}`}>
                    </div>
                  </div>
                </div>
              ))}

              <div className="pricing-card custom-plan">
                <div className="pricing-card-header">
                  <h3 className="plan-name">Enterprise</h3>
                  <div className="price-container custom">
                    <span className="custom-text">Custom Solution</span>
                  </div>
                </div>
                <div className="pricing-card-body">
                  <div className="custom-description">
                    <i className="fas fa-building"></i>
                    <p>Custom solutions for large organizations with specific requirements</p>
                  </div>
                  <div className="enterprise-features">
                    <div className="enterprise-feature">
                      <i className="fas fa-expand-arrows-alt"></i>
                      <div className="enterprise-feature-text">
                        <h5>Flexibility</h5>
                        <p>Resource customization on demand</p>
                      </div>
                    </div>
                    <div className="enterprise-feature">
                      <i className="fas fa-shield-virus"></i>
                      <div className="enterprise-feature-text">
                        <h5>High Availability</h5>
                        <p>Guaranteed maximum uptime (99.9% availability) with automatic backup and load balancing across multiple regions</p>
                      </div>
                    </div>
                  </div>
                  <Link to="/contact1" className="btn-secondary contact-btn">
                    <i className="fas fa-envelope"></i>
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>

            <div className="features-section included-features">
              <div className="features-header">
                <h4 className="features-title">All Plans Include</h4>
                <p className="features-subtitle"> Managed</p>
              </div>
              <div className="features-container">
                {features[language].map((feature, index) => {
                  const featureIcons = [
                    "fa-cogs",
                    "fa-camera",
                    "fa-cloud",
                    "fa-shield-alt",
                    "fa-chart-line",
                    "fa-tools",
                    "fa-comments",
                    "fa-lock",
                    "fa-user-shield"
                  ];

                  return (
                    <div key={index} className="feature-box">
                      <div className="feature-icon">
                        <i className={`fas ${featureIcons[index]}`}></i>
                      </div>
                      <div className="feature-text">
                        {feature}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default WordPressEn; 