import React from 'react'
import Map from './Map'
import HerosectionHeader from '../../Componet/Herosection/HerosectionHeader'
import NewsletterL1 from '../../Componet/Newsletter/NewsletterL1'

function Contact1() {
    return (
        <>
            <HerosectionHeader folder1={"Pages"} folder2={"Contacts"} name={"Contact Us"} />
            <div className="page-content">
                <div className="container py-4">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 text-center">
                            <div className="mb-5 p-4 bg-light rounded-3">
                                <h3 className="mb-3 text-primary">Free Initial Consultation!</h3>
                                <p className="mb-4">Book a 30-minute consultation at no cost, and we will be happy to help you understand how we can advance your business.</p>
                                <a 
                                    href="https://meetings.hubspot.com/viacheslav-seifer" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    className="btn btn-primary"
                                >
                                    <i className="material-icons align-middle me-2"></i>
                                    Schedule a Meeting
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <Map />
                <NewsletterL1 />
            </div>
        </>
    )
}

export default Contact1
